import { UserScheme } from "client-v2";

const initialState = {
	currentUser: undefined
};

export default function (state = initialState, action: any) {
	switch (action.type) {
		case "CURRENT_USER": {
			return {
				...state,
				currentUser: action.data as UserScheme,
			};
		}
		default:
			return state;
	}
}
