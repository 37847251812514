import { CSSProperties, ReactElement } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { Auth, UserScheme } from "client-v2";

import { Color, Menu, Button } from "@adoptaunabuelo/react-components";
import Logo from "../../assets/logo/AUA";
import { Power, User as UserIcon, ArrowLeft, X } from "lucide-react";

const Container = styled.div<{
	color?: string;
	$active?: boolean;
	$transparent?: boolean;
}>`
	position: sticky;
	top: 0;
	display: flex;
	align-items: center;
	width: 100%;
	z-index: 1000;
	transition: background-color 0.3s ease;
	background-color: ${(props) =>
		props.color ? props.color : props.$active ? "white" : "transparent"};
	border-bottom: ${(props) =>
		props.$active
			? "1px solid " + Color.line.soft
			: "0px solid " + Color.line.soft};
	min-height: 62px;
	${(props) => media.lessThan("medium")`
        position: ${props.$transparent ? "absolute" : "relative"};
        background-color: transparent;
    `}
`;
const Center = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
`;
const Left = styled.div`
	display: flex;
	align-items: center;
	${(props) => media.lessThan("small")`
        margin-left: 0px;
    `}
`;
const Right = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: flex-end;
	margin-right: 12px;
`;
const LogoView = styled.div`
	height: 60px;
	width: 60px;
	border-radius: 40px;
	background-color: white;
	cursor: pointer;
	margin: 5px 0px;
	padding: 1px;
	margin-left: 32px;
	${media.lessThan("small")`
        height: 50px;
        width: 50px;
        margin-left: 16px;
    `}
`;
const MenuView = styled.div`
	padding-right: 22px;
	${media.lessThan("small")`
        padding-right: 12px;
    `}
`;
const BackView = styled.div`
	display: none;
	${media.lessThan("small")`
        display: flex;
        padding-left: 22px;
    `}
`;

const Header = (props: HeaderProps) => {
	const goHome = () => {
		window.location.href = "https://adoptaunabuelo.org";
	};

	const onOptionClick = async (option: any) => {
		if (option.id === "logout") {
			await Auth.logout();
			window.location.reload();
		}
	};

	return (
		<Container
			style={props.style}
			color={props.backgroundColor}
			$active={props.active}
			$transparent={props.transparent}
		>
			{props.onBackClick && !props.showClose && (
				<BackView>
					<Button
						design="image"
						style={{ height: 44, width: 44, marginLeft: -10 }}
						icon={
							<ArrowLeft
								color={
									props.style?.color
										? props.style.color
										: Color.text.full
								}
							/>
						}
						disabled={props.disableBack}
						onClick={props.onBackClick}
					/>
				</BackView>
			)}
			{props.showClose && (
				<BackView>
					<Button
						design="image"
						style={{ height: 44, width: 44, marginLeft: -10 }}
						icon={
							<X
								color={
									props.style?.color
										? props.style.color
										: Color.text.full
								}
							/>
						}
						onClick={() =>
							window.open("https://adoptaunabuelo.org")
						}
					/>
				</BackView>
			)}
			{props.childrenPosition === "center" ? (
				<Center>
					{!props.hideLogo && !props.onBackClick && (
						<LogoView onClick={goHome}>
							<Logo style={{ marginTop: 6, marginLeft: 3 }} />
						</LogoView>
					)}
					{props.children}
				</Center>
			) : (
				<>
					<Left>
						{!props.hideLogo && !props.onBackClick && (
							<LogoView onClick={goHome}>
								<Logo style={{ marginTop: 6, marginLeft: 3 }} />
							</LogoView>
						)}
						{props.childrenPosition === "left" && props.children}
					</Left>
					<Right>
						{props.childrenPosition === "right" && props.children}
					</Right>
				</>
			)}
			{props.user && !props.hideUser && (
				<MenuView>
					<Menu
						id={"header-menu-list"}
						icon={
							<UserIcon
								color={
									props.color ? props.color : Color.text.full
								}
							/>
						}
						menuStyle={{ width: "max-content" }}
						options={[
							{
								id: "logout",
								label: "Cerrar sesión",
								icon: <Power />,
							},
						]}
						position={"bottom-left"}
						onClick={onOptionClick}
					/>
				</MenuView>
			)}
		</Container>
	);
};
export default Header;
export interface HeaderProps {
	style?: CSSProperties;
	user?: UserScheme;
	active?: boolean;
	children?: ReactElement;
	backgroundColor?: string;
	color?: string;
	childrenPosition?: "left" | "right" | "center";
	hideLogo?: boolean;
	hideUser?: boolean;
	transparent?: boolean;
	disableBack?: boolean;
	showClose?: boolean;
	onBackClick?: () => void;
}
