import { CSSProperties, useState } from "react";
import styled from "styled-components";
import media from "styled-media-query";

import {
	Text,
	Color,
	Button,
	ColorV2,
	ProgressBar,
} from "@adoptaunabuelo/react-components";
import { PieChart } from "react-minimal-pie-chart";
import { Check } from "lucide-react";
import ActivitiesList from "../../../components/list/ActivitiesList";
import GrandpaIcon from "../../../assets/images/icon/Grandpa";
import PressLogo from "../../../assets/logo/Press";
import ActivitiesLogo from "../../../assets/logo/Activities";
import TestimonyList from "../../../components/list/TestimonyList";
import ReliefIcon from "../../../assets/images/icon/relief";
import ReliefModal from "../../../components/modal/ReliefModal";

const screenSize1: any = "1300px";
const screenSize2: any = "1024px";
const screenSize3: any = "320px";

const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
const Section = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 0px 10%;
	justify-content: center;
	align-items: center;
	height: calc(var(--doc-height) - var(--nav-bar-height));
	${media.lessThan(screenSize1)`
        padding: 0px 5%;
    `}
	${media.lessThan(screenSize2)`
        padding: 0px 48px;
    `}
    ${media.lessThan("medium")`
        padding: 64px 32px;
        height: unset;
    `}
    ${media.lessThan("small")`
        padding: 48px 24px;
    `}
`;
const SectionFinal = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 0px 10%;
	justify-content: center;
	align-items: center;
	height: calc(var(--doc-height) - var(--nav-bar-height));
	${media.lessThan(screenSize1)`
        padding: 0px 5%;
    `}
	${media.lessThan(screenSize2)`
        padding: 0px 48px;
    `}
    ${media.lessThan("medium")`
        padding: 64px 32px;
    `}
    ${media.lessThan("small")`
        padding: 48px 24px;
    `}
`;
const InternalSection = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 1280px;
`;
const ProgressContainer = styled.div`
	display: flex;
	align-items: center;
	${media.lessThan("medium")`
        flex-direction: column;
        gap: 32px;
	`}
`;
const ProgressView = styled.div`
	display: flex;
	flex-direction: column;
	${media.lessThan("medium")`
        
	`}
`;
const HistoryRow = styled.div`
	display: flex;
	flex-direction: row;
	gap: 48px;
	${media.lessThan(screenSize2)`
        flex-direction: column;
	`}
	${media.lessThan("medium")`
        gap: 24px;
	`}
`;
const SectionRow = styled.div`
	display: flex;
	flex-direction: row;
	background-color: ${ColorV2.surface.background};
	padding: 48px;
	border-radius: 12px;
	gap: 32px;
	${media.lessThan("medium")`
        flex-direction: column;
        padding: 24px;
	`}
`;
const Col = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	${media.lessThan("medium")`
        padding: 12px 0px;
	`}
`;
const Row = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
`;
const BigNumber = styled(Text)`
	font-weight: 800 !important;
	font-size: 105px !important;
	line-height: 105px !important;
	${media.lessThan("small")`
        font-size: 60px !important;
        line-height: 60px !important;
	`}
	${media.lessThan(screenSize3)`
        font-size: 52px !important;
        line-height: 52px !important;
    `}
`;
const NumberLabel = styled(Text)`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 32px;
	min-width: 32px;
	border-radius: 30px;
	margin-right: 12px !important;
	background-color: white;
`;
const Cell = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 12px;
`;
const CheckView = styled.div`
	display: flex;
	height: 22px;
	width: 22px;
	border-radius: 22px;
	align-items: center;
	justify-content: center;
	margin-right: 12px;
	background-color: ${ColorV2.surface.background};
`;
const PieChartView = styled.div`
	display: flex;
	flex: 1;
	margin: 0px 32px;
	${media.lessThan(screenSize2)`
        display: none;
	`}
`;
const PieChartCol = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	${media.lessThan("medium")`
        width: -webkit-fill-available;
        width: -moz-available;
	`}
`;
const DesktopButton = styled(Button)`
	width: fit-content;
	margin-top: 32px;
	${media.lessThan("medium")`
        display: none !important;
    `}
`;
const VideoFrame = styled.div`
	width: 80%;
	aspect-ratio: 16/9;
	border-radius: 12px;
	overflow: hidden;
	${media.lessThan("medium")`
        width: 100%;
    `}
`;
const PressRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 48px;
	${media.lessThan("medium")`
        flex-wrap: wrap;
        gap: 24px;
    `}
`;
const DonationBanner = styled.div`
	background-color: white;
	border-radius: 12px;
	padding: 24px;
	margin-top: 12px;
`;

const Info = (props: Props) => {
	const [showRelief, setShowRelief] = useState(false);

	const chartOptions = [
		{
			title: "Para los abuelos",
			value: 85,
			color: Color.text.primary,
			textColor: Color.text.primary,
			fontSize: 120,
			bullets: [
				"Los mayores no pagan nada por participar.",
				"Acompañamiento semanal.",
				"Celebramos su cumpleaños.",
				"Asistencia a nuestras actividades.",
				"Cumplimos sus sueños.",
				"Asistencia psicológica en casos de depresión.",
			],
		},
		{
			title: "Costes operacionales",
			value: 10,
			color: Color.text.primarySoft,
			textColor: Color.text.primarySoft,
			fontSize: 48,
			bullets: [
				"Nos permiten funcionar como organización (alquiler de oficina y suministros).",
				"Impuestos.",
				"Inversión en tecnología para llegar a todos los abuelos del mundo.",
			],
		},
		{
			title: "Comisiones bancarias",
			value: 5,
			color: Color.line.primarySoft,
			textColor: Color.line.primarySoft,
			fontSize: 32,
		},
	];

	const activities = [
		{
			name: "Enriqueta",
			info: "Una actividad para reducir la brecha digital de las personas mayores y fomentar su autonomía, ampliando sus opciones de socialización y ocio.",
			testimony:
				"Gracias a Adopta Un Abuelo ahora puedo hablar con mi nieto por el móvil todas las semanas.",
			img: require("../../../assets/images/history/enriqueta.webp"),
			logo: <ActivitiesLogo type="abuelos_hackers" />,
		},
		{
			name: "José",
			info: "¡Nuestro programa original! Los voluntarios acompañan semanalmente a sus abuelos adoptados a través de visitas, llamadas o videollamadas con el objetivo de que se sientan escuchados, acompañados y queridos.",
			testimony:
				"Cuando mis voluntarias me visitan me siento un poco más feliz. Si salimos a dar un paseo siempre presumo orgulloso de que son mis nietas.",
			img: require("../../../assets/images/history/jose.webp"),
			logo: <ActivitiesLogo type="voluntariado" />,
		},
		{
			name: "Ángeles",
			info: "Una vez al mes cumplimos el sueño de mayores como Ángeles, que nunca tuvo la oportunidad de volar y gracias a las donaciones de los voluntarios ha podido hacerlo viajando hasta Mallorca.",
			testimony:
				"Muchas gracias a todos los que me han ayudado a cumplir mi sueño. Fue un día para el recuerdo.",
			img: require("../../../assets/images/history/angeles.webp"),
			logo: <ActivitiesLogo type="sueños" />,
		},
		{
			name: "Umaro",
			info: "En el día de su cumpleaños llamamos a los mayores para felicitarles en un día tan especial. Por ejemplo, Umaro no tiene familia y su voluntaria fue a pasar el día con él. ¡Hasta le llevó globos y un regalito de cumple!",
			testimony:
				"Gracias a mi voluntaria María Antonia mi cumpleaños fue un día inolvidable. Fue una sorpresa muy especial.",
			img: require("../../../assets/images/history/umaro.webp"),
			logo: <ActivitiesLogo type="cumpleaños" />,
		},
	];

	const effects = [
		{
			title: "Muerte prematura",
		},
		{
			title: "Riesgo de demencia",
		},
		{
			title: "Riesgo de enfermedades cardiacas y accidentes cerebrovasculares",
		},
		{
			title: "Depresión, ansiedad y suicidio.",
		},
	];

	const testimonies = [
		{
			name: "María Flores",
			image: require("../../../assets/images/testimonies/maria.webp"),
			testimony:
				"Conocer a África, mi abuela adoptada, ha sido increíble. He podido aprender cada día de ella y me ha dado mucho cariño y amor. África siempre me dice que cómo podemos ser tan buenas amigas con todos los años que nos llevamos, y es que para la amistad no hay edad. Es una experiencia única que te llena a nivel personal.",
		},
		{
			name: "Luis Silvestre",
			image: require("../../../assets/images/testimonies/luis.webp"),
			testimony:
				"Lo recomiendo 100% por compartir experiencias con las personas más sabias del planeta y por lo gratificante que es dedicarles tiempo a nuestros mayores que se encuentren solos.",
		},
		{
			name: "Gaby Lopez",
			image: require("../../../assets/images/testimonies/gaby.webp"),
			testimony:
				"Recomendaría Adopta Un Abuelo sin ninguna duda. Es una bonita manera de devolver lo que España me ha brindado durante estos 12 años, qué mejor forma que hacerlo dando cariño y atención a nuestros mayores. Me encantan los momentos divertidos con mi abuela Pilar.",
		},
	];

	const renderProgressView = (item: any) => (
		<ProgressView style={{ marginBottom: item.value === 10 ? 32 : 0 }}>
			<Text
				type="p"
				style={{
					fontSize: item.fontSize,
					lineHeight: item.fontSize + "px",
					fontWeight: 800,
					color: item.textColor,
				}}
			>
				{item.value}%
			</Text>
			<Text type="h4" weight="medium" style={{ marginBottom: 24 }}>
				{item.title}
			</Text>
			{item.bullets &&
				item.bullets.map((item: string, index: number) => (
					<Cell key={"progress-bullet-" + index}>
						<CheckView>
							<Check
								height={16}
								width={16}
								color={Color.text.primary}
							/>
						</CheckView>
						<Text type="p" style={{ flex: 1 }}>
							{item}
						</Text>
					</Cell>
				))}
		</ProgressView>
	);

	return (
		<Container style={props.style}>
			<ReliefModal
				isVisible={showRelief}
				price={15}
				charge="monthly"
				onClose={() => setShowRelief(false)}
			/>
			<Section style={{ backgroundColor: ColorV2.surface.background }}>
				<InternalSection>
					<HistoryRow>
						<Col>
							<Text
								type="h2"
								weight="semibold"
								style={{ marginBottom: 64 }}
							>
								Tenemos que actuar ya
							</Text>
							<BigNumber type="h3">2.000.000</BigNumber>
							<Text type="h6">
								de mayores en España viven solos y no reciben
								visitas
							</Text>
						</Col>
						<Col>
							<Text
								type="h4"
								weight="medium"
								style={{ marginBottom: 32 }}
							>
								Los efectos de la soledad no deseada:
							</Text>
							{effects.map((item, index) => (
								<Text
									key={"effects-" + index}
									type="p"
									style={{
										marginBottom: 16,
										display: "flex",
										alignItems: "center",
									}}
								>
									<NumberLabel type="p" weight="semibold">
										{index + 1}
									</NumberLabel>
									{item.title}
								</Text>
							))}
							<Text
								type="h6"
								weight="semibold"
								style={{ marginTop: 12 }}
							>
								Cada día, 3 personas mayores de 70 años, se
								quitan la vida.
							</Text>
							<Text
								type="c2"
								style={{
									marginTop: 32,
									color: Color.text.high,
								}}
							>
								Datos obtenidos de la National Academies of
								Sciences, Engineering, and Medicine. 2020.
								Social Isolation and Loneliness in Older Adults:
								Opportunities for the Health Care System.
							</Text>
						</Col>
					</HistoryRow>
				</InternalSection>
			</Section>
			<Section>
				<InternalSection>
					<Text
						type="h2"
						weight="semibold"
						style={{ textAlign: "center", marginBottom: 64 }}
					>
						<span style={{ color: Color.text.primary }}>
							Gracias a tu donación
						</span>
						,
						<Text
							type="h2"
							weight="semibold"
							style={{ textAlign: "center" }}
						>
							acompañamos a abuelos todos los días
						</Text>
					</Text>
					<ActivitiesList testimonies={activities} />
				</InternalSection>
			</Section>
			<Section style={{ backgroundColor: ColorV2.surface.background }}>
				<InternalSection>
					<HistoryRow>
						<Col>
							<Text
								type="h2"
								weight="semibold"
								style={{ marginBottom: 24 }}
							>
								Tu donación es deducible
							</Text>
							<Text type="h6">
								De los primeros 250€ que dones te devolverán el
								80% y el 40% del resto*.
							</Text>
							<Button
								design="secondary"
								style={{
									marginTop: 32,
									marginBottom: 32,
									width: "fit-content",
								}}
								onClick={() => setShowRelief(true)}
							>
								Más información
							</Button>
							<Text type="c1" style={{ color: Color.text.high }}>
								* La deducción pasa a ser del 40% al 45% si
								colaboras con Adopta Un Abuelo con una donación
								igual o superior durante al menos 3 años.
							</Text>
							<Text type="c1" style={{ color: Color.text.high }}>
								No aplica a País Vasco y Navarra, donde la
								deducción es del 20 y el 25%
							</Text>
						</Col>
						<Col
							style={{
								justifyContent: "center",
								position: "relative",
							}}
						>
							<Text type="h5" weight="semibold">
								Por ejemplo
							</Text>
							<DonationBanner>
								<Text type="h6">
									Si donas{" "}
									<span
										style={{
											color: ColorV2.text.primary,
											fontWeight: 500,
										}}
									>
										15 € al mes
									</span>{" "}
									durante un año, en la declaración de la
									Renta:
								</Text>
								<ProgressBar
									style={{
										height: 8,
										marginTop: 24,
										marginBottom: 12,
									}}
									progress={[
										{
											value: 144,
											color: Color.background.deepBlue,
										},
										{
											value: 36,
											color: Color.background.primary,
										},
									]}
								/>
								<Row>
									<Col style={{ padding: 0 }}>
										<Text type="p">Te devolverán</Text>
										<Text type={"h2"} weight="semibold">
											144
											<span
												style={{
													fontSize: 18,
													fontWeight: 500,
												}}
											>
												€
											</span>
										</Text>
									</Col>
									<Col
										style={{
											alignItems: "flex-end",
											padding: 0,
										}}
									>
										<Text
											type="p"
											style={{
												color: ColorV2.text.primary,
											}}
										>
											Solo te costaría
										</Text>
										<Text
											type={"h2"}
											weight="semibold"
											style={{
												color: ColorV2.text.primary,
											}}
										>
											36
											<span
												style={{
													fontSize: 18,
													fontWeight: 500,
												}}
											>
												€
											</span>
										</Text>
									</Col>
								</Row>
							</DonationBanner>
							<ReliefIcon
								style={{
									position: "absolute",
									right: 0,
									bottom: -60,
								}}
							/>
						</Col>
					</HistoryRow>
				</InternalSection>
			</Section>
			<Section>
				<InternalSection>
					<Text
						type="h2"
						weight="semibold"
						style={{ marginBottom: 80, textAlign: "center" }}
					>
						¿A dónde va mi donación?
					</Text>
					<ProgressContainer>
						<PieChartCol>
							{renderProgressView(chartOptions[0])}
						</PieChartCol>
						<PieChartView>
							<PieChart
								lineWidth={20}
								style={{ height: "100%", width: "100%" }}
								data={chartOptions}
							/>
						</PieChartView>
						<PieChartCol
							style={{ justifyContent: "space-between" }}
						>
							{renderProgressView(chartOptions[1])}
							{renderProgressView(chartOptions[2])}
						</PieChartCol>
					</ProgressContainer>
				</InternalSection>
			</Section>
			<Section>
				<Text
					type="h2"
					weight="semibold"
					style={{ textAlign: "center", marginBottom: 24 }}
				>
					Eres una parte clave del proyecto
				</Text>
				<Text
					type="h5"
					style={{ marginBottom: 48, textAlign: "center" }}
				>
					Gracias a la ayuda de nuestros más de 1.200 socios,
					logramos:
				</Text>
				<SectionRow>
					<Col style={{ alignItems: "center" }}>
						<GrandpaIcon type="tribute" />
						<Text
							type="h5"
							weight="semibold"
							style={{
								textAlign: "center",
								marginTop: 16,
								marginBottom: 8,
							}}
						>
							Ningún abuelo tiene que pagar nada
						</Text>
						<Text type="p2" style={{ textAlign: "center" }}>
							Gracias a tu donación, los mayores reciben
							acompañamiento semanal y participan en nuestras
							actividades de forma gratuita.
						</Text>
					</Col>
					<Col style={{ alignItems: "center" }}>
						<GrandpaIcon type="grandpa-grandma" />
						<Text
							type="h5"
							weight="semibold"
							style={{
								textAlign: "center",
								marginTop: 16,
								marginBottom: 8,
							}}
						>
							Abuelos eternos
						</Text>
						<Text type="p2" style={{ textAlign: "center" }}>
							Ellos han dado todo por nosotros y ha llegado el
							momento de darlo todo por ellos. El siglo XXI no es
							el siglo de la inteligencia artificial, es el siglo
							de la inclusión social.
						</Text>
					</Col>
					<Col style={{ alignItems: "center" }}>
						<GrandpaIcon type="grandpa-volunteer" />
						<Text
							type="h5"
							weight="semibold"
							style={{
								textAlign: "center",
								marginTop: 16,
								marginBottom: 8,
							}}
						>
							Bienestar emocional
						</Text>
						<Text type="p2" style={{ textAlign: "center" }}>
							Estamos comprometidos con la salud mental, por eso
							los casos más graves de depresión reciben
							acompañamiento psicológico gratuito.
						</Text>
					</Col>
				</SectionRow>
			</Section>
			<Section>
				<InternalSection style={{ alignItems: "center" }}>
					<Text
						type="h2"
						weight="semibold"
						style={{ textAlign: "center", marginBottom: 24 }}
					>
						La historia de Adopta Un Abuelo
					</Text>
					<Text
						type="h5"
						style={{ marginBottom: 48, textAlign: "center" }}
					>
						Descubre nuestros inicios y cómo nuestros voluntarios y
						voluntarias ayudan a combatir la soledad no deseada.
					</Text>
					<VideoFrame>
						<iframe
							width="100%"
							height="100%"
							src="https://www.youtube-nocookie.com/embed/v7pOJ-7W8AY?controls=0"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowFullScreen
						/>
					</VideoFrame>
				</InternalSection>
			</Section>
			<Section>
				<InternalSection>
					<Text
						type="h2"
						weight="semibold"
						style={{ textAlign: "center", marginBottom: 24 }}
					>
						Colabora como ya han hecho miles de personas
					</Text>
					<Text
						type="h5"
						style={{ marginBottom: 64, textAlign: "center" }}
					>
						Porque lo que recibes a cambio siempre es mayor a lo que
						das.
					</Text>
					<TestimonyList options={testimonies} />
				</InternalSection>
			</Section>
			<Section>
				<SectionRow>
					<Col style={{ justifyContent: "center" }}>
						<Text
							type="h1"
							weight="semibold"
							style={{ lineHeight: "56px", marginBottom: 24 }}
						>
							Hablan de nosotros
						</Text>
						<Text type="h6">+1.200 menciones en prensa</Text>
					</Col>
					<Col style={{ justifyContent: "space-between", gap: 24 }}>
						<PressRow>
							<PressLogo type="tve" />
							<PressLogo type="a3" />
							<PressLogo type="cuatro" />
							<PressLogo type="cope" />
						</PressRow>
						<PressRow>
							<PressLogo type="pais" />
							<PressLogo type="mundo" />
							<PressLogo type="forbes" />
							<PressLogo type="onda" />
						</PressRow>
					</Col>
				</SectionRow>
			</Section>
			<SectionFinal>
				<InternalSection style={{ alignItems: "center" }}>
					<Text
						type="h2"
						weight="semibold"
						style={{
							marginBottom: 16,
							marginTop: 16,
							textAlign: "center",
						}}
					>
						En Adopta un Abuelo acompañamos a personas mayores en
						situación de soledad con una única condición:
					</Text>
					<Text
						type="h2"
						weight="semibold"
						style={{
							marginBottom: 16,
							marginTop: 16,
							textAlign: "center",
							color: Color.text.primary,
						}}
					>
						Ellos no pagan nada.
					</Text>
					<DesktopButton
						onClick={() => props.onClick && props.onClick()}
					>
						Quiero ser socio/a
					</DesktopButton>
				</InternalSection>
			</SectionFinal>
		</Container>
	);
};
export default Info;
export interface Props {
	style?: CSSProperties;
	onClick?: () => void;
}
