import { useCallback, useState } from "react";
import SectionContainer from "./SectionContainer";
import {
	UserScheme,
	Auth,
	CoorporateScheme,
	ErrorScheme,
	Constant,
} from "client-v2";
import ReactPixel from "react-facebook-pixel";
import { ColorV2, Input } from "@adoptaunabuelo/react-components";
import { useSelector } from "react-redux";
import { State } from "../../../redux";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ReCaptchaButton from "../../../components/button/ReCaptchaButton";

const Login = (props: LoginProps) => {
	const { executeRecaptcha } = useGoogleReCaptcha();
	const translation = useSelector(
		(state: State) => state.location.translation
	);
	const appLocation = useSelector((state: State) => state.location.location);
	const colors =
		props.corporate && props.corporate.color
			? {
					buttonBackground: props.corporate.color.main,
					buttonColor: props.corporate.color.third,
			  }
			: {
					buttonBackground: ColorV2.surface.primaryHard,
					buttonColor: "white",
			  };
	const [loading, setLoading] = useState(false);
	const [phoneData, setPhoneData] = useState<
		| {
				value: string;
				country: string;
				isValid: boolean;
		  }
		| undefined
	>(undefined);
	const [phoneError, setPhoneError] = useState<string | undefined>(undefined);
	const phoneCountries = Constant.countries();

	const onNextClick = (recaptchaToken: string) => {
		console.log(recaptchaToken);
		const result = getData();
		console.log(result);
		if (result && result.phone) {
			setLoading(true);
			Auth.register({
				//recaptchaToken: recaptchaToken,
				phone: result.phone,
				coorporateId: props.corporate?.objectId,
			})
				.then((result) => {
					if (!result.data.isLogin) {
						//Track on Facebook
						ReactPixel.track("fb_mobile_activate_app");
						localStorage.setItem(
							"temp-login",
							JSON.stringify({
								date: new Date(),
								user: result.data.User,
							})
						);
						props.onFinish({
							data: result.data.User,
						});
					} else {
						const user = result.data.User;
						Auth.sendCode({
							//recaptchaToken: recaptchaToken,
							userId: user.objectId,
						})
							.then((result) => {
								setLoading(false);
								localStorage.setItem(
									"temp-login",
									JSON.stringify({
										date: new Date(),
										user: result.data,
									})
								);
								props.onFinish({
									data: result.data,
								});
							})
							.catch((error: ErrorScheme) => {
								setLoading(false);
								props.onFinish({
									error: error.message,
								});
							});
					}
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
					props.onFinish({
						error: error.message,
					});
				});
		}
	};

	const getData = () => {
		console.log(phoneData);
		if (phoneData?.country && phoneData?.value) {
			if (phoneData.isValid) {
				setPhoneError(undefined);
				return {
					phone: phoneData.country + phoneData.value,
				};
			} else {
				setPhoneError(translation.component_input_phone_error_01);
				return undefined;
			}
		} else {
			setPhoneError(translation.component_input_phone_error_02);
			return undefined;
		}
	};

	const onInputChange = (data: any) => {
		setPhoneData(data);
		setPhoneError(undefined);
	};

	const onKeyDown = (e: any) => {
		if (e.key === "Enter") {
			//onNextClick();
		}
	};

	return (
		<SectionContainer
			title={translation.login_title}
			subtitle={translation.login_subtitle}
			animated={props.animated}
			buttonProps={{
				action: "login",
				style: {
					backgroundColor: colors.buttonBackground,
					color: colors.buttonColor,
				},
				children: translation.component_button_next,
				loading: loading,
				onClick: onNextClick,
			}}
		>
			<Input
				id={"input-phone"}
				type="tel"
				autoComplete={"tel"}
				autoFocus={true}
				name={"telephone"}
				containerStyle={{ marginBottom: 48, marginTop: 32 }}
				placeholder={translation.component_input_phone_placeholder}
				error={phoneError}
				country={
					appLocation && appLocation.country_code
						? appLocation.country_code
						: "ES"
				}
				countryOptions={phoneCountries}
				design={"secondary"}
				onKeyDown={onKeyDown}
				onPhoneChange={onInputChange}
			/>
		</SectionContainer>
	);
};
export default Login;
export interface LoginProps {
	corporate?: CoorporateScheme;
	animated?: boolean;
	onFinish: (result: { data?: UserScheme; error?: string }) => void;
}
