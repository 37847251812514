import styled from "styled-components";
import { ChallengeScheme, UserScheme } from "client-v2";

import { Color, Text, Button, Menu } from "@adoptaunabuelo/react-components";
import {
	Heart,
	Link,
	MessageCircle,
	Facebook,
	Twitter,
	Share,
} from "lucide-react";
import Relief from "../../../components/section/Relief";

const Background = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;
const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 550px;
`;
const Section = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-top: 48px;
`;
const MobileSection = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin: 48px 0px;
	padding: 24px;
	border-radius: 12px;
	background-color: white;
`;
const HowWork = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;
const Row = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
`;
const Col = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
const Numbers = styled(Text)`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	margin-right: 16px !important;
	height: 40px;
	width: 40px;
	border-radius: 30px;
	color: ${Color.text.primary} !important;
`;

const Success = (props: Props) => {
	const onShareClick = (op: any) => {
		const url =
			"https://adoptaunabuelo.org/cumpleanios/?id=" +
			props.challenge?.objectId;
		if (op.id === "twitter") {
			window.open("http://www.twitter.com/share?url=" + url, "_blank");
		} else if (op.id === "facebook") {
			window.open("http://www.facebook.com/sharer.php?u=" + url);
		} else if (op.id === "whatsapp") {
			window.open("whatsapp://send?text=" + url);
		}
		if (op.id === "url") {
			navigator.clipboard.writeText(url);
		}
	};

	return (
		<Background>
			<Container>
				<Heart
					height={48}
					width={48}
					fill={Color.text.primary}
					color={"transparent"}
				/>
				<Text type="h3" weight="semibold">
					¡Muchas gracias!
				</Text>
				<Text type="p" style={{ marginTop: 12, marginBottom: 12 }}>
					Hemos recibido tu felicitación. Gracias a gente como tú
					hacemos un poquito más felices a nuestros mayores.
				</Text>
				<Section>
					<Text
						type="h4"
						weight="semibold"
						style={{ marginBottom: 24 }}
					>
						¿Y ahora qué?
					</Text>
					<HowWork>
						<Row>
							<Numbers type="p" weight="semibold">
								1
							</Numbers>
							<Col>
								<Text type="h6" weight="medium">
									Juntamos todas vuestras felicitaciones
								</Text>
								<Text
									type="p"
									style={{
										color: Color.text.high,
										marginTop: 4,
									}}
								>
									Recopilamos todas las cartas y las juntamos
									para enviarlas.
								</Text>
							</Col>
						</Row>
						<Row>
							<Numbers type="p" weight="semibold">
								2
							</Numbers>
							<Col>
								<Text type="h6" weight="medium">
									Compramos los regalos
								</Text>
								<Text
									type="p"
									style={{
										color: Color.text.high,
										marginTop: 4,
									}}
								>
									Gracias a vuestra colaboración haremos este
									día un poquito más especial.
								</Text>
							</Col>
						</Row>
						<Row>
							<Numbers type="p" weight="semibold">
								3
							</Numbers>
							<Col>
								<Text type="h6" weight="medium">
									¡Hora de celebrar!
								</Text>
								<Text
									type="p"
									style={{
										color: Color.text.high,
										marginTop: 4,
									}}
								>
									Organizaremos una fiesta para celebrar su
									cumpleaños y hacerle llegar todas vuestras
									felicitaciones y regalos. ¡Os lo contaremos
									todo por nuestras redes!
								</Text>
							</Col>
						</Row>
					</HowWork>
				</Section>
				{props.user && !props.user.idCard && (
					<Relief style={{ marginTop: 48 }} user={props.user} />
				)}
				<MobileSection>
					<Text type="h6" weight="semibold">
						¡Ayúdanos a difundirlo!
					</Text>
					<Text type="p" style={{ marginTop: 8, marginBottom: 24 }}>
						Llegar a más gente significa más felicitaciones y más
						cumpleaños celebrados. Comparte para ayudarnos a cumplir
						nuestra misión.
					</Text>
					<Menu
						id={"share"}
						position={"top-right"}
						menuStyle={{ width: 200, top: 32 }}
						options={[
							{
								id: "twitter",
								label: "Twitter",
								icon: <Twitter height={20} width={20} />,
							},
							{
								id: "facebook",
								label: "Facebook",
								icon: <Facebook height={20} width={20} />,
							},
							{
								id: "whatsapp",
								label: "Whatsapp",
								icon: <MessageCircle height={20} width={20} />,
							},
							{
								id: "url",
								label: "Copiar enlace",
								icon: <Link height={20} width={20} />,
							},
						]}
						Icon={
							<Button
								design={"secondary"}
								icon={<Share color={Color.text.primary} />}
							>
								Compartir
							</Button>
						}
						onClick={onShareClick}
					/>
				</MobileSection>
			</Container>
		</Background>
	);
};
export default Success;
export interface Props {
	challenge?: ChallengeScheme;
	user?: UserScheme;
}
