import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { State } from "../redux";

import SignUpWizard from "../views/register/RegisterWizard";
import Loading from "../components/modal/Loading";
import { Feedback } from "@adoptaunabuelo/react-components";

const Register = (props: Props) => {	
	const error = useSelector((state: State) => state.modals.error);
	const loading = useSelector((state: State) => state.modals.loading);

	return (
		<>
			<Loading isVisible={loading ? true : false} />
			<Feedback
				isVisible={error.show}
				type="error"
				text={error.message}
			/>
			<Routes>
				<Route path={"/"} element={<SignUpWizard />} />
			</Routes>
		</>
	);
};

export default Register;
export interface Props {}
