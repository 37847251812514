import {
	useState,
	forwardRef,
	useImperativeHandle,
	Ref,
	useEffect,
} from "react";
import styled from "styled-components";
import { User, UserScheme } from "client-v2";
import axios from "axios";
import { useSelector } from "react-redux";
import { State } from "../../redux";
import SectionContainer from "../container/SectionContainer";
import {
	Text,
	Color,
	Avatar,
	Checkbox,
	Modal,
	Button,
	Input,
} from "@adoptaunabuelo/react-components";

const Section = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 12px;
	background-color: white;
`;
const Col = styled.div`
	display: flex;
	flex-direction: column;
`;
const Cell = styled.div`
	display: flex;
	flex-direction: row;
	padding: 12px;
	border-bottom: 1px solid ${Color.line.soft};
	:last-child {
		border-bottom: none;
	}
`;

const SignatureStep = forwardRef(
	(props: SignatureStepProps, ref: Ref<SignatureRef>) => {
		const googleAPIKey = "AIzaSyA_H7WVmlnxy8OWrNuIJmGclYWwXFB49Wk";
		const isMobile = window.screen.width <= 768;
		const [modalError, setModalError] = useState<string | undefined>(
			undefined
		);
		const [user, setUser] = useState<UserScheme | undefined>(props.user);
		const [anonymous, setAnonymous] = useState(false);
		const [showAddIdCard, setShowAddIdCard] = useState(false);
		const [showAddAddress, setShowAddAddress] = useState(false);
		const [loading, setLoading] = useState(false);
		const [newData, setNewData] = useState<any>(undefined);

		useImperativeHandle(ref, () => ({
			getData() {
				return getData();
			},
		}));

		useEffect(() => {
			setUser(props.user);
		}, [props.user]);

		const getData = () => {
			if (user && user.idCard && user.city) {
				return {
					user,
					anonymous,
				};
			} else {
				return {
					user: undefined,
					anonymous: false,
				};
			}
		};

		const setAddress = () => {
			if (user) {
				setLoading(true);
				User.set(user.objectId, {
					address: newData.address,
					shortAddress: newData.sortAddress,
					city: newData.city,
					country: newData.country,
					timeZone: newData.timeZone,
					location: {
						lat: newData.coordinates.lat,
						lng: newData.coordinates.lng,
					},
				})
					.then((result) => {
						setUser(result.data);
						setLoading(false);
						setShowAddAddress(false);
					})
					.catch((e: string) => {
						setModalError(e);
						setLoading(false);
					});
			}
		};

		const setIdCard = () => {
			if (newData && validateCardId(newData.idCard) && user) {
				setLoading(true);
				User.set(user.objectId, {
					idCard: newData.idCard,
				})
					.then((result) => {
						setUser(result.data);
						setLoading(false);
						setShowAddIdCard(false);
					})
					.catch((error: string) => {
						setLoading(false);
						setModalError(error);
					});
			} else {
				setModalError(
					"El número de documento introducido no es válido"
				);
			}
		};

		const validateCardId = (id: string) => {
			var DNI_REGEX = /^(\d{8})([A-Z])$/;
			var NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;

			// Ensure upcase and remove whitespace
			const str = id.toUpperCase().replace(/\s/, "");

			//Check the id type
			if (str.match(DNI_REGEX)) {
				var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
				var letter = dni_letters.charAt(parseInt(str, 10) % 23);
				return letter == str.charAt(8);
			} else if (str.match(NIE_REGEX)) {
				// Change the initial letter for the corresponding number and validate as DNI
				var nie_prefix = str.charAt(0);
				switch (nie_prefix) {
					case "X":
						nie_prefix = "0";
						break;
					case "Y":
						nie_prefix = "1";
						break;
					case "Z":
						nie_prefix = "2";
						break;
				}
				const tempStr = nie_prefix + str.substr(1);

				//Validate NIE as DNI
				var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
				var letter = dni_letters.charAt(parseInt(tempStr, 10) % 23);
				return letter == tempStr.charAt(8);
			} else return false;
		};

		const onLocationChange = async (item: {
			address: string;
			geocoder: google.maps.GeocoderResult;
			location: google.maps.LatLngLiteral;
		}) => {
			setModalError(undefined);

			const locality = item.geocoder.address_components.filter((it) =>
				it.types.includes("locality")
			);
			const city = item.geocoder.address_components.filter((it) =>
				it.types.includes("administrative_area_level_2")
			);
			const country = item.geocoder.address_components.filter((it) =>
				it.types.includes("country")
			);
			const sortAddress =
				(locality.length > 0 ? locality[0].long_name + ", " : "") +
				(city.length > 0 ? city[0].long_name + ", " : "") +
				country[0].long_name;

			const temp = {
				coordinates: item.location,
				address: item.address,
				country: country[0].short_name,
				city: city.length > 0 ? city[0].long_name : undefined,
				sortAddress: sortAddress,
			};

			//Get the timezone
			const result2 = await axios({
				method: "GET",
				url:
					"https://maps.googleapis.com/maps/api/timezone/json?location=" +
					item.location.lat +
					"%2C" +
					item.location.lng +
					"&timestamp=1331161200&key=" +
					googleAPIKey,
			});
			if (result2.data.status === "OK") {
				setNewData({
					...newData,
					...temp,
					timeZone: result2.data.timeZoneId,
				});
			} else {
				setNewData({
					...newData,
					...temp,
				});
			}
		};

		return (
			<SectionContainer title={props.title}>
				<Modal
					isVisible={showAddIdCard}
					type={isMobile ? "full-screen" : "default"}
					title="Añade tu documento de identidad"
					subtitle="Esto permite certificar que eres tú quien firma la petición."
					error={modalError}
					buttonProps={{
						children: "Guardar",
						loading: loading,
						onClick: setIdCard,
					}}
					onClose={() => {
						setShowAddIdCard(false);
						setModalError(undefined);
					}}
				>
					<Input
						type={"text"}
						containerStyle={{ margin: "12px 0px" }}
						design={"secondary"}
						placeholder={"DNI o NIE"}
						onChange={(e) => {
							setModalError(undefined);
							setNewData({
								...newData,
								idCard: e.target.value,
							});
						}}
					/>
				</Modal>
				<Modal
					isVisible={showAddAddress}
					title="Añade tu ciudad"
					subtitle="Indica desde dónde firmas la petición."
					error={modalError}
					style={{
						overflow: "unset",
						overflowX: "unset",
						overflowY: "unset",
					}}
					type={isMobile ? "full-screen" : "default"}
					buttonProps={{
						children: "Guardar",
						loading: loading,
						onClick: setAddress,
					}}
					onClose={() => {
						setShowAddAddress(false);
						setModalError(undefined);
					}}
				>
					<Input
						type={"location"}
						containerStyle={{
							margin: isMobile ? "12px 0px 80px" : "2px 0px 12px",
						}}
						design={"secondary"}
						placeholder={"Tu ciudad"}
						searchOptions={{
							types: ["locality"],
						}}
						onLocationChange={onLocationChange}
					/>
				</Modal>
				{user && (
					<Section>
						<Cell style={{ alignItems: "center", gap: 12 }}>
							<Avatar
								style={{ height: 48, width: 48 }}
								name={user.name}
								icon={user.image && user.image.url}
							/>
							<Text type="p">
								{user.name} {user.surname}
							</Text>
						</Cell>
						<Cell style={{ justifyContent: "space-between" }}>
							<Col>
								<Text
									type="c1"
									style={{ color: Color.text.high }}
								>
									Ciudad
								</Text>
								<Text type="p">
									{user.city ? user.city : "-"}
								</Text>
							</Col>
							{!user.city && (
								<Button
									design="call-to-action"
									onClick={() => setShowAddAddress(true)}
								>
									Añadir
								</Button>
							)}
						</Cell>
						<Cell style={{ justifyContent: "space-between" }}>
							<Col>
								<Text
									type="c1"
									style={{ color: Color.text.high }}
								>
									DNI/NIE
								</Text>
								<Text type="p">
									{user.idCard ? user.idCard : "-"}
								</Text>
							</Col>
							{!user.idCard && (
								<Button
									design="call-to-action"
									onClick={() => setShowAddIdCard(true)}
								>
									Añadir
								</Button>
							)}
						</Cell>
					</Section>
				)}
				<Checkbox
					style={{ marginTop: 32 }}
					type="multiple"
					options={[
						{
							id: "anonymous",
							label: "No mostrar mi firma públicamente en esta petición",
						},
					]}
					onChange={(op: Array<any>) =>
						setAnonymous(op.length > 0 ? true : false)
					}
				/>
			</SectionContainer>
		);
	}
);
export default SignatureStep;
export interface SignatureStepProps {
	title?: string;
	user?: UserScheme;
}
export interface SignatureRef {
	getData: () => {
		user?: UserScheme;
		anonymous: boolean;
	};
}
