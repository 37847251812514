import { useEffect, useState } from "react";
import { Feed } from "client-v2";
import styled from "styled-components";

import DreamCell from "../../components/feed/DreamCell";
import BirthdayCell from "../../components/feed/BirthdayCell";
import SignatureCell from "../../components/feed/SignatureCell";
import { Button, ColorV2, Text } from "@adoptaunabuelo/react-components";
import { ChevronLeft, ChevronRight } from "lucide-react";
import LetterCell from "../../components/feed/LetterCell";

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;
const CellContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 12px;
`;
const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: 8px;
`;

const HomeView = (props: Props) => {
	const letter = {
		obj: {
			type: "letter",
			title: "La felicidad llega en un sobre",
			image: {
				url: "https://adoptaunabuelo.org/wp-content/uploads/2024/11/video-673347741ead3.webp",
			},
		},
		amount: 0,
	};
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState(0);
	const [challengeArray, setChallengeArray] = useState<
		Array<{ obj: any; amount?: number }>
	>([letter]);

	useEffect(() => {
		getChallenge();
	}, []);

	const getChallenge = () => {
		setLoading(true);
		Feed.getForWeb({
			version: 1,
		})
			.then((result) => {
				setChallengeArray([letter, ...result.data]);
				setLoading(false);
			})
			.catch((e: string) => {
				console.error(e);
				setLoading(false);
			});
	};

	return (
		<Container>
			<ButtonContainer>
				<Text type="p">
					{step + 1}/{challengeArray.length}
				</Text>
				<Button
					design="image"
					icon={<ChevronLeft />}
					style={{
						border: "1px solid " + ColorV2.border.neutralSoft,
						borderRadius: 80,
						height: 32,
						width: 32,
					}}
					disabled={step > 0 ? false : true}
					onClick={() => {
						if (step > 0) setStep(step - 1);
					}}
				/>
				<Button
					design="image"
					icon={<ChevronRight />}
					style={{
						border: "1px solid " + ColorV2.border.neutralSoft,
						borderRadius: 80,
						height: 32,
						width: 32,
					}}
					disabled={step < challengeArray.length - 1 ? false : true}
					onClick={() => {
						if (step < challengeArray.length - 1) setStep(step + 1);
					}}
				/>
			</ButtonContainer>
			<CellContainer>
				{challengeArray.map((challenge, index) => {
					if (step === index) {
						if (challenge.obj.type === "dream")
							return <DreamCell challenge={challenge.obj} />;
						else if (challenge.obj.type === "birthday")
							return (
								<BirthdayCell
									challenge={challenge.obj}
									letters={
										challenge.amount ? challenge.amount : 0
									}
								/>
							);
						else if (challenge.obj.type === "signature")
							return <SignatureCell challenge={challenge.obj} />;
						else if (challenge.obj.type === "letter")
							return <LetterCell challenge={challenge.obj} />;
						else return null;
					} else return null;
				})}
			</CellContainer>
		</Container>
	);
};
export default HomeView;
export interface Props {
	
}
