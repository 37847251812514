import { useEffect, useState } from "react";
import {
	Product,
	EventScheme,
	ProductScheme,
} from "client-v2";
import { Route, Routes, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import media from "styled-media-query";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../redux";
import { bindActionCreators } from "redux";

import Detail from "../views/event/Detail";
import Checkout from "../views/event/Checkout";

import Loading from "../components/modal/Loading";
import { Feedback } from "@adoptaunabuelo/react-components";

const FeedbackStyled = styled(Feedback)`
	right: 24px !important;
	left: unset !important;
	${media.lessThan("small")`
        top: 64px;
        left: 24px !important;
        bottom: unset !important;
	`}
`;

const EventLayout = (props: PayoutLayoutProps) => {
	const dispatch = useDispatch();
	const { setShowError, setShowSuccess } = bindActionCreators(
		actionsCreators,
		dispatch
	);
	const error = useSelector((state: State) => state.modals.error);
	const success = useSelector((state: State) => state.modals.success);
	const loading = useSelector((state: State) => state.modals.loading);

	const [searchParams, setSearchParams] = useSearchParams();
	const [product, setProduct] = useState<ProductScheme | undefined>(
		undefined
	);
	const [event, setEvent] = useState<EventScheme | undefined>(undefined);

	useEffect(() => {
		const id = searchParams.get("id");
		if (id) {
			Product.getById(id, {
				include: ["Event"],
			})
				.then((result) => {
					setProduct(result.data);
					const event = result.data.Event;
					if (event) {
						setEvent(event);
					} else {
						setShowError({
							show: true,
							message: "El id introducido no es válido",
						});
					}
				})
				.catch((error) => {
					setShowError({
						show: true,
						message: "El parámetro id no es válido",
					});
				});
		} else {
			setShowError({
				show: true,
				message: "El parámetro id no es válido",
			});
		}
	}, []);

	return (
		<>
			<FeedbackStyled
				isVisible={error.show}
				type="error"
				text={error.message}
				onClose={() => setShowError({ show: false })}
			/>
			<FeedbackStyled
				isVisible={success.show}
				type="success"
				text={success.message}
				onClose={() => setShowSuccess({ show: false })}
			/>
			<Loading isVisible={loading ? true : false} />
			<Routes>
				<Route
					path="/"
					element={
						product && event ? (
							<Detail product={product} event={event} />
						) : (
							<Loading isVisible={true} />
						)
					}
				/>
				<Route path="/registro" element={<Checkout />} />
			</Routes>
		</>
	);
};

export default EventLayout;
export interface PayoutLayoutProps {}
