import styled from "styled-components";
import media from "styled-media-query";

import { Player } from "@lottiefiles/react-lottie-player";
import { Text, Color } from "@adoptaunabuelo/react-components";
import animation from "../../../assets/animations/heart.json";

const Container = styled.div`
	display: flex;
	flex: 1;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	${media.lessThan("medium")`
        align-items: center;
        margin-top: 0px;
        margin-left: 0px;
        justify-content: center;
    `}
`;
const DataContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 500px;
	${media.lessThan("medium")`
        max-width: 100%;
    `}
`;
const StepContainer = styled.div`
	display: flex;
	flex: 1;
	width: 100%;
	flex-direction: column;
`;
const Col = styled.div`
	display: flex;
	flex-direction: column;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
	gap: 12px;
`;
const IconImage = styled.img`
	width: 135px;
	height: 40px;
	object-fit: cover;
	border-radius: 8px;
	cursor: pointer;
`;

const Success = (props: Props) => {
	const isMobile = window.screen.width <= 768;

	const steps = [
		{
			title: "Descarga nuestra app",
			subtitle: "E iniciar sesión con tu número de teléfono.",
		},
		{
			title: "Completa la formación",
			subtitle:
				"Te enseñaremos todo lo necesario para que puedas acompañar a un mayor.",
		},
		{
			title: "¡Diviértete!",
			subtitle:
				"Encontraremos al mayor ideal para ti. Podrás llamarla y visitarla para hacerle compañía.",
		},
	];

	return (
		<Container>
			<DataContainer>
				<Player
					style={{
						width: isMobile ? 300 : 400,
						height: isMobile ? 300 : 400,
						marginTop: isMobile ? -100 : -140,
						marginBottom: isMobile ? -100 : -140,
					}}
					keepLastFrame={true}
					autoplay={true}
					src={animation}
				/>
				<Text
					type="h3"
					weight="semibold"
					style={{ textAlign: "center" }}
				>
					¡Hola, {props.user.name}!
				</Text>
				<Text
					type="p"
					style={{
						marginTop: 12,
						textAlign: "center",
						marginBottom: 48,
					}}
				>
					Ya formas parte de la comunidad que piensa que los abuelos
					son geniales.
				</Text>
				<Text type="h4" weight="semibold" style={{ marginBottom: 32 }}>
					¿Y ahora qué?
				</Text>
				<StepContainer style={{ gap: 18 }}>
					{steps.map((step, index) => (
						<Row style={{ flex: 1, gap: 12 }}>
							<Text
								type="p"
								weight="semibold"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									backgroundColor: Color.background.soft,
									height: 36,
									width: 36,
									borderRadius: 40,
								}}
							>
								{index + 1}
							</Text>
							<Col style={{ flex: 1 }}>
								<Text type="p" weight="medium">
									{step.title}
								</Text>
								<Text
									type="p2"
									style={{ color: Color.text.high }}
								>
									{step.subtitle}
								</Text>
							</Col>
						</Row>
					))}
					<Row style={{ justifyContent: "center", marginTop: 32 }}>
						<IconImage
							src={require("../../../assets/logo/android.png")}
							onClick={() =>
								window.open(
									"https://play.google.com/store/apps/details?id=com.adoptaunabuelo.app&hl=es_419&gl=ES&pli=1",
									"_blank"
								)
							}
						/>
						<IconImage
							src={require("../../../assets/logo/ios.png")}
							onClick={() =>
								window.open(
									"https://apps.apple.com/es/app/adopta-un-abuelo/id1559513523",
									"_blank"
								)
							}
						/>
					</Row>
				</StepContainer>
			</DataContainer>
		</Container>
	);
};
export default Success;
export interface Props {
	user: any;
}
