import {
	useEffect,
	useRef,
	ComponentPropsWithoutRef,
	forwardRef,
	Ref,
	useImperativeHandle,
} from "react";
import moment from "moment";

const LetterCanvas = forwardRef((props: Props, ref: Ref<CanvasRef>) => {
	const canvasRef = useRef<HTMLCanvasElement | null>(null);
	const height = 1920;
	const width = 1080;

	useImperativeHandle(ref, () => ({
		toDataURL() {
			if (canvasRef.current) {
				return canvasRef.current.toDataURL("image/png");
			} else return undefined;
		},
	}));

	useEffect(() => {
		const canvas = canvasRef.current;
		if (canvas) {
			const ctx = canvas.getContext("2d");
			if (ctx) {
				//Generate the image
				var img = new Image();
				img.src = props.image;
				img.onload = (e: any) => {
					ctx.drawImage(img, 0, 0, width, height);

					//Add text
					ctx.font = "bold 82px Poppins";
					ctx.fillStyle = props.textColor;
					ctx.textAlign = "center";
					ctx.fillText(props.name, 540, props.design === "A" ? 440 : props.design === "B" ? 670 : 600);

					const ctx2 = canvas.getContext("2d");
					if (props.birthday && ctx2) {
						const years = moment().diff(props.birthday, "years");
						ctx2.font = "52px Poppins";
						ctx.fillStyle = props.textColor;
						ctx2.textAlign = "center";
						ctx2.fillText("de " + years + " años", 540, props.design === "A" ? 520 : props.design === "B" ? 750 : 680);
					}
				};
			}
		}
	}, [props.image]);

	return <canvas ref={canvasRef} width={width} height={height} {...props} />;
});
export default LetterCanvas;
export interface Props extends ComponentPropsWithoutRef<"canvas"> {
	image: string;
	name: string;
	birthday?: string;
	textColor: string;
    design: string
}
export interface CanvasRef {
	toDataURL: () => string | undefined;
}
