import { useState, forwardRef, useImperativeHandle, Ref, useEffect } from 'react';
import styled from 'styled-components';

import SectionContainer from '../container/SectionContainer';
import { Shuffle } from 'lucide-react';
import { Text, Color, CellSelector } from '@adoptaunabuelo/react-components';

const Icon = styled.img`
    height: 24px;
    width: 24px;
`

const GenderStep = forwardRef((props: GenderStepProps, ref: Ref<GenderRef>) => {

    const [ badInput, setBadInput ] = useState(false);
    const [ gender, setGender ] = useState<string | undefined>(undefined);

    useImperativeHandle(ref, () => ({
        getData() {
            return getData();
        }
    }));

    useEffect(() =>{
        if(gender){
            const result = getData();
            props.onChange && props.onChange(result);
        }
    }, [gender]);

    const getData = () =>{
        if(gender){
            return { gender };
        }
        else{
            setBadInput(true);
            return undefined;
        }
    }

    const onGenderChange = (item: any) =>{
        setGender(item.id);
        setBadInput(false);
    }

    return(
        <SectionContainer
            title={props.title}
        >
            <CellSelector
                options={[
                    {
                        id: 'male',
                        title: 'Hombre',
                        icon: <Icon src={require('../../assets/images/icon/Man.png')}/>
                    },
                    {
                        id: 'female',
                        title: 'Mujer',
                        icon: <Icon src={require('../../assets/images/icon/Woman.png')}/>
                    },
                    {
                        id: 'none',
                        title: 'Otro',
                        icon: <Shuffle/>
                    }
                ]}
                onClick={onGenderChange}
            />
            {badInput && 
                <Text
                    type='p'
                    style={{marginTop: 12, color: Color.status.color.error, fontSize: 14}}
                >
                    Debes seleccionar al menos una de las opciones.
                </Text>
            }
        </SectionContainer>
    )
})
export default GenderStep;
export interface GenderStepProps{
    title?: string,
    onChange?: (result : undefined | {
        gender: string
    }) => void
}
export interface GenderRef{
    getData: () => undefined | {
        gender: string
    }
}