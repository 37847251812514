import { useEffect, useState } from "react";
import styled from "styled-components";
import {
	CoorporateScheme,
	ErrorScheme,
	Regex,
	User,
	UserScheme,
} from "client-v2";
import media from "styled-media-query";
import { useSelector } from "react-redux";
import { State } from "../../../redux";

import SectionContainer from "./SectionContainer";
import {
	Input,
	Text,
	Checkbox,
	Color,
	ColorV2,
} from "@adoptaunabuelo/react-components";
import LetterLine from "../../../assets/images/background/LetterLine";
import moment from "moment";

const FatherContainer = styled.div<{ $hidden: boolean; $animated?: boolean }>`
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 388px;
	padding-top: 66px;
	padding-bottom: 66px;
	padding-right: 100px;
	border-radius: 12px;
	margin-bottom: 72px;
	transition: ${(props) =>
		!props.$animated ? "unset" : "transform 1s ease-in-out"};
	transform: ${(props) =>
		!props.$animated
			? "unset"
			: props.$hidden
			? `translateX(-${window.screen.width / 2}px) translateY(300px)`
			: "translateX(0px) translateY(0px)"};
	${(props) => media.lessThan("medium")`
        padding: 40px 0px 0px;
		margin: 0px;
		transition: ${!props.$animated ? "unset" : "transform .5s ease-in-out"};
		width: 100%;
		transform: ${
			!props.$animated
				? "unset"
				: props.$hidden
				? `translateX(-${window.screen.width}px) translateY(200px)`
				: "translateX(0px) translateY(0px)"
		};
	`}
`;
const TermsText = styled(Text)`
	font-size: 13px !important;
	color: ${Color.text.high};
`;
const Link = styled.a`
	color: ${Color.text.high};
	&:hover {
		text-decoration: underline;
	}
`;
const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: 8px;
	${media.lessThan("medium")`
        padding: 0px 28px 0px 0px;
    `}
`;
const Background = styled.div`
	position: absolute;
	top: 0px;
	right: 0px;
	height: 100%;
	width: 100vw;
	background-color: white;
	border-radius: 24px 24px 24px 0px;
	box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
	overflow: hidden;
	${media.lessThan("medium")`
        right: unset;
		left: 0px;
		border-radius: 0px 24px 0px 0px;
	`}
`;
const CheckboxView = styled.div`
	display: flex;
	flex: 1;
	align-items: flex-end;
	padding-top: 60px;
	${media.lessThan("medium")`
        padding-top: 16px;
	`}
`;

const Personal = (props: PersonalProps) => {
	const isMobile = useSelector((state: State) => state.screen.isMobile);
	const translation = useSelector(
		(state: State) => state.location.translation
	);
	const colors =
		props.corporate && props.corporate.color
			? {
					buttonBackground: props.corporate.color.main,
					buttonColor: props.corporate.color.third,
					animation: [
						props.corporate.color.main,
						props.corporate.color.secondary,
					],
			  }
			: {
					buttonBackground: ColorV2.surface.primaryHard,
					buttonColor: "white",
					animation: ["#00315C", "#005AA8"],
			  };

	const [selection, setSelection] = useState({
		name: props.user.name,
		surname: props.user.surname,
		email: props.user.email,
		terms: false,
		birthday: props.user.birthday
			? new Date(props.user.birthday.iso)
			: undefined,
	});
	const [errors, setErrors] = useState<{
		name: string | undefined;
		surname: string | undefined;
		email: string | undefined;
		terms: string | undefined;
		birthday: string | undefined;
	}>({
		name: undefined,
		surname: undefined,
		email: undefined,
		terms: undefined,
		birthday: undefined,
	});
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);

	useEffect(() => {
		setShow(true);
		return () => {
			setShow(false);
		};
	}, []);

	const onNextClick = () => {
		if (
			selection.name &&
			selection.surname &&
			selection.email &&
			selection.terms &&
			selection.birthday
		) {
			const emailIsValid = Regex.email(selection.email);
			if (emailIsValid) {
				setErrors({
					name: undefined,
					surname: undefined,
					email: undefined,
					terms: undefined,
					birthday: undefined,
				});
				setLoading(true);
				User.set(props.user.objectId, {
					name: selection.name,
					surname: selection.surname,
					email: selection.email,
					birthday: selection.birthday,
				})
					.then((result) => {
						setLoading(false);
						props.onFinish({ data: result.data });
					})
					.catch((e: ErrorScheme) => {
						setLoading(false);
						props.onFinish({ error: e.message });
					});
			} else {
				if (!emailIsValid)
					setErrors({
						...errors,
						email: translation.component_input_email_error_02,
					});
				return undefined;
			}
		} else {
			setErrors({
				name: !selection.name
					? translation.component_input_name_error
					: undefined,
				surname: !selection.surname
					? translation.component_input_surname_error
					: undefined,
				email: !selection.email
					? translation.component_input_email_error
					: undefined,
				terms: !selection.terms
					? translation.component_input_terms_error
					: undefined,
				birthday: !selection.birthday
					? translation.component_input_birth_error
					: undefined,
			});
			return undefined;
		}
	};

	const onInputChange = (input: string, value: string) => {
		if (input === "name") {
			setSelection({
				...selection,
				name: value,
			});
			setErrors({
				...errors,
				name: undefined,
			});
		} else if (input === "surname") {
			setSelection({
				...selection,
				surname: value,
			});
			setErrors({
				...errors,
				surname: undefined,
			});
		} else if (input === "email") {
			setSelection({
				...selection,
				email: value,
			});
			setErrors({
				...errors,
				email: undefined,
			});
		} else if (input === "birthday") {
			setSelection({
				...selection,
				birthday: new Date(value),
			});
			setErrors({
				...errors,
				birthday: undefined,
			});
		}
	};

	const onTermsChange = (check: Array<any>) => {
		setErrors({
			...errors,
			terms: undefined,
		});
		if (check.length > 0)
			setSelection({
				...selection,
				terms: true,
			});
		else
			setSelection({
				...selection,
				terms: false,
			});
	};

	return (
		<FatherContainer $hidden={!show} $animated={props.animated}>
			<Background>
				{!isMobile && (
					<LetterLine position="bottom" color={colors.animation} />
				)}
				<LetterLine position="right" color={colors.animation} />
				<LetterLine position="top" color={colors.animation} />
			</Background>
			<SectionContainer
				style={{ position: "relative" }}
				childrenProps={{
					style: { flex: 1 },
				}}
				buttonProps={{
					style: {
						backgroundColor: colors.buttonBackground,
						color: colors.buttonColor,
					},
					loading: loading,
					children: translation.component_button_next,
					onClick: onNextClick,
				}}
			>
				<Container>
					<Text
						type="p"
						style={{
							fontFamily: "DM Mono",
							textTransform: "uppercase",
							marginBottom: 16,
							color: Color.text.high,
						}}
					>
						{translation.letter_personal_sender}
					</Text>
					<Input
						type="text"
						defaultValue={selection.name}
						placeholder={translation.component_input_name_placeholder}
						error={errors.name}
						design={"third"}
						onChange={(e: any) =>
							onInputChange("name", e.target.value)
						}
					/>
					<Input
						type="text"
						defaultValue={selection.surname}
						placeholder={translation.component_input_surname_placeholder}
						error={errors.surname}
						design={"third"}
						onChange={(e: any) =>
							onInputChange("surname", e.target.value)
						}
					/>
					<Input
						type="email"
						defaultValue={selection.email}
						placeholder={translation.component_input_email_placeholder}
						error={errors.email}
						icon={undefined}
						design={"third"}
						onChange={(e: any) =>
							onInputChange("email", e.target.value)
						}
					/>
					<Input
						type="date"
						defaultValue={
							selection.birthday
								? moment(selection.birthday).format(
										"DD/MM/YYYY"
								  )
								: undefined
						}
						placeholder={translation.component_input_birthday_placeholder}
						error={errors.birthday}
						icon={undefined}
						design={"third"}
						onChange={(e: any) => onInputChange("birthday", e)}
					/>
					<CheckboxView>
						<Checkbox
							elementStyle={{ padding: 0, margin: 0 }}
							options={[
								{
									id: "terms",
									Element: (
										<TermsText type="p">
											{translation.component_input_terms_text_01}
											<Link
												href="https://www.adoptaunabuelo.org/terminos-condiciones"
												target="_blank"
											>
												{translation.component_input_terms_text_02}
											</Link>{" "}
											{translation.component_input_terms_text_03}
											<Link
												href="https://www.adoptaunabuelo.org/politica-privacidad"
												target="_blank"
											>
												{translation.component_input_terms_text_04}
											</Link>
										</TermsText>
									),
									error: errors.terms ? true : false,
								},
							]}
							type={"multiple"}
							onChange={onTermsChange}
						/>
					</CheckboxView>
				</Container>
			</SectionContainer>
		</FatherContainer>
	);
};
export default Personal;
export interface PersonalProps {
	user: UserScheme;
	corporate?: CoorporateScheme;
	animated?: boolean;
	onFinish: (result: { data?: UserScheme; error?: string }) => void;
}
