import { useState, useImperativeHandle, forwardRef, Ref } from 'react';
import moment from 'moment';

import SectionContainer from '../container/SectionContainer';
import { Input } from '@adoptaunabuelo/react-components';

const BirthStep = forwardRef((props: Props, ref: Ref<BirthRef>) => {

    const [ date, setDate ] = useState<Date | undefined>(undefined);
    const [ inputError, setInputError ] = useState<string | undefined>(undefined);

    useImperativeHandle(ref, () => ({
        getData() {
            return getData();
        }
    }));

    const getData = () =>{
        if(date){
            //Check if volunteer is in the year margin
            const today = moment();
            const momentDate = moment(date);

            if(today.diff(momentDate, 'years') < 15){
                setInputError('Para unirte a Adopta Un Abuelo debes ser mayor de 15');                        
                return undefined;
            }
            else if(today.diff(momentDate, 'years') > 1000){
                setInputError('Debes añadir una fecha válida antes de continuar');                        
                return undefined;
            }

            setInputError(undefined);
            return {
                birthday: momentDate.toDate()
            };
        }
        else{
            setInputError('Introduce una fecha válida');
            return undefined;
        }
    }

    const onInputChange = (date: any) =>{
        setInputError(undefined);
        setDate(date)
    }

    const onKeyDown = (e: any) =>{
        if (e.key === 'Enter') {
            const result = getData();
            props.onEnter && props.onEnter(result);
        }
    }

    return(
        <SectionContainer>
            <Input
                type='date'
                placeholder={'Fecha de nacimiento'}
                max={moment().format('YYYY-MM-DD')}
                design={'secondary'}
                onKeyDown={onKeyDown}
                error={inputError}
                onChange={onInputChange}
            />
        </SectionContainer>
    )
})
export default BirthStep;
export interface Props{
    onEnter?: (result: undefined | {
        birthday: Date
    }) => void
}
export interface BirthRef{
    getData: () => undefined | {
        birthday: Date
    }
}