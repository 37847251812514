import { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import media from "styled-media-query";
import { ChallengeScheme } from "client-v2";

import {
	Text,
	Color,
	ProgressBar,
	ColorV2,
} from "@adoptaunabuelo/react-components";
import { Calendar, Check, Users2 } from "lucide-react";
import { useNavigate } from "react-router-dom";

const screenSize2: any = "1024px";

const CellContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 388px;
	height: 295px;
	min-width: 388px;
	border-radius: 12px;
	overflow: hidden;
	background-color: white;
	cursor: pointer;
	${media.lessThan("medium")`
        width: unset;
        min-width: unset;
    `}
`;
const Section = styled.div`
	padding: 24px;
	${media.lessThan(screenSize2)`
        padding: 20px;
    `}
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const Col = styled.div`
	display: flex;
	flex-direction: column;
`;
const IconCheckView = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 60px;
	background-color: white;
	padding: 2px 0px;
	width: fit-content;
	height: fit-content;
	margin-bottom: 16px;
`;
const IconCheck = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 20px;
	width: 20px;
	border-radius: 20px;
	background-color: ${ColorV2.surface.green};
	margin-right: 6px;
`;
const Image = styled.img`
	height: 109px;
	width: 109px;
	border-radius: 12px;
	object-fit: cover;
`;

const ChallengeCell = (props: Props) => {
	const navigate = useNavigate();
	const [pastDate, setPastDate] = useState(false);
	const [completed, setCompleted] = useState(false);

	useEffect(() => {
		//Check if past date
		const diff = moment(props.challenge.endDate.iso).diff(moment(), "days");
		if (diff <= 0) setPastDate(true);

		//Check if goal completed
		const restAmount = props.challenge.goal - props.challenge.amount;
		if (restAmount <= 0) setCompleted(true);
	}, [props.challenge]);

	const onCellClick = () => {
		if (props.challenge.type === "birthday") {
			window.open(
				`${window.location.origin}/cumpleanios?id=${props.challenge.objectId}`,
				"_blank"
			);
		} else if (props.challenge.type === "dream") {
			window.open(
				`${window.location.origin}/reto?id=${props.challenge.objectId}`
			);
		} else if (props.challenge.type === "signature") {
			window.open(
				`${window.location.origin}/firma?id=${props.challenge.objectId}`
			);
		}
	};

	return (
		<CellContainer onClick={onCellClick}>
			<Row style={{ padding: "24px 24px 0px" }}>
				<Text
					type="h5"
					weight="medium"
					style={{
						display: "flex",
						flex: 1,
						height: 109,
						textOverflow: "ellipsis",
						wordWrap: "break-word",
						overflow: "hidden",
					}}
				>
					{props.challenge.title}
				</Text>
				<Image src={props.challenge.image?.url} />
			</Row>
			<Section>
				{completed ? (
					<IconCheckView>
						<IconCheck>
							<Check
								height={18}
								width={18}
								strokeWidth={2}
								color={"white"}
							/>
						</IconCheck>
						<Text type="p2" style={{ color: ColorV2.text.green }}>
							¡
							{props.challenge.type === "signature"
								? "Objetivo"
								: "Sueño"}{" "}
							cumplido!
						</Text>
					</IconCheckView>
				) : null}
				<ProgressBar
					style={{ height: 8, marginBottom: 8 }}
					progress={[
						{
							value:
								(props.challenge.amount /
									props.challenge.goal) *
								100,
							color: completed
								? Color.status.color.success
								: props.challenge.type === "rose"
								? ColorV2.surface.redMedium
								: Color.text.primary,
						},
						{
							value: completed
								? 0
								: props.amount
								? (props.amount / props.challenge.goal) * 100
								: 0,
							color:
								props.challenge.type === "rose"
									? ColorV2.surface.redMedium
									: Color.text.primary,
						},
					]}
					animationTime={0.3}
					animationDelay={0.5}
				/>
				<Row style={{ alignItems: "flex-end", marginBottom: 24 }}>
					<Text
						type="h5"
						weight="medium"
						style={{
							color: completed
								? Color.status.color.success
								: props.challenge.type === "rose"
								? ColorV2.surface.redMedium
								: Color.text.primary,
						}}
					>
						{props.challenge.amount}
						{props.challenge.type === "signature" ? "" : "€"}
					</Text>
					<Text
						type="p2"
						style={{
							marginBottom: 1,
							marginLeft: 6,
							color: Color.text.high,
						}}
					>
						de {props.challenge.goal}
						{props.challenge.type === "signature" ? " firmas" : "€"}
					</Text>
				</Row>
				<Col>
					{props.challenge.type !== "signature" && (
						<Row>
							<Users2
								style={{
									marginRight: 12,
									minHeight: 24,
									minWidth: 24,
								}}
								height={24}
								width={24}
								color={ColorV2.text.neutralHard}
							/>
							<Text
								type="p"
								style={{
									marginTop: 1,
								}}
							>
								{props.challenge.supporters} personas han
								colaborado
							</Text>
						</Row>
					)}
					{!completed && !pastDate && (
						<Row style={{ marginTop: 8 }}>
							<Calendar
								style={{
									marginRight: 12,
									minHeight: 24,
									minWidth: 24,
								}}
								height={24}
								width={24}
								color={ColorV2.text.neutralHard}
							/>
							<Text
								type="p"
								style={{
									marginTop: 1,
								}}
							>
								{moment(props.challenge.endDate.iso).diff(
									moment(),
									"days"
								) + "  días restantes "}
							</Text>
						</Row>
					)}
				</Col>
			</Section>
		</CellContainer>
	);
};
export default ChallengeCell;
export interface Props {
	challenge: ChallengeScheme;
	amount?: number;
	onClick?: () => void;
}
