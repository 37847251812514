import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	PaymentMethod,
	Track,
	Letter,
	ChallengeScheme,
	UserScheme,
	PaymentMethodScheme,
	ErrorScheme,
} from "client-v2";
import { PaymentRequestPaymentMethodEvent } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";

import Letters, { LetterRef } from "../../components/section/Letter";
import Paycards, { PaycardsRef } from "../../components/section/Paycards";
import Presents, { PresentsRef } from "../../components/section/Presents";
import ResumeCheckout from "./section/ResumeCheckout";
import Success from "./section/Success";
import SkipModal from "../../components/modal/SkipPaymentModal";
import Donation from "../../components/section/Donation";

import Wizard from "../../components/navigation/Wizard";

const Checkout = (props: Props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { setShowError, setShowLoading } = bindActionCreators(
		actionsCreators,
		dispatch
	);
	const isMobile = useSelector((state: State) => state.screen.isMobile);
	const currentUser = useSelector((state: State) => state.user.currentUser);
	const appLocation = useSelector((state: State) => state.location.location);

	const letterStep = useRef<LetterRef>(null);
	const presentsStep = useRef<PresentsRef>(null);
	const paycardsStep = useRef<PaycardsRef>(null);

	const [step, setStep] = useState(0);
	const [challenge, setChallenge] = useState<ChallengeScheme | undefined>(
		undefined
	);
	const [paymentMethods, setPaymentsMethods] = useState<
		Array<PaymentMethodScheme>
	>([]);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [showSkipModal, setShowSkipModal] = useState(false);
	const [data, setData] = useState<{
		name?: string;
		surname?: string;
		email?: string;
		phone?: string;
		code?: string;
		letter?: string;
		paymentMethod?: any;
		price: number;
	}>({
		price: 15,
	});

	useEffect(() => {
		const step = location.state.step;
		if (location.state && location.state.challenge) {
			setShowLoading(true);
			setChallenge(location.state.challenge);
			setStep(step === "presents" ? 1 : 0);
			setShowLoading(false);
		} else {
			navigate("/");
		}
	}, []);

	const onNextClick = async (skip?: boolean) => {
		if (skip) {
			setStep(step + 1);
		} else {
			//Letter
			if (step === 0) {
				const newData = letterStep.current?.getData();
				if (newData && newData.letter) {
					setData({ ...data, ...newData });
					sendLetter(newData.letter);
				} else {
					setShowError({
						show: true,
						message: "No te olvides de escribir tu felicitación",
					});
				}
			}
			//Amount
			else if (step === 1) {
				const newData = presentsStep.current?.getData();
				if (newData && newData.price && currentUser) {
					setLoading(true);
					const result = await Donation.getProduct({
						user: currentUser,
						price: newData.price,
						charge: "one",
						view: "birthday",
					});
					setPaymentsMethods(result.paymentMethods);
					setData({
						...data,
						price: result.product.price,
						paymentMethod: result.defaultPaymentMethod,
					});
					setLoading(false);
					setStep(step + 1);
				}
			}
			//Checkout
			else if (step === 2) {
				setLoading(true);
				const newData = await paycardsStep.current?.getData();
				setLoading(false);
				//Paycard
				if (
					newData &&
					newData.paymentMethod &&
					newData.paymentMethod.id
				) {
					setData({ ...data, ...newData });
					if (currentUser && challenge) {
						registerToChallenge(
							currentUser,
							challenge,
							data.price,
							newData.paymentMethod.id
						);
					}
				}
				//Apple Pay
				else if (newData && newData.paymentRequest) {
					newData.paymentRequest.show();
					newData.paymentRequest.on("paymentmethod", async (ev) => {
						setData({
							...data,
							paymentMethod: {
								id: ev.paymentMethod.id,
								brand: "apple_pay",
							},
						});
						if (currentUser && challenge)
							registerToChallenge(
								currentUser,
								challenge,
								data.price,
								ev.paymentMethod.id,
								ev
							);
					});
				} else {
					setShowError({
						show: true,
						message:
							"Debes añadir un método de pago para completar la compra de entradas",
					});
				}
			}
		}
	};

	const sendLetter = (letter: string) => {
		if (currentUser && challenge) {
			setLoading(true);
			Letter.create({
				userId: currentUser.objectId,
				content: letter,
				country: appLocation?.country_code,
				language: appLocation?.language,
				challengeId: challenge.objectId,
			})
				.then((result: any) => {
					Track.track("letter sent", {
						view: "birthday",
					});
					setStep(step + 1);
					setLoading(false);
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
					setShowError({ show: true, message: error.message });
				});
		}
	};

	const onPaymentMethodClick = (paymentMethod: any) => {
		if (currentUser) {
			setData({
				...data,
				paymentMethod: paymentMethod,
			});
			if (
				paymentMethod.id !== "apple_pay" &&
				paymentMethod.id !== "google_pay"
			) {
				setLoading(true);
				PaymentMethod.setDefault(paymentMethod.objectId, {
					userId: currentUser.objectId,
				})
					.then(async () => {
						if (currentUser) {
							const result = await Donation.getPaycards(
								currentUser.objectId,
								"one"
							);
							setPaymentsMethods(result.paymentMethods);
							setData({
								...data,
								paymentMethod: result.defaultPaymentMethod,
							});
							setLoading(false);
						}
					})
					.catch((error: ErrorScheme) => {
						setShowError({ show: true, message: error.message });
						setLoading(false);
					});
			}
		}
	};

	const registerToChallenge = async (
		user: UserScheme,
		challenge: ChallengeScheme,
		amount: number,
		paymentMethod: string,
		paymentRequestEvent?: PaymentRequestPaymentMethodEvent
	) => {
		setLoading(true);
		const result = await Donation.registerToChallenge({
			user: user,
			challenge: challenge,
			amount: amount,
			paymentMethod: paymentMethod,
			paymentRequestEvent: paymentRequestEvent,
			view: "birthday",
		});
		//Error
		if (result.error) {
			setShowError({ show: true, message: result.error });
			setLoading(false);
		}
		//Need 3d secure confirmation
		else if (result.result) {
			paycardsStep.current?.confirmPaymentIntent(result.result);
		}
		//Finish confirmation
		else {
			setSuccess(true);
			setLoading(false);
		}
	};

	return (
		<>
			<SkipModal
				isVisible={showSkipModal}
				video={challenge?.video}
				type={isMobile ? "full-screen" : "default"}
				onClick={() => {
					Track.track("skip donation modal click", {
						view: "birthday",
					});
					setShowSkipModal(false);
				}}
				onClose={() => {
					Track.track("skip donation modal close", {
						view: "birthday",
					});
					setShowSkipModal(false);
					if (data.letter) setStep(step + 2);
					else navigate(-1);
				}}
			/>
			<Wizard
				tag={"register-birthday"}
				location={appLocation}
				loginIndex={0}
				step={step}
				titles={[
					"Escribe tu felicitación",
					"Colabora con el bote",
					"Confirmación",
				]}
				subtitles={[
					"Incluye en ella tus mejores deseos, palabras bonitas y todos los piropos que se merece.",
					"Todo el dinero irá destinado a la compra de los regalos.",
					"",
				]}
				onError={(error: string) =>
					setShowError({ show: true, message: error })
				}
				onStepChange={(step: number) => {
					setStep(step);
				}}
				buttonProps={{
					loading: loading,
					success: success,
					onClick: onNextClick,
					onSuccess: () => setStep(step + 1),
				}}
				skipButtonProps={
					step === 1
						? {
								children: "No quiero colaborar",
								onClick: () => {
									Track.track("skip donation modal show", {
										view: "birthday",
									});
									setShowSkipModal(true);
								},
						  }
						: undefined
				}
				RightViews={
					challenge
						? [
								<ResumeCheckout
									amount={
										location.state &&
										location.state.supporters
											? location.state.supporters
											: 0
									}
									challenge={challenge}
								/>,
								undefined,
						  ]
						: []
				}
				LeftViews={[
					<Letters ref={letterStep} />,
					<Presents ref={presentsStep} challenge={challenge} />,
					<Paycards
						ref={paycardsStep}
						paymentMethods={paymentMethods}
						buttonProps={{
							children:
								data.paymentMethod?.brand === "apple_pay" ||
								data.paymentMethod?.brand === "google_pay"
									? "Colaborar con"
									: "Colaborar con " + data.price + " €",
							width:
								data.paymentMethod?.brand === "apple_pay" ||
								data.paymentMethod?.brand === "google_pay"
									? 250
									: 220,
							request: data.paymentMethod?.brand,
						}}
						amount={data.price}
						charge={"one"}
						currency={"eur"}
						countryCode={"ES"}
						onPaymentMethodClick={onPaymentMethodClick}
						onSetupConfirmed={(
							paymentMethodId?: string,
							error?: string
						) => {
							if (error) {
								setShowError({ show: true, message: error });
								setLoading(false);
							} else {
								setSuccess(true);
								setLoading(false);
							}
						}}
					/>,
					<Success challenge={challenge} user={currentUser} />,
				]}
			/>
		</>
	);
};
export default Checkout;
export interface Props {}
