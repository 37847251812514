import { ChallengeScheme } from "client-v2";

import Cell from "./Cell";
import { Text, ColorV2, Button } from "@adoptaunabuelo/react-components";
import styled from "styled-components";

const Stamp = styled.img`
	width: 128px;
	height: 96px;
    margin-bottom: 24px;
`;

const LetterCell = (props: Props) => {
	return (
		<Cell challenge={props.challenge}>
			<Stamp src={"./assets/letter/stamp_retro.png"} />
			<Text type="h3" weight="semibold" style={{ color: "white" }}>
				{props.challenge.title}
			</Text>
			<Text
				type="h6"
				style={{ marginTop: 16, marginBottom: 40, color: "white" }}
			>
				Envía una carta a los abuelos que viven solos en residencias.
			</Text>
			<Button
				style={{
					width: "fit-content",
					backgroundColor: "white",
					color: ColorV2.text.neutralHard,
				}}
				onClick={() =>
					window.open(
						"https://adoptaunabuelo.org/una-carta-para-un-abuelo",
						"_blank"
					)
				}
			>
				Enviar mi carta
			</Button>
		</Cell>
	);
};
export default LetterCell;
export interface Props {
	challenge: ChallengeScheme;
}
