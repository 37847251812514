const initialState={
	error: {
		show: false
	},
	loading: false,
	success: {
		show: false
	},
}

export default function(state=initialState, action: any) {
  	switch (action.type) {
		case 'SHOW_ERROR': {
			return {
				...state,
				error: action.data
			};
		}
		case 'SHOW_LOADING':{
			return{
				...state,
				loading: action.data
			}
		}
		case 'SHOW_SUCCESS':{
			return{
				...state,
				success: action.data,
			}
		}
		default:
			return state;
  	}
}
