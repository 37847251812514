import { ComponentPropsWithRef } from "react";
import styled from "styled-components";
import { ChallengeScheme } from "client-v2";
import media from "styled-media-query";
import { useSelector } from "react-redux";
import { State } from "../../redux";
import { Text, ColorV2 } from "@adoptaunabuelo/react-components";

const Container = styled.div`
	display: flex;
	flex-direction: row;
	overflow: hidden;
	border-radius: 12px;
	height: 442px;
	width: 100%;
	background-color: ${ColorV2.surface.primaryHard};
	${media.lessThan("small")`
        flex-direction: column;
        height: unset;
    `}
`;
const ImageView = styled.div`
	display: flex;
	flex: 1;
	position: relative;
	min-height: 442px;
	${media.lessThan("small")`
        min-height: unset;
        max-height: 300px;
        height: 300px;
    `}
`;
const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;
const DataContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 48px;
	justify-content: center;
	${media.lessThan("small")`
        padding: 24px 16px;
    `}
`;

const Cell = (props: Props) => {
	const { challenge, backgroundColor, titleColor, ...restProps } = props;

	return (
		<Container
			{...restProps}
			style={{ backgroundColor: backgroundColor, ...restProps.style }}
		>
			<ImageView>
				<Image src={props.challenge.image.url} />
			</ImageView>
			<DataContainer>
				{props.children}
			</DataContainer>
		</Container>
	);
};
export default Cell;
export interface Props extends ComponentPropsWithRef<"div"> {
	challenge: ChallengeScheme;
	backgroundColor?: string;
	titleColor?: string;
}
