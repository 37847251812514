import { useState, useEffect } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { CoorporateScheme } from "client-v2";
import { useSelector } from "react-redux";
import { State } from "../../../redux";

import { Button, Text, Counter } from "@adoptaunabuelo/react-components";
import { Pencil } from "lucide-react";
import VideoModal from "../../../components/modal/VideoModal";
import SectionContainer from "./SectionContainer";

const isScreen01: any = "1350px";
const isScreen02: any = "1050px";

const SectionContainerStyled = styled(SectionContainer)`
	width: 489px;
	margin-right: 8%;
	justify-content: center;
	${media.lessThan(isScreen01)`
        width: 425px;
        margin-right: 6%;
    `}
	${media.lessThan(isScreen02)`
        width: 395px;
        margin-right: 4%;
    `}
	${media.lessThan("medium")`
		width: unset;
		margin-right: 0px;
	`}
`;
const ButtonStyled = styled(Button)`
	${media.lessThan("medium")`
        width: 100%;
    `}
`;
const StampsContainer = styled.div`
	position: relative;
	margin-bottom: 38px;
	${media.lessThan("medium")`
		display: flex;
		justify-content: flex-end;
    `}
`;
const StampView = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 132px;
	height: 110px;
	background-size: cover;
`;
const StampLogo = styled.img`
	width: 90px;
	height: 54px;
	object-fit: contain;
`;
const AUAStampLogo = styled.img`
	position: absolute;
	top: 58px;
	left: -48px;
	width: 98px;
	transform: rotate(10deg);
	${media.lessThan("medium")`
        left: unset;
		right: 86px;
		top: 52px;
    `}
`;
const DataContainer = styled.div`
	display: flex;
	flex-direction: column;
	${media.lessThan("medium")`
        flex: 1;
		justify-content: center;
    `}
`;
const Title = styled(Text)`
	margin-bottom: 32px !important;
	${media.lessThan(isScreen02)`
        font-size: 32px !important;
		line-height: 42px !important;
		margin-bottom: 32px !important;
    `}
`;

const Home = (props: Props) => {
	const translation = useSelector(
		(state: State) => state.location.translation
	);
	const [showVideo, setShowVideo] = useState(false);

	return (
		<>
			<VideoModal
				isVisible={showVideo}
				url={"https://www.youtube.com/watch?v=nalM0N80OYA"}
				onFinish={() => setShowVideo(false)}
				onClose={() => setShowVideo(false)}
			/>
			<SectionContainerStyled animated={props.animated}>
				<DataContainer>
					{props.corporate.logo && (
						<StampsContainer>
							<StampView
								style={{
									backgroundImage: `url("./assets/letter/stamp_empty_white.png")`,
								}}
							>
								<StampLogo src={props.corporate.logo.url} />
							</StampView>
							<AUAStampLogo
								src={"./assets/letter/stamp_aua.png"}
							/>
						</StampsContainer>
					)}
					<Title type="h2" weight="semibold">
						{translation.letter_home_title}
						{props.corporate.name}!
					</Title>
					<Counter
						amount={props.amount}
						color={props.corporate.color?.main}
						height={53}
						width={36}
						fontSize={40}
					/>
					<Text
						type="p"
						style={{ marginTop: 8, marginBottom: "10%" }}
					>
						{translation.letter_home_counter}
					</Text>
				</DataContainer>
				<ButtonStyled
					style={{
						backgroundColor: props.corporate.color?.main,
						color: props.corporate.color?.third,
					}}
					icon={
						<Pencil
							color={props.corporate.color?.third}
							height={20}
							width={20}
						/>
					}
					onClick={props.onFinish}
				>
					{translation.letter_button_go}
				</ButtonStyled>
			</SectionContainerStyled>
		</>
	);
};
export default Home;
export interface Props {
	corporate: CoorporateScheme;
	amount: number;
	animated?: boolean;
	onFinish: () => void;
}
