import styled from 'styled-components';
import media from 'styled-media-query';

import { Text, Color, ColorV2 } from '@adoptaunabuelo/react-components';

const screenSize1: any = "1300px";
const screenSize2: any = "1024px";

const Container = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    height: 380px;
    min-width: fit-content;
    border-radius: 12px;
    overflow: hidden;
    ${media.lessThan("medium")`
        flex-direction: column;
        height: unset;
    `}
`
const Image = styled.img`
    width: 50%;
    height: inherit;
    border-radius: 12px;
    object-fit: cover;
    ${media.lessThan(screenSize2)`
        width: 350px;
        height: 350px;
    `}
    ${media.lessThan("medium")`
        width: 100%;
        height: 348px;
	`}
`
const DataContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0px 0px 0px 48px;
    height: -webkit-fill-available;
    height: -moz-available;
    ${media.lessThan(screenSize2)`
        padding: 0px 0px 0px 32px;
	`}
    ${media.lessThan("medium")`
        padding: 32px 0px 0px;
	`}
`
const DataView = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 24px;
    justify-content: space-between;
`
const TopView = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: -webkit-fill-available;
    width: -moz-available;
`
const TestimonyView = styled.div`
    position: relative;
    padding: 24px;
    border-radius: 12px;
    background-color: ${ColorV2.surface.background};
    margin-top: 24px;
    ${media.lessThan("medium")`
        margin-top: 32px;
	`}
`
const ArrowUp = styled.div`
    display: none;
    position: absolute;
    left: calc(50% - 24px);
    top: -23px;
    width: 0; 
    height: 0; 
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 24px solid ${ColorV2.surface.background};
    ${media.lessThan("medium")`
        display: block;
	`}
`
const ArrowLeft = styled.div`
    position: absolute;
    top: calc(50% - 24px);
    left: -24px;
    width: 0; 
    height: 0; 
    border-top: 24px solid transparent;
    border-bottom: 24px solid transparent; 
    border-right:24px solid ${ColorV2.surface.background}; 
    ${media.lessThan("medium")`
        display: none;
	`}
`

const TestimonyCell = (props: Props) =>{

    return(
        <Container>
            <Image
                src={props.img}
            />
            <DataContainer>
                <TopView>
                    {props.logo}
                    {props.children}
                </TopView>
                <DataView>
                    <Text type='p'>
                        {props.info}
                    </Text>
                    <TestimonyView>
                        <ArrowLeft/>
                        <ArrowUp/>
                        <Text type='h6'>
                            "{props.testimony}"
                        </Text>
                        <Text type="p" style={{fontFamily: "Chantal", fontSize: 20, lineHeight: '28px', marginTop: 16, color: Color.text.high}}>
                            {props.name}
                        </Text>
                    </TestimonyView>
                </DataView>
            </DataContainer>
        </Container>
    )
}
export default TestimonyCell;
export interface Props{
    img: string,
    logo: any,
    testimony: string,
    info: string
    name: string,
    children?: any
}