import { useNavigate } from "react-router-dom";
import { ChallengeScheme, UserScheme } from "client-v2";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";

import Header from "../../components/section/Header";
import Info from "../challenge/section/Info";
import Resume from "../../components/section/ResumeDetail";
import Container from "../../components/container/Container";

const Detail = (props: Props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { setShowSuccess } = bindActionCreators(actionsCreators, dispatch);
	const currentUser = useSelector((state: State) => state.user.currentUser);
	const loading = useSelector((state: State) => state.modals.loading);

	const onNextClick = () => {
		navigate("/firma/registro/?id=" + props.challenge.objectId, {
			state: {
				challenge: props.challenge,
			},
		});
	};

	const onShareClick = (op: any) => {
		const url =
			"https://adoptaunabuelo.org/firma/?id=" + props.challenge.objectId;
		if (op.id === "twitter") {
			window.open("http://www.twitter.com/share?url=" + url, "_blank");
		} else if (op.id === "facebook") {
			window.open("http://www.facebook.com/sharer.php?u=" + url);
		} else if (op.id === "whatsapp") {
			window.open("whatsapp://send?text=" + url);
		} else if (op.id === "url") {
			navigator.clipboard.writeText(url);
			setShowSuccess({
				show: true,
				message: "Se ha copiado el enlace en tu portapapeles",
			});
		}
	};

	return (
		<Container
			loading={loading}
			headerProps={{
				user: currentUser,
			}}
			footerProps={{
				style: { backgroundColor: "white" },
				buttonProps: {
					style: { width: "100%" },
					children: "Quiero firmar",
					onClick: onNextClick,
				},
			}}
			RightView={
				<Resume
					challenge={props.challenge}
					supporters={props.supporters}
					onClick={onNextClick}
					onShareClick={onShareClick}
				/>
			}
		>
			<Header
				title={props.challenge.title}
				image={props.challenge.image.url}
				video={props.challenge.video}
			/>
			<Info challenge={props.challenge} supporters={props.supporters} />
		</Container>
	);
};
export default Detail;
export interface Props {
	challenge: ChallengeScheme;
	supporters: Array<any>;
}
