import { useState } from "react";
import styled from "styled-components";
import media from "styled-media-query";

import { Text } from "@adoptaunabuelo/react-components";
import { Play } from "lucide-react";
import VideoModal from "../../components/modal/VideoModal";
import LogoActivities from "../../assets/logo/Activities";

const Container = styled.div``;
const DesktopSection = styled.div`
	${media.lessThan("medium")`
        display: none;
    `}
`;
const MobileSection = styled.div`
	display: none;
	flex: 1;
	flex-direction: column;
	${media.lessThan("medium")`
        display: flex;
    `}
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const EventImage = styled.img`
	position: absolute;
	height: 100%;
	width: 100%;
	object-fit: cover;
`;
const EventImageView = styled.div`
	position: relative;
	display: flex;
	aspect-ratio: 16/9;
	border-radius: 12px;
	width: -webkit-fill-available;
	width: -moz-available;
	margin-top: 32px;
	overflow: hidden;
	${media.lessThan("medium")`
        margin-top: 0px;
        border-radius: 0px;
        aspect-ratio: 4/3;
    `}
`;
const PlayButton = styled.div`
	position: absolute;
	bottom: 24px;
	right: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50px;
	padding: 6px 12px;
	gap: 6px;
	background-color: #0000003d;
	${media.lessThan("medium")`
        bottom: 16px;
        right: 16px;
    `}
`;
const ActivityLogoView = styled.div`
	display: flex;
	justify-content: center;
	align-items: flex-end;
	position: absolute;
	bottom: 24px;
	left: 24px;
	height: 96px;
	width: 128px;
	${media.lessThan("medium")`
        height: 72px;
        width: 96px;
        bottom: 16px;
        left: 16px;
    `}
`;

const Header = (props: Props) => {
	const [showVideo, setShowVideo] = useState(false);

	return (
		<Container>
			{props.video && (
				<VideoModal
					isVisible={showVideo}
					url={props.video}
					onFinish={() => setShowVideo(false)}
					onClose={() => setShowVideo(false)}
				/>
			)}
			<MobileSection style={{ margin: "-24px -24px 32px" }}>
				<EventImageView
					onClick={() => props.video && setShowVideo(true)}
				>
					<EventImage src={props.image} />
					{props.video && (
						<PlayButton>
							<Play
								height={24}
								width={24}
								fill={"white"}
								strokeWidth={0}
							/>
							<Text type="b2" style={{ color: "white" }}>
								Ver video
							</Text>
						</PlayButton>
					)}
					{props.type && (
						<ActivityLogoView>
							<LogoActivities
								style={{ width: "100%", color: "white" }}
								type={props.type}
							/>
						</ActivityLogoView>
					)}
				</EventImageView>
			</MobileSection>
			<Text type="h3" weight="semibold">
				{props.title}
			</Text>
			<DesktopSection
				style={{ cursor: props.video ? "pointer" : "default" }}
				onClick={() => props.video && setShowVideo(true)}
			>
				<EventImageView>
					<EventImage src={props.image} />
					{props.video && (
						<PlayButton>
							<Play
								height={24}
								width={24}
								fill={"white"}
								strokeWidth={0}
							/>
							<Text type="b2" style={{ color: "white" }}>
								Ver video
							</Text>
						</PlayButton>
					)}
					{props.type && (
						<ActivityLogoView>
							<LogoActivities
								style={{ width: "100%", color: "white" }}
								type={props.type}
							/>
						</ActivityLogoView>
					)}
				</EventImageView>
			</DesktopSection>
		</Container>
	);
};
export default Header;
export interface Props {
	title: string;
	video?: string;
	image?: string;
	type?: "cumpleaños" | "sueños";
}
