import styled from "styled-components";
import media from "styled-media-query";

const Container = styled.div`
	display: flex;
	flex: 1;
	height: var(--doc-height);
	padding-right: 48px;
	justify-content: flex-end;
	align-items: center;
	${media.lessThan("medium")`
        display: none;
    `}
`;
const MockupImage = styled.img`
	width: 400px;
`;

const Success = (props: Props) => {
	return (
		<Container>
			<MockupImage
				src={require("../../../assets/images/mockup/register.webp")}
			/>
		</Container>
	);
};
export default Success;
export interface Props {}
