import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import media from "styled-media-query";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../redux";
import { bindActionCreators } from "redux";

import Detail from "../views/donation/Detail";
import Checkout from "../views/donation/Checkout";

import Loading from "../components/modal/Loading";
import { Feedback } from "@adoptaunabuelo/react-components";

const FeedbackStyled = styled(Feedback)`
	right: 24px !important;
	left: unset !important;
	${media.lessThan("small")`
        top: 64px;
        left: 24px !important;
        bottom: unset !important;
	`}
`;

const PaycardLayout = (props: PayoutLayoutProps) => {
	const dispatch = useDispatch();
	const { setShowError } = bindActionCreators(actionsCreators, dispatch);
	const error = useSelector((state: State) => state.modals.error);
	const loading = useSelector((state: State) => state.modals.loading);

	return (
		<>
			<Loading isVisible={loading ? true : false} />
			<FeedbackStyled
				isVisible={error.show}
				type="error"
				text={error.message}
				onClose={() => setShowError({ show: false })}
			/>
			<Routes>
				<Route path="/" element={<Detail />} />
				<Route path="/registro" element={<Checkout />} />
			</Routes>
		</>
	);
};

export default PaycardLayout;
export interface PayoutLayoutProps {}
