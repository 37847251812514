import { useState, forwardRef, useImperativeHandle, Ref } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { TextArea, Text, Color } from "@adoptaunabuelo/react-components";
import SectionContainer from "../container/SectionContainer";
import { useSelector } from 'react-redux';
import { State } from '../../redux';

const Container = styled(SectionContainer)`
	${media.lessThan("medium")`
        height: 100%;
    `}
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-top: 12px;
`;

const LetterStep = forwardRef((props: LetterStepProps, ref: Ref<LetterRef>) => {
	
	const isMobile: boolean = useSelector((state:State)=>state.screen.isMobile);
	const [letter, setLetter] = useState<string | undefined>(undefined);
	const [length, setLength] = useState(0);

	useImperativeHandle(ref, () => ({
		getData() {
			if (letter && length <= 3072) {
				return { letter };
			} else {
				return undefined;
			}
		},
	}));

	return (
		<Container>
			<TextArea
				type="edit"
				design="primary"
				style={{ height: isMobile ? "100%" : "350px" }}
				maxLength={3072}
				onChange={(e: any) => {
					setLetter(e);
					var strippedHtml = e.replace(/<[^>]+>/g, "");
					setLength(strippedHtml.length);
				}}
			/>
			<Row>
				<Text
					type="p2"
					style={{
						color:
							length > 3072
								? Color.status.color.error
								: Color.text.high,
					}}
				>
					{length}/3072 caracteres
				</Text>
			</Row>
		</Container>
	);
});
export default LetterStep;
export interface LetterStepProps {
	
}
export interface LetterRef {
	getData: () =>
		| undefined
		| {
				letter: string;
		  };
}
