import styled from 'styled-components';
import { ChallengeScheme } from 'client-v2';

import { Modal } from '@adoptaunabuelo/react-components';
import PaymentBreakdownCell from '../cell/PriceBreakdownCell'

const Container = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 24px;
`

const PaymentBreakdownModal = (props: Props) =>{

    return(
        <Modal
            isVisible={props.isVisible}
            title={'¿A qué se destina tu colaboración?'}
            subtitle={'Queremos que sea un cumpleaños muy especial, así que vamos a enviarle regalos de parte de todos. Tu aportación irá a un bote común para poder comprarlos.'}
            type={props.type}
            onClose={props.onClose}
        >
            <Container>
                {props.challenge &&
                    <PaymentBreakdownCell
                        style={{ width: '100%' }}
                        options={props.challenge.distribution}
                    />
                }
            </Container>
        </Modal>
    )
}
export default PaymentBreakdownModal;
export interface Props{
    isVisible: boolean,
    type?: 'full-screen' | 'default'
    challenge?: ChallengeScheme,
    onClose: () => void
}