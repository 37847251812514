import { useEffect, useRef, useState } from "react";
import {
	ErrorScheme,
	PaymentMethod,
	PaymentMethodScheme,
	UserScheme,
} from "client-v2";
import { PaymentMethod as PaymentMethodStripe } from "@stripe/stripe-js";
import Stripe from "stripe";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";

import Wizard from "../../components/navigation/Wizard";
import PaycardStep, { PaycardsRef } from "../../components/section/Paycards";
import Resume from "./Resume";
import Success from "./Success";

const PaycardWizard = (props: Props) => {
	const dispatch = useDispatch();
	const { setShowError } = bindActionCreators(actionsCreators, dispatch);
	const currentUser = useSelector((state: State) => state.user.currentUser);
	const appLocation = useSelector((state: State) => state.location.location);

	const paycardView = useRef<PaycardsRef>(null);

	const [step, setStep] = useState(0);
	const [loading, setLoading] = useState(false);
	const [paycardsArray, setPaycardsArray] = useState<
		Array<PaymentMethodScheme>
	>([]);

	useEffect(() => {
		if (currentUser) {
			getPaycards(currentUser);
		}
	}, []);

	const onNextClick = async () => {
		if (step === 0) {
			setLoading(true);
			const newData = await paycardView.current?.getData();
			if (
				newData?.paymentMethod &&
				newData.paymentMethod.id.slice(0, 2) === "pm"
			)
				addPaycard(newData.paymentMethod);
			else setStep(step + 1);
		}
	};

	const getPaycards = async (user: UserScheme) => {
		setLoading(true);
		PaymentMethod.get({
			userId: user.objectId,
			types: ["paycard"]
		})
			.then((result) => {
				setPaycardsArray(result.data);
				setLoading(false);
			})
			.catch((error: ErrorScheme) => {
				setLoading(false);
				setShowError({ show: true, message: error.message });
			});
	};

	const addPaycard = async (paycardToken: PaymentMethodStripe) => {
		if (currentUser) {
			setLoading(true);
			const paymentMethodId = paycardToken.id;
			PaymentMethod.initSetupIntent({
				userId: currentUser.objectId,
				cardId: paymentMethodId,
				redirectUrl: window.location.origin + "/card-success",
			})
				.then((result) => {
					const setupIntent = result.data as Stripe.SetupIntent;
					if (setupIntent.status === "requires_action")
						paycardView.current?.confirmSetupIntent(setupIntent);
					else {
						setStep(step + 1);
					}
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
					setShowError({ show: true, message: error.message });
				});
		}
	};

	const changeDefaultCard = async (paymentMethod: PaymentMethodScheme) => {
		if (currentUser) {
			setLoading(true);
			PaymentMethod.setDefault(paymentMethod.objectId, {
				userId: currentUser.objectId,
			})
				.then((result) => {
					if (currentUser) getPaycards(currentUser);
				})
				.catch((error: string) => {
					setLoading(false);
				});
		}
	};

	const onSetupConfirmed = (paymentMethodId?: string, error?: string) => {
		if (paymentMethodId) {
			setStep(step + 1);
		} else setShowError({ show: true, message: error ? error : "" });
	};

	const onLogin = (user: UserScheme) => {
		getPaycards(user);
	};

	const leftViews = [
		<PaycardStep
			ref={paycardView}
			paymentMethods={paycardsArray}
			canEdit={true}
			currency={appLocation ? appLocation.currency.name : "eur"}
			countryCode={appLocation ? appLocation.country_code : "ES"}
			charge={"one"}
			currentUser={currentUser}
			buttonProps={{
				children: "Guardar",
				width: 150,
			}}
			onPaymentMethodClick={changeDefaultCard}
			onSetupConfirmed={onSetupConfirmed}
			onRemoveClick={() =>
				currentUser ? getPaycards(currentUser) : undefined
			}
		/>,
		<Success />,
	];

	const rightView = [
		<Resume
			image={require("../../assets/images/background/register_background_01.png")}
		/>,
		undefined,
	];

	return (
		<Wizard
			tag={"register-volunteer"}
			location={appLocation}
			loginIndex={0}
			step={step}
			titles={["Añadir método de pago"]}
			onError={(error: string) =>
				setShowError({ show: true, message: error })
			}
			onLogin={onLogin}
			onStepChange={(step: number) => {
				setStep(step);
			}}
			LeftViews={leftViews}
			RightViews={rightView}
			buttonProps={{
				onClick: onNextClick,
				loading: loading,
			}}
		/>
	);
};
export default PaycardWizard;
export interface Props {}
