import { useEffect, useState, ComponentPropsWithoutRef } from "react";
import styled from "styled-components";
import media from "styled-media-query";

import { Player } from "@lottiefiles/react-lottie-player";
import { Color, ColorV2 } from "@adoptaunabuelo/react-components";
import animation from "../../assets/animations/confetti.json";
import HeaderNav, { HeaderProps } from "../../components/navigation/Header";
import Footer, { FooterProps } from "../../components/navigation/Footer";

const screenSize1: any = "1300px";
const screenSize2: any = "1024px";

const ContainerView = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
`;
const Content = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	max-width: 1280px;
	padding: 32px 10% 80px;
	height: 100%;
	${media.lessThan(screenSize1)`
        padding: 32px 5% 80px;
    `}
	${media.lessThan(screenSize2)`
        padding: 32px 48px 80px;
    `}
    ${media.lessThan("medium")`
        padding: 32px 32px 88px;
    `}
    ${media.lessThan("small")`
        padding: 24px 24px 88px;
    `}
`;
const LeftViewContainer = styled.div<{ hidden?: boolean }>`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-right: 10%;
	opacity: ${(props) => (props.hidden ? 0 : 1)};
	transition: opacity 0.6s ease-in, transform 0.6s ease-out;
	transform: ${(props) =>
		props.hidden ? "translateY(20px)" : "translateY(0px)"};
	${media.lessThan(screenSize1)`
        margin-right: 8%;
    `}
	${media.lessThan(screenSize2)`
        margin-right: 5%;
    `}
    ${media.lessThan("medium")`
        margin-right: 0px;
    `}
`;
const RightViewContainer = styled.div<{ hidden?: boolean }>`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 40%;
	opacity: ${(props) => (props.hidden ? 0 : 1)};
	transition: opacity 0.6s ease-in;
	${media.lessThan("medium")`
        display: none;
    `}
`;
const ConfettiView = styled.div<{ hidden?: boolean }>`
	display: flex;
	position: fixed;
	top: 0px;
	right: 0px;
	left: 0px;
	bottom: 0px;
	opacity: ${(props) => (props.hidden ? 0 : 1)};
	transition: opacity 0.6s ease-in;
`;

const Container = ({
	loading,
	headerProps,
	footerProps,
	RightView,
	...props
}: Props) => {
	const [activeHeader, setActiveHeader] = useState(false);
	const [show, setShow] = useState(false);
	const [showConfetti, setShowConfetti] = useState(false);
	const [hideConfetti, setHideConfetti] = useState(!props.showConfetti);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	useEffect(() => {
		setShow(false);
		setShowConfetti(false);
		delay(600).then(() => {
			setShow(true);
			setShowConfetti(true);
		});
	}, [loading]);

	const delay = (ms: number) =>
		new Promise((resolve) => setTimeout(resolve, ms));

	const handleScroll = (e: any) => {
		if (window.scrollY > 100) setActiveHeader(true);
		else setActiveHeader(false);
	};

	return (
		<ContainerView {...props}>
			<HeaderNav active={activeHeader} {...headerProps} />
			<Footer
				style={{ borderTop: "1px solid " + Color.line.soft }}
				{...footerProps}
			/>
			{!hideConfetti && (
				<ConfettiView hidden={!showConfetti}>
					<Player
						style={{ width: "auto", height: "auto" }}
						loop={true}
						autoplay={true}
						src={animation}
						onEvent={(event) => {
							if (event === "loop") {
								setShowConfetti(false);
								delay(600).then(() => {
									setHideConfetti(true);
								});
							}
						}}
					/>
				</ConfettiView>
			)}
			<Content>
				<LeftViewContainer hidden={!show}>
					{props.children}
				</LeftViewContainer>
				<RightViewContainer hidden={!show}>
					{RightView}
				</RightViewContainer>
			</Content>
		</ContainerView>
	);
};
export default Container;
export interface Props extends ComponentPropsWithoutRef<"div"> {
	loading?: boolean;
	RightView: any;
	headerProps?: HeaderProps;
	showConfetti?: boolean;
	footerProps: FooterProps;
}
