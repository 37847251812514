import styled from 'styled-components';
import { Player } from '@lottiefiles/react-lottie-player';

import animation from '../../assets/animations/success.json';
import { Modal, Text } from '@adoptaunabuelo/react-components';

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 24px 0px;
`
const Animation = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    margin-top: -80px;
`
const TextView = styled.div`
    margin-top: -50px;
`

const SuccessModal = (props: Props) =>{


    return(
        <Modal
            isVisible={props.isVisible}
            hideClose
            buttonProps={props.buttonProps}
            onClose={props.onClose}
        >
            <Container>
                <Animation>
                    <Player
                        style={{height: 200, width: 200}}
                        loop={false}
                        autoplay={true}
                        src={animation}
                    />
                </Animation>
                <TextView>
                    <Text
                        type='p'
                        style={{fontSize: 28, fontWeight: 700}}
                    >
                        {props.title}
                    </Text>
                    <Text
                        type='p'
                        style={{marginTop: 8}}
                    >
                        {props.message}
                    </Text>
                </TextView>
            </Container>
        </Modal>
    )
}
export default SuccessModal;
export interface Props{
    isVisible: boolean,
    title: string,
    message: string
    buttonProps: {
        children: string,
        onClick: () => void
    }
    onClose: () => void
}