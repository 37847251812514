import styled from "styled-components";
import moment from "moment";
import { PaymentMethodScheme } from "client-v2";

import { Text, Menu, ColorV2 } from "@adoptaunabuelo/react-components";
import PaycardIcon from "../../assets/logo/Paycard";
import { Check, Bookmark, Pencil, Trash } from "lucide-react";

const Cell = styled.div<{ $canEdit?: boolean }>`
	display: flex;
	align-items: center;
	background-color: white;
	cursor: ${(props) => (props.$canEdit ? "default" : "pointer")};
	border-radius: 12px;
	height: 64px;
	&:hover {
		background-color: ${(props) =>
			props.$canEdit ? "white" : ColorV2.surface.neutralSoft};
	}
`;
const Container = styled.div`
	position: relative;
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
    padding: 0px 12px;
	height: 100%;
`;
const BorderView = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: row;
	box-shadow: 0px 1px 0px 0px ${ColorV2.border.neutralSoft};
	height: 100%;
`
const DataView = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
const Icon = styled.div`
	height: 36px;
	width: 36px;
	margin-right: 16px;
`;
const Selector = styled.div<{ $selected?: boolean; color?: string }>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 22px;
	width: 22px;
	border-radius: 100px;
	background-color: ${(props) =>
		props.$selected
			? props.color
				? props.color
				: ColorV2.surface.primary
			: "transparent"};
	border: ${(props) =>
		props.$selected
			? "2px solid " +
			  (props.color ? props.color : ColorV2.surface.primary)
			: "2px solid " +
			  (props.color ? props.color + "1A" : ColorV2.border.neutralSoft)};
`;

const PaycardCell = (props: Props) => {
	const onCellClick = () => {
		if (!props.canEdit) props.onClick && props.onClick();
	};

	const onOptionClick = (op: any) => {
		if (op.id === "default") {
			props.onClick && props.onClick();
		} else if (op.id === "remove") {
			props.onRemoveClick && props.onRemoveClick();
		}
	};

	return (
		<Cell
			className={"cell"}
			onClick={onCellClick}
			style={props.style}
			$canEdit={props.canEdit}
			color={props.color}
		>
			<Container>
				<Icon>
					<PaycardIcon
						brand={props.brand}
						style={{
							height: 36,
							width: 36,
							borderRadius: 6,
						}}
					/>
				</Icon>
				<BorderView>
					<DataView>
						{props.brand === "apple_pay" ||
						props.brand === "google_pay" ? (
							<Text type="p" weight="medium">
								{props.brand === "apple_pay"
									? "Apple Pay"
									: "Google Pay"}
							</Text>
						) : (
							<Text type="p" weight="medium">
								**** **** **** {props.last4}
							</Text>
						)}
						<Text
							type="c1"
							style={{
								marginTop: -4,
								color: ColorV2.border.neutralHigh,
							}}
						>
							{props.expDate
								? "Caduda " +
								  moment(props.expDate).format("MM/YY")
								: props.name}
						</Text>
					</DataView>
					{props.canEdit && !props.default ? (
						<Menu
							id={"card-menu-list"}
							position={"bottom-left"}
							icon={
								<Pencil
									height={18}
									width={18}
									color={ColorV2.border.neutralHigh}
								/>
							}
							options={[
								{
									id: "default",
									label: "Marcar como predeterminada",
									icon: <Bookmark height={20} width={20} />,
								},
								{
									id: "remove",
									label: "Eliminar",
									icon: <Trash height={20} width={20} />,
								},
							]}
							onClick={onOptionClick}
						/>
					) : props.canEdit ? undefined : (
						<Selector $selected={props.default} color={props.color}>
							{props.default && (
								<Check
									strokeWidth={3}
									height={18}
									width={18}
									color={"white"}
								/>
							)}
						</Selector>
					)}
				</BorderView>
			</Container>
		</Cell>
	);
};
export default PaycardCell;
export interface Props {
	style?: any;
	brand: PaymentMethodScheme["brand"];
	last4: string;
	name?: string;
	expDate?: string;
	default?: boolean;
	canEdit?: boolean;
	color?: string;
	onClick: () => void;
	onRemoveClick?: () => void;
}
