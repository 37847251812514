import { useEffect, useState, useRef } from "react";
import {
	PaymentMethod,
	Track,
	UserScheme,
	EventScheme,
	ProductScheme,
	PaymentMethodScheme,
	Product,
	ErrorScheme,
} from "client-v2";
import { useLocation, useNavigate } from "react-router-dom";
import { PaymentRequestPaymentMethodEvent } from "@stripe/stripe-js";
import moment from "moment";
import Stripe from "stripe";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";

import Wizard from "../../components/navigation/Wizard";
import ResumeCheckout from "./section/ResumeCheckout";
import Paycards, { PaycardsRef } from "../../components/section/Paycards";
import Ticket from "./section/Ticket";
import Success from "./section/Success";

const Checkout = (props: Props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { setShowError, setShowLoading } = bindActionCreators(
		actionsCreators,
		dispatch
	);
	const currentUser = useSelector((state: State) => state.user.currentUser);
	const appLocation = useSelector((state: State) => state.location.location);

	const paycardsStep = useRef<PaycardsRef>(null);

	const [event, setEvent] = useState<EventScheme | undefined>(undefined);
	const [product, setProduct] = useState<ProductScheme | undefined>(
		undefined
	);
	const [step, setStep] = useState(0);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [paymentMethods, setPaymentsMethods] = useState<
		Array<PaymentMethodScheme>
	>([]);
	const [pastDue, setPastDue] = useState(false);
	const [data, setData] = useState<{
		name?: string;
		surname?: string;
		email?: string;
		phone?: string;
		code?: string;
		paymentMethod?: any;
	}>({});

	useEffect(() => {
		if (location.state && location.state.event && location.state.product) {
			setEvent(location.state.event);
			setProduct(location.state.product);
			const pastDue = location.state.product.startDate
				? moment() > moment(location.state.product.startDate.iso)
				: false;
			setPastDue(pastDue);
			if (currentUser) {
				if (location.state.product.price !== 0) {
					setShowLoading(true);
					PaymentMethod.get({
						userId: currentUser.objectId,
						types: ["paycard"],
					})
						.then((result: any) => {
							setPaymentsMethods(result.array);
							result.array.map((item: any) => {
								if (item.default) {
									setData({ ...data, paymentMethod: item });
								}
							});
							setShowLoading(false);
						})
						.catch((error: ErrorScheme) => {
							setShowLoading(false);
						});
				}
			}
		} else {
			navigate("/");
		}
	}, []);

	const onNextClick = async () => {
		//Ticket
		if (step === 0) {
			if (product && product.price === 0) {
				registerToEvent(currentUser, product, undefined, undefined);
			} else {
				setStep(step + 1);
			}
		}
		//Checkout
		if (step === 1) {
			const newData = await paycardsStep.current?.getData();
			if (newData && newData.paymentMethod && newData.paymentMethod.id) {
				setData({ ...data, ...newData });
				if (product)
					registerToEvent(
						currentUser,
						product,
						newData.paymentMethod.id
					);
			} else if (newData && newData.paymentRequest) {
				newData.paymentRequest.show();
				newData.paymentRequest.on("paymentmethod", async (ev) => {
					setData({
						...data,
						paymentMethod: {
							id: ev.paymentMethod.id,
							brand: "apple_pay",
						},
					});
					if (product)
						registerToEvent(
							currentUser,
							product,
							ev.paymentMethod.id,
							ev
						);
				});
			} else {
				setShowError({
					show: true,
					message:
						"Debes añadir un método de pago para completar la compra de entradas",
				});
			}
		}
	};

	const onLogin = (user: UserScheme) => {
		if (location.state.product.price !== 0) {
			getPaycards(user.objectId);
		}
	};

	const getPaycards = (userId: string) => {
		setLoading(true);
		PaymentMethod.get({
			userId: userId,
			types: ["paycard"],
		})
			.then((result: any) => {
				setPaymentsMethods(result.array);
				setLoading(false);
			})
			.catch((error: ErrorScheme) => {
				setLoading(false);
				setShowError({ show: true, message: error.message });
			});
	};

	const onPaymentMethodClick = (paymentMethod: any) => {
		if (currentUser) {
			setData({
				...data,
				paymentMethod: paymentMethod,
			});
			if (
				paymentMethod.id !== "apple_pay" &&
				paymentMethod.id !== "google_pay"
			) {
				setLoading(true);
				PaymentMethod.setDefault(paymentMethod.objectId, {
					userId: currentUser.objectId,
				})
					.then((result: any) => {
						if (currentUser) getPaycards(currentUser.objectId);
					})
					.catch((error: ErrorScheme) => {
						setLoading(false);
					});
			}
		}
	};

	const onPaymentIntentConfirmed = (
		error?: string,
		paymentRequestEvent?: PaymentRequestPaymentMethodEvent
	) => {
		if (error) {
			Track.track("event register failed", {
				Product: product?.objectId,
				amount: product?.price,
				view: "event",
				error: error,
			});
			if (paymentRequestEvent) paymentRequestEvent.complete("fail");
			setShowError({ show: true, message: error });
			setLoading(false);
		} else {
			Track.track("event register done", {
				Product: product?.objectId,
				amount: product?.price,
				view: "event",
			});
			if (paymentRequestEvent) paymentRequestEvent.complete("success");
			setSuccess(true);
			setLoading(false);
		}
	};

	const registerToEvent = async (
		user: any,
		product: ProductScheme,
		paymentMethodId?: string,
		paymentRequestEvent?: PaymentRequestPaymentMethodEvent
	) => {
		setLoading(true);
		Track.track("event register intent", {
			Product: product.objectId,
			amount: product.price,
			view: "event",
		});
		Product.initRegister(product.objectId, {
			userId: user.objectId,
			currency: "eur",
			redirectUrl: window.location.origin + "/card-success",
			paymentMethod: paymentMethodId ? paymentMethodId : undefined,
		})
			.then((result) => {
				if (product.price !== 0) {
					//Check if 3d secure needed
					const paymentIntent = result.data as Stripe.PaymentIntent;
					if (
						paymentIntent.status === "processing" ||
						paymentIntent.status === "succeeded"
					)
						onPaymentIntentConfirmed(
							undefined,
							paymentRequestEvent
						);
					else
						paycardsStep.current?.confirmPaymentIntent(
							paymentIntent
						);
				} else {
					onPaymentIntentConfirmed();
				}
			})
			.catch((error: ErrorScheme) => {
				onPaymentIntentConfirmed(error.message);
			});
	};

	return (
		<Wizard
			tag={"register-event"}
			location={appLocation}
			loginIndex={0}
			step={step}
			titles={["Reserva tu plaza", "Pago y confirmación"]}
			onError={(error: string) =>
				setShowError({ show: true, message: error })
			}
			onLogin={onLogin}
			onStepChange={(step: number) => {
				setStep(step);
			}}
			buttonProps={{
				loading: loading,
				success: success,
				onClick: onNextClick,
				onSuccess: () => {
					// TODO: 24/05 fix
					if (product?.price === 0) setStep(step + 2);
					else setStep(step + 1);
				},
			}}
			RightViews={
				event && product
					? [
							<ResumeCheckout
								title={product.title}
								price={product.price}
								startDate={
									product.startDate
										? product.startDate.iso
										: undefined
								}
								endDate={
									product.endDate
										? product.endDate.iso
										: undefined
								}
								address={event.address}
								image={event.image.url}
								corporate={
									product.data
										? product.data.corporate
										: false
								}
							/>,
							undefined,
					  ]
					: []
			}
			LeftViews={
				event && product
					? [
							<Ticket product={product} />,

							<Paycards
								ref={paycardsStep}
								paymentMethods={paymentMethods}
								buttonProps={{
									children:
										data.paymentMethod?.brand ===
											"apple_pay" ||
										data.paymentMethod?.brand ===
											"google_pay"
											? "Comprar con"
											: "Comprar entrada por " +
											  product.price +
											  "€",
									width:
										data.paymentMethod?.brand ===
											"apple_pay" ||
										data.paymentMethod?.brand ===
											"google_pay"
											? 220
											: 260,
									request: data.paymentMethod?.brand,
								}}
								currency={"eur"}
								countryCode={"ES"}
								amount={product.price}
								charge={"one"}
								onPaymentMethodClick={onPaymentMethodClick}
								onSetupConfirmed={() =>
									onPaymentIntentConfirmed()
								}
							/>,
							<Success event={event} product={product} />,
					  ]
					: []
			}
		/>
	);
};
export default Checkout;
export interface Props {}
