import { useEffect, useState } from "react";
import { Challenge, ChallengeScheme } from "client-v2";
import { Route, Routes, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import media from "styled-media-query";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../redux";
import { bindActionCreators } from "redux";

import Detail from "../views/challenge/Detail";
import Checkout from "../views/challenge/Checkout";

import Loading from "../components/modal/Loading";
import { Feedback } from "@adoptaunabuelo/react-components";

const FeedbackStyled = styled(Feedback)`
	right: 24px !important;
	left: unset !important;
	${media.lessThan("small")`
        top: 64px;
        left: 24px !important;
        bottom: unset !important;
	`}
`;

const ChallengeLayout = (props: PayoutLayoutProps) => {
	const dispatch = useDispatch();
	const { setShowError, setShowSuccess } = bindActionCreators(
		actionsCreators,
		dispatch
	);
	const error = useSelector((state: State) => state.modals.error);
	const success = useSelector((state: State) => state.modals.success);
	const loading = useSelector((state: State) => state.modals.loading);

	const [searchParams, setSearchParams] = useSearchParams();
	const [challenge, setChallenge] = useState<ChallengeScheme | undefined>(
		undefined
	);
	const [supporters, setSupporters] = useState<Array<any> | undefined>(
		undefined
	);

	useEffect(() => {
		const id = searchParams.get("id");
		if (id) {
			Challenge.getById(id)
				.then((result) => {
					setChallenge(result.data);
					Challenge.getSupporters(result.data.objectId)
						.then((result) => {
							setSupporters(result.data);
						})
						.catch((error) => {
							setSupporters([]);
						});
				})
				.catch((error) => {
					setShowError({
						show: true,
						message: "El parámetro id no es válido",
					});
				});
		} else {
			setShowError({
				show: true,
				message: "El parámetro id no es válido",
			});
		}
	}, []);

	return (
		<>
			<FeedbackStyled
				isVisible={error.show}
				type="error"
				text={error.message}
				onClose={() => setShowError({ show: false })}
			/>
			<FeedbackStyled
				isVisible={success.show}
				type="success"
				text={success.message}
				onClose={() => setShowSuccess({ show: false })}
			/>
			<Loading isVisible={loading ? true : false} />
			<Routes>
				<Route
					path="/"
					element={
						!(challenge && supporters) ? (
							<Loading isVisible={true} />
						) : (
							<Detail
								challenge={challenge}
								supporters={supporters}
							/>
						)
					}
				/>
				<Route path="/registro" element={<Checkout />} />
			</Routes>
		</>
	);
};

export default ChallengeLayout;
export interface PayoutLayoutProps {}
