import { useState, useRef, useEffect } from "react";
import {
	User,
	Product,
	Track,
	PaymentMethod,
	Subscription,
	ProductScheme,
	UserScheme,
	PaymentMethodScheme,
	ErrorScheme,
} from "client-v2";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";
import {
	PaymentRequestPaymentMethodEvent,
	PaymentIntent,
} from "@stripe/stripe-js";
import ReactPixel from "react-facebook-pixel";

import { Color, ColorV2 } from "@adoptaunabuelo/react-components";
import Wizard from "../../components/navigation/Wizard";
import Location, { LocationRef } from "../../components/section/Location";
import Gender, { GenderRef } from "../../components/section/Gender";
import Birth, { BirthRef } from "../../components/section/Birthday";
import Price, { PriceRef } from "../../components/section/Price";
import Paycards, { PaycardsRef } from "../../components/section/Paycards";
import SuccessRightStep from "./steps/SuccessRightStep";
import SuccessLeftStep from "./steps/SuccessLeftStep";
import LeftStep from "./steps/LeftStep";
import PaymentLeftStep from "./steps/PaymentLeftStep";
import { Button } from "@adoptaunabuelo/react-components";
import Names from "../../assets/images/history/Names";
import VisionRightStep from "./steps/VisionRightStep";
import SkipModal from "../../components/modal/SkipPaymentModal";

const SignUpWizard = (props: Props) => {
	const dispatch = useDispatch();
	const { setShowError } = bindActionCreators(actionsCreators, dispatch);
	const isMobile: boolean = useSelector(
		(state: State) => state.screen.isMobile
	);
	const currentUser = useSelector((state: State) => state.user.currentUser);
	const location = useSelector((state: State) => state.location.location);

	const [index, setIndex] = useState(0);
	const [data, setData] = useState<{
		name?: string;
		surname?: string;
		email?: string;
		phone?: string;
		code?: string;
		birthday?: Date;
		product?: any;
		paymentMethod?: any;
	}>({});
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [paymentMethods, setPaymentsMethods] = useState<
		Array<PaymentMethodScheme>
	>([]);
	const [showSkipModal, setShowSkipModal] = useState(false);

	const locationStep = useRef<LocationRef>(null);
	const genderStep = useRef<GenderRef>(null);
	const birthStep = useRef<BirthRef>(null);
	const priceStep = useRef<PriceRef>(null);
	const paycardsStep = useRef<PaycardsRef>(null);

	useEffect(() => {
		initialStep();
	}, []);

	const delay = (ms: number) =>
		new Promise((resolve) => setTimeout(resolve, ms));

	const initialStep = async () => {
		if (currentUser) {
			if (currentUser.birthday) {
				if (currentUser.gender) {
					if (currentUser.address) setIndex(3);
					else setIndex(2);
				} else setIndex(1);
			} else setIndex(0);
		} else setIndex(0);
	};

	const onNextPress = async () => {
		//Birthday
		if (index === 0) {
			const newData = birthStep.current?.getData();
			if (newData) {
				setData({ ...data, ...newData });
				changeBirthday(newData.birthday);
			}
		}
		//Gender
		else if (index === 1) {
			const newData = genderStep.current?.getData();
			if (newData) {
				setData({ ...data, ...newData });
				changeGender(newData.gender);
			}
		}
		//Location
		else if (index === 2) {
			const newData = locationStep.current?.getData();
			if (newData) {
				setData({ ...data, ...newData });
				changeLocation(
					newData.coordinates,
					newData.address,
					newData.shortAddress,
					newData.country,
					newData.timeZone,
					newData.city,
					newData.postalCode
				);
			}
		}
		//Vision
		else if (index === 3) {
			setIndex(index + 1);
		}
		//Amount
		else if (index === 4) {
			const newData = priceStep.current?.getData();
			if (newData && newData.price && newData.charge) {
				getProduct(newData.price, newData.charge);
			}
		}
		//Payment method
		else if (index === 5) {
			const newData = await paycardsStep.current?.getData();
			if (newData && newData.paymentMethod && newData.paymentMethod.id) {
				setData({ ...data, ...newData });
				if (currentUser) {
					initSubscription(currentUser, newData.paymentMethod);
				}
			} else if (newData && newData.paymentRequest) {
				newData.paymentRequest.show();
				newData.paymentRequest.on("paymentmethod", async (ev) => {
					setData({
						...data,
						paymentMethod: {
							id: ev.paymentMethod.id,
							brand: "apple_pay",
						},
					});
					if (currentUser) {
						initSubscription(currentUser, ev.paymentMethod);
					}
				});
			} else if (data.paymentMethod) {
				if (currentUser) {
					initSubscription(currentUser, {
						id: data.paymentMethod.objectId,
					});
				}
			} else {
				setShowError({
					show: true,
					message:
						"Debes añadir un método de pago para completar la compra de entradas",
				});
			}
		}
	};

	const changeGender = async (gender: string) => {
		if (currentUser) {
			setLoading(true);
			User.set(currentUser.objectId, {
				gender: gender,
			})
				.then(() => {
					setLoading(false);
					setIndex(index + 1);
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
					setShowError({ show: true, message: error.message });
				});
		}
	};

	const changeBirthday = async (birthday: Date) => {
		if (currentUser) {
			setLoading(true);
			User.set(currentUser.objectId, {
				birthday: birthday,
			})
				.then(() => {
					setLoading(false);
					setIndex(index + 1);
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
					setShowError({ show: true, message: error.message });
				});
		}
	};

	const changeLocation = async (
		coordinates: google.maps.LatLngLiteral,
		address: string,
		shortAddress: string,
		country: string,
		timeZone: string,
		city?: string,
		postalCode?: string
	) => {
		if (currentUser) {
			setLoading(true);
			User.set(currentUser.objectId, {
				location: {
					lat: coordinates.lat,
					lng: coordinates.lng,
				},
				address: address,
				shortAddress: shortAddress,
				country: country,
				timeZone: timeZone,
				city: city,
				zipCode: postalCode,
			})
				.then(() => {
					setLoading(false);
					setSuccess(true);
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
					setShowError({ show: true, message: error.message });
				});
		}
	};

	const getProduct = async (price: number, charge: string) => {
		setLoading(true);
		Product.get({
			price: price,
			charge: charge,
			active: true,
		})
			.then(async (result) => {
				//Track on mixpanel
				const product = result.data[0];
				Track.track("donation product selected", {
					Product: product.objectId,
					amount: price,
					view: "web-register",
				});
				if (currentUser) {
					const defaultPaymentMethod = await getPaycards(
						currentUser.objectId,
						charge
					);
					setData({
						...data,
						product: result.data[0],
						paymentMethod: defaultPaymentMethod,
					});
					setIndex(index + 1);
				}
			})
			.catch((error: ErrorScheme) => {
				setLoading(false);
				setShowError({ show: true, message: error.message });
			});
	};

	const getPaycards = async (
		userId: string,
		charge: string
	): Promise<PaymentMethodScheme | undefined> => {
		setLoading(true);
		const result = await PaymentMethod.get({
			userId: userId,
			types: charge === "one" ? ["paycard"] : ["sepa_debit"],
			doesNotExist: charge === "one" ? undefined : ["stripeId"],
		});
		setPaymentsMethods(result.data);
		let paymentMethod = undefined;
		result.data.map((item) => {
			if (item.default) {
				paymentMethod = item;
			}
		});
		if (!paymentMethod && result.data.length > 0)
			paymentMethod = result.data[0];
		setLoading(false);
		return paymentMethod;
	};

	const onPaymentMethodClick = (paymentMethod: any) => {
		if (currentUser) {
			setData({
				...data,
				paymentMethod: paymentMethod,
			});
			if (
				paymentMethod.id !== "apple_pay" &&
				paymentMethod.id !== "google_pay"
			) {
				setLoading(true);
				PaymentMethod.setDefault(paymentMethod.objectId, {
					userId: currentUser.objectId,
				})
					.then(async (result: any) => {
						if (currentUser && data.product) {
							const defaultPaymentMethod = await getPaycards(
								currentUser.objectId,
								data.product.charge
							);
							setData({
								...data,
								paymentMethod: defaultPaymentMethod,
							});
						}
					})
					.catch((error: string) => {
						setLoading(false);
					});
			}
		}
	};

	const initSubscription = async (user: UserScheme, paymentMethod: any) => {
		if (data.product) {
			setLoading(true);

			//Track on Facebook
			if (data.product.charge === "annually") {
				ReactPixel.track("fb_mobile_level_achieved");
			} else {
				ReactPixel.track("fb_mobile_add_payment_info");
			}

			//Track on mixpanel
			Track.track("donation payment intent", {
				Product: data.product.objectId,
				amount: data.product.price,
				view: "web-register",
			});

			Subscription.create({
				userId: user.objectId,
				productId: data.product.objectId,
				paymentMethod: paymentMethod.id,
				currency:
					location && location.currency
						? location.currency.name
						: "eur",
				redirectUrl: window.location.origin + "/card-success",
			})
				.then(() => {
					onPaymentIntentConfirmed();
				})
				.catch((error: ErrorScheme) => {
					onPaymentIntentConfirmed(error.message);
					setShowError({ show: true, message: error.message });
				});
		}
	};

	const onPaymentIntentConfirmed = (
		error?: string,
		paymentRequestEvent?: PaymentRequestPaymentMethodEvent
	) => {
		if (data.product) {
			if (error) {
				//Track on mixpanel
				Track.track("donation activation failed", {
					Product: data.product.objectId,
					amount: data.product.price,
					view: "web-register",
					error: error,
				});

				if (paymentRequestEvent) paymentRequestEvent.complete("fail");
				setShowError({ show: true, message: error });
				setLoading(false);
			} else {
				//Track on Facebook
				if (data.product.charge === "annually") {
					ReactPixel.track("fb_mobile_purchase");
				} else {
					ReactPixel.track("fb_mobile_add_to_cart");
				}

				//Track on mixpanel
				Track.track("donation activation done", {
					Product: data.product.objectId,
					amount: data.product.price,
					view: "web-register",
				});
				Track.charge(data.product.price);

				if (paymentRequestEvent)
					paymentRequestEvent.complete("success");
				setSuccess(true);
				setLoading(false);
			}
		}
	};

	const leftViews = [
		<LeftStep
			src={require("../../assets/images/background/register/back_01.webp")}
			title={
				"¡Si piensas que los abuelos son geniales, esta es tu comunidad!"
			}
		/>,
		<LeftStep
			src={require("../../assets/images/background/register/back_02.webp")}
			title={
				"¡Vive una experiencia única y conecta con abuelos increíbles!"
			}
		/>,
		<LeftStep
			src={require("../../assets/images/history/marivi.webp")}
			subtitle={
				"“Adopta Un Abuelo me cambió la vida. Estuve cuatro años sin salir de la residencia hasta que conocí a mi voluntario Pablo. Su compañía me animó tanto que me decidí a salir a comprar una pastas para nuestra siguiente visita”"
			}
			footer="Abuela de Pamplona"
			Header={<Names type="marivi" />}
		/>,
		<LeftStep
			src={require("../../assets/images/history/enriqueta.webp")}
			subtitle={
				'"Gracias a Adopta Un Abuelo he aprendido a utilizar el teléfono móvil. Ahora puedo hacer videollamadas con mis voluntarios todas las semanas."'
			}
			footer="Abuela de Madrid"
			Header={<Names type="enriqueta" />}
		/>,
		<LeftStep
			src={require("../../assets/images/history/jose.webp")}
			subtitle={
				'"Cada vez que mis voluntarias me visitan me siento un poco más feliz. Cuando salimos a dar un paseo siempre presumo orgulloso de que son mi nietas."'
			}
			footer="Abuelo de Jaén"
			Header={<Names type="jose" />}
		/>,
		<LeftStep
			src={require("../../assets/images/history/antonia.webp")}
			subtitle={
				'"Mi mayor ilusión era aprender a leer y gracias a la dedicación de mis voluntarias Charo y Paloma pude conseguirlo. ¡Las quiero mucho!"'
			}
			footer="Abuela de Córdona"
			Header={<Names type="antonia" />}
		/>,
		<LeftStep
			src={require("../../assets/images/background/register/back_vision.webp")}
			subtitle={"Bernardo, el primer abuelo adoptado"}
		/>,
		<PaymentLeftStep
			src={require("../../assets/images/background/register/back_video.webp")}
			title="Ningún mayor debería sentirse solo. ¿Nos ayudas?"
			subtitle="Ellos han dado todo por nosotros y ha llegado el momento de darlo todo por ellos."
		/>,
		<PaymentLeftStep
			src={require("../../assets/images/background/register/back_video.webp")}
			title="Ningún mayor debería sentirse solo. ¿Nos ayudas?"
			subtitle="Ellos han dado todo por nosotros y ha llegado el momento de darlo todo por ellos."
		/>,
		<SuccessLeftStep />,
	];

	const rightViews = [
		<Birth ref={birthStep} onEnter={onNextPress} />,
		<Gender ref={genderStep} onChange={onNextPress} />,
		<Location ref={locationStep} onEnter={onNextPress} />,
		<VisionRightStep
			buttonProps={{
				children: "Comparto vuestra visión",
			}}
		/>,
		<Price
			ref={priceStep}
			currency="eur"
			language="es"
			showInfo={true}
			title="Da un paso más y hazte socio"
			options={["monthly", "annually"]}
		/>,
		<Paycards
			ref={paycardsStep}
			paymentMethods={paymentMethods}
			buttonProps={{
				children:
					data.paymentMethod?.brand === "apple_pay" ||
					data.paymentMethod?.brand === "google_pay"
						? "Donar con"
						: data.product
						? "Donar " +
						  data.product.price +
						  " €" +
						  (data.product.charge === "monthly"
								? "/mes"
								: data.product.charge === "annually"
								? "/año"
								: "")
						: "",
				width:
					data.paymentMethod?.brand === "apple_pay" ||
					data.paymentMethod?.brand === "google_pay"
						? 200
						: data.product
						? data.product.charge === "monthly"
							? 200
							: data.product.charge === "annually"
							? 200
							: 160
						: 220,
				request: data.paymentMethod?.brand,
			}}
			currency={"eur"}
			countryCode={"ES"}
			charge={data.product ? data.product.charge : "one"}
			currentUser={currentUser}
			amount={data.product ? data.product.price : 0}
			onPaymentMethodClick={onPaymentMethodClick}
			onSetupConfirmed={() => onPaymentIntentConfirmed()}
		/>,
		<SuccessRightStep user={currentUser} />,
	];

	return (
		<>
			<SkipModal
				isVisible={showSkipModal}
				type={isMobile ? "full-screen" : "default"}
				video={"https://data.adoptaunabuelo.org/video/donation/welcome_01.mp4"}
				onClick={() => {
					Track.track("skip donation modal click", {
						view: "web-register",
					});
					setShowSkipModal(false);
				}}
				onClose={() => {
					Track.track("skip donation modal close", {
						view: "web-register",
					});
					setShowSkipModal(false);
					setIndex(index + 2);
				}}
			/>
			<Wizard
				tag={"register-volunteer"}
				location={location}
				loginIndex={0}
				step={index}
				onStepChange={(step) => setIndex(step)}
				RightViews={leftViews}
				LeftViews={rightViews}
				titles={[
					"Tu fecha de nacimiento",
					"Tu género",
					"Tu ubicación",
					undefined,
					undefined,
					data.product?.charge === "one"
						? "Método de pago"
						: "Cuenta bancaria",
				]}
				subtitles={[
					"Dinos tu fecha de cumpleaños 🎂",
					"¡Queremos conocerte mejor! Esto nos ayudará a conectarte con tu nuevo abuelo/a",
					"Conocer la zona en la que vives nos dará más pistas para realizar el mejor emparejamiento",
					"",
					"",
					data.product?.charge === "one"
						? "Introduce el método de pago con el que realizarás la donación."
						: "Introduce el IBAN donde quieres que domiciliemos tu donación.",
				]}
				buttonProps={{
					loading: loading,
					success: success,
					style: {
						backgroundColor:
							(data.paymentMethod?.brand === "apple_pay" ||
								data.paymentMethod?.brand === "google_pay") &&
							index === 5
								? Color.background.full
								: undefined,
					},
					onClick: onNextPress,
					onSuccess: () => {
						setIndex(index + 1);
						delay(600).then(() => {
							setSuccess(false);
						});
					},
				}}
				skipButtonProps={
					index === 4
						? {
								children: "No quiero colaborar",
								onClick: () => {
									Track.track("skip donation modal show", {
										view: "web-register",
									});
									setShowSkipModal(true);
								},
						  }
						: undefined
				}
				headerProps={{
					children: (
						<Button
							size="small"
							style={{
								backgroundColor: "white",
								color: ColorV2.text.neutralHard,
							}}
							onClick={() =>
								window.open(
									"whatsapp://send?phone=+34670347229"
								)
							}
						>
							¿Necesitas ayuda?
						</Button>
					),
					childrenPosition: "right",
				}}
				onError={(message: string) =>
					setShowError({ show: true, message: message })
				}
			/>
		</>
	);
};

export default SignUpWizard;
export interface Props {}
