import { ActionType } from "./actions-types";
import { Dispatch } from "redux";
import { Action } from "./actions";
import { UserScheme } from "client-v2";

export const setShowError = (data: { show: boolean; message?: string }) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SHOW_ERROR,
			data: data,
		});
	};
};

export const setShowLoading = (show: boolean) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SHOW_LOADING,
			data: show,
		});
	};
};

export const setShowSuccess = (data: {
	show: boolean;
	title?: string;
	message?: string;
}) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SHOW_SUCCESS,
			data: data,
		});
	};
};

export const setAppTranslation = (translation: Object) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.APP_TRANSLATION,
			data: translation,
		});
	};
};

export const setAppLocation = (location: {
	country_code: string;
	currency: {
		name: string;
		symbol: string;
		hasSEPA: boolean;
		conversion: number;
		price: {
			annually: {
				min: number;
				max: number;
				default: number;
				gift: number;
				jump: number;
			};
			monthly: {
				min: number;
				max: number;
				default: number;
				gift: number;
				jump: number;
			};
			one: {
				min: number;
				max: number;
				default: number;
				jump: number;
			};
		};
	};
	language: string;
}) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.APP_LOCATION,
			data: location,
		});
	};
};

export const setCurrentUser = (user: UserScheme | null) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.CURRENT_USER,
			data: user,
		});
	};
};

export const setIsScreen01 = (is: boolean) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_IS_SCREEN01,
			data: is,
		});
	};
};

export const setIsScreen02 = (is: boolean) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_IS_SCREEN02,
			data: is,
		});
	};
};

export const setIsMobile = (is: boolean) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_IS_MOBILE,
			data: is,
		});
	};
};
