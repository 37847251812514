const initialState={
	isScreen01: false,
    isScreen02: false,
    isMobile: false,
}

export default function(state=initialState, action: any) {
  	switch (action.type) {
		case 'SET_IS_SCREEN01': {
			return {
				...state,
				isScreen01: action.data
			};
		}
        case 'SET_IS_SCREEN02': {
			return {
				...state,
				isScreen02: action.data
			};
		}
        case 'SET_IS_MOBILE': {
			return {
				...state,
				isMobile: action.data
			};
		}
		default:
			return state;
  	}
}
