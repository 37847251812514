import { combineReducers } from "redux";
import modals from "./modals";
import location from "./location";
import user from "./user";
import screen from "./screen";

const reducers = combineReducers({ modals, location, user, screen });
export default reducers;

export type State = ReturnType<typeof reducers>;
