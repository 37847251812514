import styled from "styled-components";

import SectionContainer from "../../../components/container/SectionContainer";
import { Text } from "@adoptaunabuelo/react-components";
import SignatureImage from "../../../assets/images/vision/team_signature.svg";
import TitleImage from "../../../assets/images/vision/our_mission.svg";

const Title = styled.img`
	height: 38px;
	margin-bottom: 32px;
	width: fit-content;
`;
const Signature = styled.img`
	height: 28px;
	width: fit-content;
`;

const VisionRightStep = (props: Props) => {
	return (
		<SectionContainer>
			<Title src={TitleImage} />
			<Text type="p" style={{ marginBottom: 12 }}>
				Nos encantan las personas mayores y nos comprometemos a
				potenciar su papel protagonista en la sociedad del siglo XXI.
			</Text>
			<Text type="p" style={{ marginBottom: 12 }}>
				Pertenecer a nuestra comunidad significa comportarse con
				integridad, amabilidad y honestidad, respetando y apoyando la
				diversidad de raza, religión, género y orientación sexual.
			</Text>
			<Text type="p" style={{ marginBottom: 12 }}>
				Estamos creando una comunidad segura e inclusiva que sea un
				referente de apoyo a las personas mayores.
			</Text>
			<Text type="p" style={{ marginBottom: 24 }}>
				Con mucho cariño,
			</Text>
			<Signature src={SignatureImage} />
		</SectionContainer>
	);
};
export default VisionRightStep;
export interface Props {
	buttonProps?: {
		children: string;
	};
}
