import { CSSProperties, useState } from "react";
import styled from "styled-components";
import media from "styled-media-query";

import { Text, Color, Button } from "@adoptaunabuelo/react-components";
import { PlayCircle } from "lucide-react";
import VideoModal from "../../../components/modal/VideoModal";

const size: any = "1080px";

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100%;
	width: 100%;
	${media.lessThan("medium")`
        display: none;
    `}
`;
const ImageView = styled.div`
	display: flex;
	height: 350px;
	width: 350px;
	align-items: center;
	justify-content: center;
	object-fit: cover;
	border-radius: 12px;
	margin-bottom: 24px;
	background-repeat: no-repeat;
	background-size: cover;
	${media.lessThan(size)`
        height: 300px;
        width: 300px;
    `}
	${media.lessThan("medium")`
        height: 0px;
    `}
`;
const TextContainer = styled.div`
	width: 350px;
	${media.lessThan(size)`
        width: 300px;
    `}
`;

const LeftStep = (props: Props) => {
	const [showModal, setShowModal] = useState(false);

	return (
		<Container>
			<VideoModal
				isVisible={showModal}
				url={
					"https://data.adoptaunabuelo.org/video/donation/welcome_01.mp4"
				}
				onClose={() => setShowModal(false)}
			/>
			<ImageView
				style={{
					backgroundImage: "url(" + props.src + ")",
				}}
			>
				<Button
					size="small"
					icon={<PlayCircle color="white" />}
					style={{ backgroundColor: Color.text.full }}
					onClick={() => setShowModal(true)}
				>
					Ver video
				</Button>
			</ImageView>
			<TextContainer>
				<Text
					type="h3"
					weight="semibold"
					style={{ color: Color.text.deepBlue }}
				>
					{props.title}
				</Text>
				{props.subtitle && (
					<Text
						type="p"
						style={{ color: Color.text.deepBlue, marginTop: 16 }}
					>
						{props.subtitle}
					</Text>
				)}
			</TextContainer>
		</Container>
	);
};
export default LeftStep;
export interface Props {
	src: string;
	title?: string;
	titleStyle?: CSSProperties;
	subtitle?: string;
	Header?: JSX.Element;
	footer?: string;
}
