import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	PaymentMethod,
	ChallengeScheme,
	UserScheme,
	PaymentMethodScheme,
} from "client-v2";
import { PaymentRequestPaymentMethodEvent } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";

import Price, { PriceRef } from "../../components/section/Price";
import Paycards, { PaycardsRef } from "../../components/section/Paycards";
import ResumeCheckout from "./section/ResumeCheckout";
import Success from "./section/Success";
import Wizard from "../../components/navigation/Wizard";
import { Color, ColorV2 } from "@adoptaunabuelo/react-components";
import Donation from "../../components/section/Donation";

const Checkout = (props: Props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { setShowError, setShowSuccess, setShowLoading } = bindActionCreators(
		actionsCreators,
		dispatch
	);
	const currentUser = useSelector(
		(state: State) => state.user.currentUser
	);
	const appLocation = useSelector(
		(state: State) => state.location.location
	);

	const priceStep = useRef<PriceRef>(null);
	const paycardsStep = useRef<PaycardsRef>(null);

	const [challenge, setChallenge] = useState<ChallengeScheme | undefined>(
		undefined
	);
	const [paymentMethods, setPaymentsMethods] = useState<
		Array<PaymentMethodScheme>
	>([]);
	const [amount, setAmount] = useState(15);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [data, setData] = useState<any>({});
	const [step, setStep] = useState(0);
	const [completed, setCompleted] = useState(false);

	useEffect(() => {
		if (location.state && location.state.challenge) {
			setShowLoading(true);
			setChallenge(location.state.challenge);
			const restAmount =
				location.state.challenge.goal - location.state.challenge.amount;
			if (restAmount <= 0) setCompleted(true);
			setShowLoading(false);
		} else {
			navigate("/");
		}
	}, []);

	const onNextClick = async () => {
		//Price
		if (step === 0) {
			const newData = priceStep.current?.getData();
			if (newData && newData.price && currentUser) {
				setLoading(true);
				const result = await Donation.getProduct({
					user: currentUser,
					price: newData.price,
					charge: "one",
					view: "challenge",
				});
				setPaymentsMethods(result.paymentMethods);
				setData({
					...data,
					price: result.product.price,
					paymentMethod: result.defaultPaymentMethod,
				});
				setLoading(false);
				setStep(step + 1);
			}
		}
		//Checkout
		else if (step === 1) {
			setLoading(true);
			const newData = await paycardsStep.current?.getData();
			setLoading(false);
			//Paycard
			if (newData && newData.paymentMethod && newData.paymentMethod.id) {
				setData({ ...data, ...newData });
				if (currentUser && challenge) {
					registerToChallenge(
						currentUser,
						challenge,
						data.price,
						newData.paymentMethod.id
					);
				}
			}
			//Apple Pay
			else if (newData && newData.paymentRequest) {
				newData.paymentRequest.show();
				newData.paymentRequest.on("paymentmethod", async (ev) => {
					setData({
						...data,
						paymentMethod: {
							id: ev.paymentMethod.id,
							brand: "apple_pay",
						},
					});
					if (currentUser && challenge)
						registerToChallenge(
							currentUser,
							challenge,
							data.price,
							ev.paymentMethod.id,
							ev
						);
				});
			} else {
				setShowError({
					show: true,
					message:
						"Debes añadir un método de pago para completar la compra de entradas",
				});
			}
		}
	};

	const onPaymentMethodClick = (paymentMethod: any) => {
		if (currentUser) {
			setData({
				...data,
				paymentMethod: paymentMethod,
			});
			if (
				paymentMethod.id !== "apple_pay" &&
				paymentMethod.id !== "google_pay"
			) {
				setLoading(true);
				PaymentMethod.setDefault(paymentMethod.objectId, {
					userId: currentUser.objectId,
				})
					.then(async (result: any) => {
						if (currentUser) {
							const result = await Donation.getPaycards(
								currentUser.objectId,
								"one"
							);
							setPaymentsMethods(result.paymentMethods);
							setData({
								...data,
								paymentMethod: result.defaultPaymentMethod,
							});
							setLoading(false);
						}
					})
					.catch((error) => {
						setLoading(false);
					});
			}
		}
	};

	const registerToChallenge = async (
		user: UserScheme,
		challenge: ChallengeScheme,
		amount: number,
		paymentMethod: string,
		paymentRequestEvent?: PaymentRequestPaymentMethodEvent
	) => {
		setLoading(true);
		const result = await Donation.registerToChallenge({
			user: user,
			challenge: challenge,
			amount: amount,
			paymentMethod: paymentMethod,
			paymentRequestEvent: paymentRequestEvent,
			view: "challenge",
		});
		//Error
		if (result.error) {
			setShowError({ show: true, message: result.error });
			setLoading(false);
		}
		//Need 3d secure confirmation
		else if (result.result) {
			paycardsStep.current?.confirmPaymentIntent(result.result);
		}
		//Finish confirmation
		else {
			setSuccess(true);
			setLoading(false);
		}
	};

	const onShareClick = (op: any) => {
		const url =
			"https://adoptaunabuelo.org/reto/?id=" + challenge?.objectId;
		if (op.id === "twitter") {
			window.open("http://www.twitter.com/share?url=" + url, "_blank");
		} else if (op.id === "facebook") {
			window.open("http://www.facebook.com/sharer.php?u=" + url);
		} else if (op.id === "whatsapp") {
			window.open("whatsapp://send?text=" + url);
		} else if (op.id === "url") {
			navigator.clipboard.writeText(url);
			setShowSuccess({
				show: true,
				message: "Se ha copiado el enlace en tu portapapeles",
			});
		}
	};

	return (
		<Wizard
			tag={"register-dream"}
			location={appLocation}
			loginIndex={0}
			step={step}
			titles={["Colabora", "Confirmación"]}
			subtitles={
				completed
					? [
							"Selecciona la cantidad con la que quieres colaborar para ayudarnos a cumplir futuros sueños.",
							"",
					  ]
					: [
							challenge?.type === "rose"
								? "Selecciona la cantidad con la que quieres colaborar, recuerda que enviar cada rosa tiene un coste de 5€."
								: "Selecciona la cantidad con la que quieres colaborar para ayudarnos a cumplir el sueño.",
							"",
					  ]
			}
			onError={(error: string) =>
				setShowError({ show: true, message: error })
			}
			onStepChange={(step: number) => {
				setStep(step);
			}}
			buttonProps={{
				loading: loading,
				success: success,
				style: {
					backgroundColor:
						challenge?.type === "rose"
							? ColorV2.surface.redMedium
							: Color.text.deepBlue,
				},
				onClick: onNextClick,
				onSuccess: () => setStep(step + 1),
			}}
			RightViews={
				challenge
					? [
							<ResumeCheckout
								challenge={challenge}
								amount={amount}
							/>,
							undefined,
					  ]
					: []
			}
			LeftViews={[
				<Price
					ref={priceStep}
					currency={"eur"}
					language={"es"}
					hideTitle={true}
					hideSubtitle={true}
					options={
						completed ? ["monthly", "annually", "one"] : ["one"]
					}
					defaultPrice={completed ? undefined : 15}
					minPrice={5}
					maxPrice={
						completed
							? undefined
							: challenge
							? challenge.type === "rose"
								? 100
								: challenge.goal - challenge.amount < 250
								? challenge.goal - challenge.amount
								: 250
							: 100
					}
					challenge={challenge}
					completed={completed}
					onPriceChange={(amount) => setAmount(amount)}
				/>,
				<Paycards
					ref={paycardsStep}
					paymentMethods={paymentMethods}
					buttonProps={{
						children:
							data.paymentMethod?.brand === "apple_pay" ||
							data.paymentMethod?.brand === "google_pay"
								? "Colaborar con"
								: "Colaborar con " +
								  data.price +
								  " €" +
								  (data.charge === "monthly"
										? "/mes"
										: data.charge === "annually"
										? "/año"
										: ""),
						width: "100%",
						request: data.paymentMethod?.brand,
					}}
					currency={"eur"}
					countryCode={"ES"}
					amount={amount}
					charge={"one"}
					onPaymentMethodClick={onPaymentMethodClick}
					onSetupConfirmed={(
						paymentMethodId?: string,
						error?: string
					) => {
						if (error) {
							setShowError({ show: true, message: error });
							setLoading(false);
						} else {
							setSuccess(true);
							setLoading(false);
						}
					}}
				/>,
				<Success onShareClick={onShareClick} user={currentUser} />,
			]}
		/>
	);
};
export default Checkout;
export interface Props {}
