import { useState } from "react";
import SectionContainer from "./SectionContainer";
import { Auth, ErrorScheme, UserScheme } from "client-v2";
import { Color, Input, Text } from "@adoptaunabuelo/react-components";
import { useSelector } from "react-redux";
import { State } from "../../../redux";

const Code = ({ ...props }: CodeProps) => {
	const translation = useSelector((state: State) => state.location.translation);
	const [loading, setLoading] = useState(false);

	const onNextClick = (code: string) => {
		if (props.user) {
			setLoading(true);
			Auth.login(props.user.username, code)
				.then((result) => {
					setLoading(false);
					localStorage.removeItem("temp-login");
					props.onFinish({ data: result.data });
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
					props.onFinish({ error: error.message });
				});
		}
	};

	return (
		<SectionContainer
			title={translation.code_title}
			subtitle={translation.code_subtitle+props.user?.phone}
			animated={props.animated}
		>
			<Input
				style={{marginTop: 32}}
				type="code"
				loading={loading}
				autoFocus={true}
				onChange={onNextClick}
			/>
			{props.countdown && props.countdown > 0 ? (
				<Text
					type="p2"
					style={{ color: Color.text.high, marginTop: 24 }}
				>
					{translation.code_countdown_01}{props.countdown}{translation.code_countdown_02}
				</Text>
			) : null}
		</SectionContainer>
	);
};
export default Code;
export interface CodeProps {
	user?: UserScheme;
	animated?: boolean;
	countdown?: number;
	onFinish: (result: { data?: UserScheme; error?: string }) => void;
}
