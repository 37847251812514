import styled from "styled-components";
import { CSSProperties } from "react";
import media from "styled-media-query";
import { Text } from "@adoptaunabuelo/react-components";
import { useSelector } from "react-redux";
import { State } from "../../redux";

const Container = styled.div`
	position: relative;
	width: fit-content;
	width: 108px;
	height: 132px;
	${media.lessThan("medium")`
		height: 120px;
		width: 96px;
	`}
`;
const IconContainer = styled.div<{ $backgroundColor?: string }>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 88px;
	width: 88px;
	margin-top: 10px;
	margin-left: 2px;
	background-color: ${(props) => props.$backgroundColor};
	${media.lessThan("medium")`
        margin-top: 8px;
		height: 78px;
		width: 78px;
	`}
`;
const Background = styled.img`
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	height: -webkit-fill-available;
	height: -moz-available;
`;
const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 1;
	position: relative;
	height: 100%;
`;
const Title = styled(Text)`
	max-width: -webkit-fill-available;
	max-width: -moz-available;
	margin: 4px 9px 0px 9px !important;
	width: 100%;
	font-family: DM Mono !important;
	font-size: 12px !important;
	max-height: 24px;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	${media.lessThan("medium")`
		font-size: 10px !important;
		margin: 7px 9px 0px 9px!important
	`}
`;

const HobbiesStamp = (props: StampProps) => {
	const isMobile: boolean = useSelector(
		(state: State) => state.screen.isMobile
	);

	return (
		<Container role="stamp" style={props.style}>
			<Background
				src={require(isMobile
					? "../../assets/images/background/stamp-m.webp"
					: "../../assets/images/background/stamp-l.webp")}
			/>
			<Content>
				<IconContainer $backgroundColor={props.backgroundColor}>
					<Text type="d1">{props.icon}</Text>
				</IconContainer>
				<Title
					type="p"
				>
					{props.title}
				</Title>
			</Content>
		</Container>
	);
};
export default HobbiesStamp;
export interface StampProps {
	style?: CSSProperties;
	icon: string;
	title: string;
	backgroundColor: string;
}
