import { CSSProperties } from "react";
import styled from "styled-components";
import media from "styled-media-query";

import { Text, Color } from "@adoptaunabuelo/react-components";

const size: any = "1080px";

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100%;
	width: 100%;
	${media.lessThan("medium")`
        display: none;
    `}
`;
const ImageView = styled.img`
	height: 350px;
	width: 350px;
	object-fit: cover;
	border-radius: 12px;
	margin-bottom: 24px;
	${media.lessThan(size)`
        height: 300px;
        width: 300px;
    `}
	${media.lessThan("medium")`
        height: 0px;
    `}
`;
const TextContainer = styled.div`
	width: 350px;
	${media.lessThan(size)`
        width: 300px;
    `}
`;
const HeaderView = styled.div`
	margin-top: 16px;
`;

const LeftStep = (props: Props) => {
	return (
		<Container>
			<ImageView src={props.src} />
			<TextContainer>
				<Text
					type="h3"
					weight="semibold"
					style={{ color: Color.text.deepBlue }}
				>
					{props.title}
				</Text>
				{props.subtitle && (
					<Text type="p" style={{ color: Color.text.deepBlue }}>
						{props.subtitle}
					</Text>
				)}
				{props.Header && <HeaderView>{props.Header}</HeaderView>}
				{props.footer && (
					<Text type="p" style={{ color: Color.text.high }}>
						{props.footer}
					</Text>
				)}
			</TextContainer>
		</Container>
	);
};
export default LeftStep;
export interface Props {
	src: string;
	title?: string;
	titleStyle?: CSSProperties;
	subtitle?: string;
	Header?: JSX.Element;
	footer?: string;
}
