import styled from "styled-components";
import moment from "moment";
import media from "styled-media-query";

import { Text, Color } from "@adoptaunabuelo/react-components";
import ResumeStyled from "../../../components/section/Resume";

const screenSize2: any = "1150px";

const Column = styled.div`
	display: flex;
	flex-direction: column;
`;
const EventImage = styled.img`
	height: 109px;
	width: 109px;
	background-color: ${Color.background.soft};
	border-radius: 12px;
	object-fit: cover;
	margin-left: 16px;
	${media.lessThan(screenSize2)`
        display: none;
    `}
`;

const Resume = (props: Props) => {
	return (
		<ResumeStyled style={{ flexDirection: "row", alignItems: "center" }}>
			<Column style={{ flex: 1 }}>
				<Text type="p" weight="medium">
					{props.title}
				</Text>
				<Text type="p2" style={{ marginTop: 12 }}>
					{moment(props.startDate).format("ddd DD MMMM HH:mm")} -{" "}
					{moment(props.endDate).format("HH:mm")}
				</Text>
				<Text type="p2" style={{ marginTop: 4 }}>
					{props.address}
				</Text>
			</Column>
			<EventImage src={props.image} />
		</ResumeStyled>
	);
};
export default Resume;
export interface Props {
	title: string;
	price: number;
	startDate?: string;
	endDate?: string;
	address: string;
	image: string;
	corporate?: boolean;
	onClick?: () => void;
}
