import { ComponentPropsWithoutRef } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { ColorV2 } from "@adoptaunabuelo/react-components";

const screenSize2: any = "1024px";

const Container = styled.div`
	display: flex;
	position: sticky;
	top: 94px;
	flex-direction: row;
	background-color: white;
	border: ${"1px solid " + ColorV2.border.neutralSoft};
	border-radius: 12px;
	padding: 32px 24px;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
	max-width: 425px;
	${media.lessThan(screenSize2)`
        padding: 24px 16px;
    `}
	${media.lessThan("medium")`
        display: none;
    `}
`;

const Resume = (props: Props) => {
	return <Container {...props}>{props.children}</Container>;
};
export default Resume;
export interface Props extends ComponentPropsWithoutRef<"div"> {}
