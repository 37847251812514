import styled from "styled-components";
import { ChallengeScheme, UserScheme } from "client-v2";
import { Player } from '@lottiefiles/react-lottie-player';

import { Modal, Text, ColorV2 } from "@adoptaunabuelo/react-components";
import animation from '../../assets/animations/signature.json';
import moment from "moment";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0px;
`
const Line = styled.div`
    border-top: 1px solid ${ColorV2.border.neutralSoft};
    height: 1px;
    width: 240px;
    margin-top: -18px;
`

const SignatureConfirmationModal = (props: Props) =>{

    const isMobile = window.innerWidth <= 768;

    return(
        <Modal
            isVisible={props.isVisible}
            onClose={props.onClose}
            hideClose={true}
            hideHeader={true}
            type={isMobile ? 'full-screen' : 'default'}
        >
            <Container>
                <Text type='p' style={{textAlign: 'center', marginBottom: 32}}>
                    {props.challenge.title}
                </Text>
                <Player
                    style={{height: 120, width: 240}}
                    loop={false}
                    autoplay={true}
                    src={animation}
                    keepLastFrame={true}
                    onEvent={(event) =>{
                        if(event === 'complete'){
                            props.onClose();
                        }
                    }}
                />
                <Line/>
                <Text type='h5' weight="semibold" style={{textAlign: 'center', marginTop: 24}}>
                    {props.user.name} {props.user.surname}
                </Text>
                <Text type='p2' style={{textAlign: 'center'}}>
                    En {props.user.city} a {moment().format('DD/MM/YYYY')}
                </Text>
            </Container>
        </Modal>
    )
}
export default SignatureConfirmationModal;
export interface Props {
    isVisible: boolean,
    challenge: ChallengeScheme,
    user: UserScheme,
    onClose: () => void
}