import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

import { Color, ColorV2, Text } from '@adoptaunabuelo/react-components';

const Container = styled.div`

`
const Cell = styled.div`
    display: flex;
    flex-direction: row;
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 16px;
`
const Line = styled.div`
    height: 100%;
    min-height: 25px;
    border-right: 1px solid ${Color.line.soft};
`

const Timing = (props: Props) =>{

    return(
        <Container
            {...props}
        >
            {props.data.map((item, index) =>{
                return(
                    <Cell
                        key={'timing-'+index}
                    >
                        <Column>
                            <Text type='p' style={{backgroundColor: ColorV2.surface.background, width: 55, borderRadius: 6, display: 'flex', justifyContent: 'center'}}>
                                {item.time}
                            </Text>
                            {index+1 !== props.data.length &&
                                <Line/>
                            }
                        </Column>
                        <Text type='p' style={{marginBottom: 16}}>
                            {item.title}
                        </Text>
                    </Cell>
                )
            })}
        </Container>
    )
}
export default Timing;
export interface Props extends ComponentPropsWithoutRef<"div">{
    data: Array<{
        time: string,
        title: string
    }>
}