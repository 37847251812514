import {
	useState,
	forwardRef,
	useImperativeHandle,
	Ref,
	useEffect,
} from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { ChallengeScheme } from "client-v2";

import { Player } from "@lottiefiles/react-lottie-player";
import {
	Text,
	Color,
	Input,
	Button,
	ColorV2,
} from "@adoptaunabuelo/react-components";
import { Pencil, ArrowUpRight } from "lucide-react";
import AmountModal from "../../components/modal/AmountModal";
import animation from "../../assets/animations/confetti.json";
import SectionContainer from "../container/SectionContainer";
import PaymentBreakdownModal from "../modal/PaymentBreakdownModal";
import SectionProgressBar from "../../components/progress/SectionProgressBar";
import ReliefModal from "../modal/ReliefModal";

const SectionContainerStyled = styled(SectionContainer)`
	${media.lessThan("medium")`
        display: flex;
        flex: 1;
        padding-bottom: 0px;
    `}
`;
const ChallengeContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 48px;
	${media.lessThan("medium")`
        margin-bottom: 0px;
    `}
`;
const DataContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 32px;
	${media.lessThan("medium")`
        margin-bottom: 0px;
        flex: 1;
        justify-content: center;
    `}
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
`;
const Column = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
const PriceView = styled.div`
	display: flex;
	flex-direction: row;
	cursor: pointer;
	align-items: center;
	transform: scale(1);
	transition: transform 0.1s ease-in, background-color 0.1s ease-in;
	padding: 6px 6px 6px 12px;
	&:hover {
		transform: scale(1.1);
	}
`;
const ConfettiView = styled.div<{ hidden?: boolean }>`
	display: flex;
	position: fixed;
	top: 0px;
	right: 0px;
	left: 0px;
	bottom: 0px;
	opacity: ${(props) => (props.hidden ? 0 : 1)};
	transition: opacity 0.3s ease-in;
`;
const IconView = styled.div`
	position: absolute;
	bottom: 20px;
	right: -42px;
	display: flex;
	height: 36px;
	width: 36px;
	margin-left: 28px;
	align-items: center;
	justify-content: center;
	background-color: ${Color.background.deepBlueLow};
	border-radius: 40px;
`;

const PresentsStep = forwardRef(
	(props: PresentsStepProps, ref: Ref<PresentsRef>) => {
		const isMobile = window.screen.width <= 768;
		const [selectedPrice, setSelectedPrice] = useState<any>(15);
		const [showModal, setShowModal] = useState(false);
		const [showConfetti, setShowConfetti] = useState(false);
		const [hideConfetti, setHideConfetti] = useState(true);
		const [showPriceModal, setShowPriceModal] = useState(false);
		const [showReliefModal, setShowReliefModal] = useState(false);
		const [reliefAmount, setReliefAmount] = useState("0");

		useImperativeHandle(ref, () => ({
			getData() {
				if (selectedPrice) {
					return { price: selectedPrice };
				} else {
					return undefined;
				}
			},
		}));

		const delay = (ms: number) =>
			new Promise((resolve) => setTimeout(resolve, ms));

		useEffect(() => {
			getReliefAmount(selectedPrice);
		}, []);

		const getReliefAmount = (price: number) => {
			const totalAmount = price;
			const reliefSection1 = totalAmount > 250 ? 250 : totalAmount;
			const reliefSection2 = totalAmount > 250 ? totalAmount - 250 : 0;
			const relief = reliefSection1 * 0.8 + reliefSection2 * 0.4;
			setReliefAmount(relief.toFixed(2));
		};

		const onPriceChange = (e: any) => {
			const value =
				typeof e === "string" ? parseInt(e) : parseInt(e.target.value);
			if (
				props.challenge &&
				value >= props.challenge.goal - props.challenge.amount
			) {
				setHideConfetti(false);
				delay(300).then(() => {
					setShowConfetti(true);
				});
			} else {
				setShowConfetti(false);
				setHideConfetti(true);
			}
			setSelectedPrice(value);
			getReliefAmount(value);
			props.onPriceChange && props.onPriceChange(value);
		};

		return (
			<SectionContainerStyled>
				<ReliefModal
					isVisible={showReliefModal}
					price={selectedPrice}
					charge={"one"}
					onClose={() => setShowReliefModal(false)}
				/>
				<AmountModal
					isVisible={showModal}
					currencySimbol={"€"}
					onClick={(amount) => onPriceChange(amount)}
					onClose={() => setShowModal(false)}
				/>
				<PaymentBreakdownModal
					isVisible={showPriceModal}
					type={isMobile ? "full-screen" : "default"}
					challenge={props.challenge}
					onClose={() => setShowPriceModal(false)}
				/>
				{!hideConfetti && (
					<ConfettiView hidden={!showConfetti}>
						<Player
							style={{ width: "auto", height: "auto" }}
							loop={true}
							autoplay={true}
							src={animation}
							onEvent={(event) => {
								if (event === "loop") {
									setShowConfetti(false);
									delay(300).then(() => {
										setHideConfetti(true);
									});
								}
							}}
						/>
					</ConfettiView>
				)}
				{props.challenge && (
					<>
						<ChallengeContainer>
							<SectionProgressBar
								options={props.challenge.distribution}
								defaultProgress={props.challenge.amount}
								progress={
									selectedPrice + props.challenge.amount
								}
								animationTime={0.25}
							/>
							<Button
								style={{
									marginTop: 18,
									color: Color.text.high,
									fontSize: 14,
								}}
								design={"call-to-action"}
								icon={
									<ArrowUpRight
										height={18}
										width={18}
										style={{ marginRight: 6 }}
									/>
								}
								onClick={() => setShowPriceModal(true)}
							>
								Cómo se utilizará el dinero del bote
							</Button>
						</ChallengeContainer>
						<DataContainer>
							<Row
								style={{
									justifyContent: "center",
									alignItems: "flex-end",
								}}
							>
								<PriceView onClick={() => setShowModal(true)}>
									<Text
										type="h1"
										style={{
											position: "relative",
											fontFamily: "DM Mono",
											textAlign: "center",
											color: Color.text.primary,
											fontSize: 52,
										}}
										weight={"medium"}
									>
										<Text
											type="h4"
											weight="medium"
											style={{
												position: "absolute",
												top: 8,
												left: -26,
												color: Color.text.primarySoft,
											}}
										>
											€
										</Text>
										{selectedPrice}
									</Text>
									<IconView>
										<Pencil
											color={Color.text.primary}
											height={20}
											width={20}
										/>
									</IconView>
								</PriceView>
							</Row>
							<Column>
								<Text
									type="c2"
									style={{
										textAlign: "center",
										marginTop: 12,
									}}
								>
									{"Te devolverán "}
									<span
										style={{
											backgroundColor:
												ColorV2.surface.secondary,
											padding: "1px 6px",
											borderRadius: 3,
											fontWeight: 500,
										}}
									>
										{reliefAmount.split(".")[0]}
										<span style={{ fontSize: 10 }}>
											{"," + reliefAmount.split(".")[1]} €
										</span>
									</span>
									{" en la declaración de la Renta"}
								</Text>
								<Button
									design={"call-to-action"}
									style={{
										color: ColorV2.text.neutralHard,
										textDecoration: "underline",
										fontSize: 12,
										fontWeight: 400,
										marginTop: 5,
										marginBottom: isMobile ? 8 : 12,
									}}
									onClick={() => setShowReliefModal(true)}
								>
									Más información
								</Button>
							</Column>
							<Input
								style={{ paddingTop: 24 }}
								type="range"
								min={5}
								max={
									props.challenge.goal -
										props.challenge.amount <=
									15
										? 50
										: props.challenge.goal -
										  props.challenge.amount
								}
								value={selectedPrice}
								unit={"€"}
								step={5}
								hideRange={true}
								onChange={onPriceChange}
							/>
						</DataContainer>
					</>
				)}
			</SectionContainerStyled>
		);
	}
);
export default PresentsStep;
export interface PresentsStepProps {
	challenge?: ChallengeScheme;
	onPriceChange?: (amount: number) => void;
}
export interface PresentsRef {
	getData: () =>
		| undefined
		| {
				price: number;
		  };
}
