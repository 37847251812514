import {
	useState,
	forwardRef,
	useImperativeHandle,
	Ref,
	useEffect,
} from "react";
import styled from "styled-components";
import { GrowthBook } from "@growthbook/growthbook-react";
import media from "styled-media-query";
import { ChallengeScheme, CoorporateScheme } from "client-v2";
import moment from "moment";

import SectionContainer from "../container/SectionContainer";
import {
	Text,
	Color,
	Tabs,
	Input,
	ProgressBar,
	ColorV2,
	Button,
} from "@adoptaunabuelo/react-components";
import { Pencil } from "lucide-react";
import AmountModal from "../../components/modal/AmountModal";
import DonationInfo from "../../views/donation/steps/Info";
import ReliefModal from "../modal/ReliefModal";

const SectionContainerStyled = styled(SectionContainer)`
	${media.lessThan("medium")`
        display: flex;
        flex: 1;
        padding-bottom: 0px;
    `}
`;
const Container = styled.div`
	${media.lessThan("medium")`
        display: flex;
        flex-direction: column;
        height: calc(var(--doc-height) - 62px - 72px - 38px);
    `}
`;
const DataContainer = styled.div<{ $center: boolean }>`
	display: flex;
	flex-direction: column;
	${(props) => media.lessThan("medium")`
        flex: 1;
        justify-content: ${props.$center ? "center" : "flex-start"};
    `}
`;
const ChallengeContainer = styled.div`
	display: none;
	flex-direction: column;
	${media.lessThan("medium")`
        display: flex;
    `}
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const AmountView = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
const PriceView = styled.div<{ $disabled: boolean }>`
	display: flex;
	flex-direction: row;
	cursor: ${(props) => !props.$disabled && "pointer"};
	align-items: center;
	transform: scale(1);
	transition: transform 0.1s ease-in, background-color 0.1s ease-in;
	padding: 6px 6px 6px 12px;
	&:hover {
		transform: ${(props) => !props.$disabled && "scale(1.1)"};
	}
`;
const IconView = styled.div`
	position: absolute;
	bottom: 20px;
	right: -42px;
	display: flex;
	height: 36px;
	width: 36px;
	margin-left: 28px;
	align-items: center;
	justify-content: center;
	background-color: ${Color.background.deepBlueLow};
	border-radius: 40px;
`;
const ProgressContainer = styled.div`
	${media.lessThan("medium")`
        display: flex;
        height: 100%;
        justify-content: center;
        flex-direction: column;
    `}
`;

const PlanStep = forwardRef((props: Props, ref: Ref<PriceRef>) => {
	const isMobile = window.screen.width <= 768;
	const timePerEur = 0.602;
	const currencyFormat = new Intl.NumberFormat(props.language, {
		style: "currency",
		currency: props.currency,
		maximumFractionDigits: 0,
	});
	const currencySimbol = currencyFormat
		.formatToParts(1)
		.find((x) => x.type === "currency")?.value;

	const [showReliefModal, setShowReliefModal] = useState(false);
	const [reliefAmount, setReliefAmount] = useState("0");
	const [selectedPrice, setSelectedPrice] = useState<number>(
		props.defaultPrice ? props.defaultPrice : 15
	);
	const [selectedTab, setSelectedTab] = useState<
		{ id: string; title: string } | undefined
	>(undefined);
	const [showModal, setShowModal] = useState(false);
	const [tabOptions, setTabsOptions] = useState<
		Array<{ id: string; title: string }>
	>([
		{
			id: "one",
			title: "Puntual",
		},
	]);

	const titleGrowthbook: string | undefined = props.growthbook
		? props.growthbook.getFeatureValue(
				"donation_letter",
				"Da un paso más y hazte socio"
		  )
		: undefined;

	useImperativeHandle(ref, () => ({
		getData() {
			return selectedPrice && selectedTab
				? {
						price: selectedPrice,
						charge: selectedTab.id,
				  }
				: undefined;
		},
	}));

	useEffect(() => {
		//Scroll to top
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const temp: any = props.options.map((item) => {
			if (item === "monthly")
				return {
					id: "monthly",
					title: "Mensual",
				};
			else if (item === "annually")
				return {
					id: "annually",
					title: "Anual",
				};
			else if (item === "one")
				return {
					id: "one",
					title: "Puntual",
				};
		});
		setTabsOptions(temp);
		setSelectedTab(temp[0]);
		getReliefAmount(selectedPrice, temp[0].id);
	}, [props.options.length]);

	const onTabChange = (option: any) => {
		setSelectedTab(option);
		if (option.id === "annually") {
			setSelectedPrice(150);
			getReliefAmount(150, "annually");
		} else if (option.id === "monthly") {
			setSelectedPrice(15);
			getReliefAmount(15, "monthly");
		} else {
			setSelectedPrice(50);
			getReliefAmount(50, "one");
		}
	};

	const onPriceChange = (e: any) => {
		const value =
			typeof e === "string" ? parseInt(e) : parseInt(e.target.value);
		setSelectedPrice(value);
		props.onPriceChange && props.onPriceChange(value);
		getReliefAmount(value, selectedTab ? selectedTab.id : "monthly");
	};

	const getReliefAmount = (price: number, charge: string) => {
		const totalAmount = charge === "monthly" ? price * 12 : price;
		const reliefSection1 = totalAmount > 250 ? 250 : totalAmount;
		const reliefSection2 = totalAmount > 250 ? totalAmount - 250 : 0;
		const relief = reliefSection1 * 0.8 + reliefSection2 * 0.4;
		setReliefAmount(relief.toFixed(2));
	};

	return (
		<SectionContainerStyled>
			<Container>
				<AmountModal
					isVisible={showModal}
					currencySimbol={currencySimbol}
					onClick={onPriceChange}
					onClose={() => setShowModal(false)}
				/>
				<ReliefModal
					isVisible={showReliefModal}
					price={selectedPrice}
					charge={selectedTab ? selectedTab.id : "monthly"}
					onClose={() => setShowReliefModal(false)}
				/>
				{props.challenge &&
					!props.completed &&
					props.challenge.type !== "signature" && (
						<ChallengeContainer>
							<ProgressBar
								style={{
									height: 8,
									marginBottom: 12,
									flex: "none",
								}}
								progress={[
									{
										value:
											(props.challenge.amount /
												props.challenge.goal) *
											100,
										color:
											props.challenge.type === "rose"
												? ColorV2.surface.redMedium
												: Color.text.deepBlue,
									},
									{
										value:
											(selectedPrice /
												props.challenge.goal) *
											100,
										color:
											props.challenge.type === "rose"
												? ColorV2.surface.redMedium
												: Color.text.primary,
									},
								]}
							/>
							<Row style={{ justifyContent: "space-between" }}>
								<Text
									type="p2"
									style={{ color: Color.text.high }}
								>
									<Text
										type="h6"
										style={{
											color:
												props.challenge.type === "rose"
													? ColorV2.surface.redMedium
													: Color.text.deepBlue,
										}}
										weight="medium"
									>
										{props.challenge.amount} €
									</Text>
									de {props.challenge.goal} €
								</Text>
								<Text
									type="p2"
									style={{ color: Color.text.high }}
								>
									<Text type="h6" weight="medium">
										{props.challenge.supporters}
									</Text>
									colaboradores
								</Text>
								<Text
									type="p2"
									style={{ color: Color.text.high }}
								>
									<Text type="h6" weight="medium">
										{moment(
											props.challenge.endDate.iso
										).diff(moment(), "days")}{" "}
										días
									</Text>
									restantes
								</Text>
							</Row>
						</ChallengeContainer>
					)}
				<DataContainer
					$center={
						props.challenge
							? props.completed
								? false
								: true
							: false
					}
				>
					{!props.hideTitle && (
						<Text
							type="h3"
							weight="semibold"
							style={{ textAlign: "center", marginBottom: 12 }}
						>
							{titleGrowthbook
								? titleGrowthbook
								: props.title
								? props.title
								: "Hazte socio"}
						</Text>
					)}
					{!props.hideSubtitle && (
						<Text
							type="p"
							style={{ marginBottom: 48, textAlign: "center" }}
						>
							Regala{" "}
							<strong>
								{(timePerEur * selectedPrice).toFixed(0)} horas
							</strong>{" "}
							de compañía{" "}
							{selectedTab?.id === "annually"
								? "al año"
								: selectedTab?.id === "monthly"
								? "al mes"
								: ""}
						</Text>
					)}
					<ProgressContainer>
						<AmountView>
							<Row
								style={{
									justifyContent: "center",
									alignItems: "flex-end",
								}}
							>
								<PriceView
									$disabled={
										selectedTab?.id === "one" ? false : true
									}
									onClick={() =>
										selectedTab?.id === "one" &&
										setShowModal(true)
									}
								>
									<Text
										type="h1"
										style={{
											position: "relative",
											fontFamily: "DM Mono",
											textAlign: "center",
											color:
												props.coorporate &&
												props.coorporate.color
													? props.coorporate.color
															.main
													: props.challenge?.type ===
													  "rose"
													? ColorV2.surface.redMedium
													: Color.text.primary,
											fontSize: 52,
										}}
										weight={"medium"}
									>
										<Text
											type="h4"
											weight="medium"
											style={{
												position: "absolute",
												top: 8,
												left: -26,
												color:
													props.coorporate &&
													props.coorporate.color
														? Color.text.medium
														: props.challenge
																?.type ===
														  "rose"
														? ColorV2.surface
																.redMedium
														: Color.text
																.primarySoft,
											}}
										>
											{currencySimbol}
										</Text>
										{selectedPrice}
									</Text>
									{selectedTab?.id === "one" && (
										<IconView>
											<Pencil
												color={
													props.coorporate &&
													props.coorporate.color
														? props.coorporate.color
																.main
														: Color.text.primary
												}
												height={20}
												width={20}
											/>
										</IconView>
									)}
								</PriceView>
							</Row>
							<Text
								type="c2"
								style={{ textAlign: "center", marginTop: 12 }}
							>
								{"Te devolverán "}
								<span
									style={{
										backgroundColor:
											ColorV2.surface.secondary,
										padding: "1px 6px",
										borderRadius: 3,
										fontWeight: 500,
									}}
								>
									{reliefAmount.split(".")[0]}
									<span style={{ fontSize: 10 }}>
										{"," + reliefAmount.split(".")[1]} €
									</span>
								</span>
								{" en la declaración de la Renta"}
							</Text>
							<Button
								design={"call-to-action"}
								style={{
									color: ColorV2.text.neutralHard,
									textDecoration: "underline",
									fontSize: 12,
									fontWeight: 400,
									marginTop: 5,
									marginBottom: isMobile ? 12 : 32,
								}}
								onClick={() => setShowReliefModal(true)}
							>
								Más información
							</Button>
						</AmountView>
						<Input
							type="range"
							style={{ paddingTop: 12 }}
							min={
								props.minPrice
									? props.minPrice
									: selectedTab?.id === "annually"
									? 50
									: selectedTab?.id === "monthly"
									? 5
									: 5
							}
							max={
								props.maxPrice
									? props.maxPrice
									: selectedTab?.id === "annually"
									? 200
									: selectedTab?.id === "monthly"
									? 30
									: 200
							}
							value={selectedPrice}
							unit={currencySimbol}
							step={
								selectedTab?.id === "annually"
									? 10
									: selectedTab?.id === "monthly"
									? 1
									: 5
							}
							backgroundColor="white"
							lineColor={
								props.coorporate && props.coorporate.color
									? props.coorporate.color.main
									: props.challenge?.type === "rose"
									? ColorV2.surface.redMedium
									: ColorV2.surface.primary
							}
							thumbColor={
								props.coorporate && props.coorporate.color
									? props.coorporate.color.main
									: props.challenge?.type === "rose"
									? ColorV2.surface.redMedium
									: ColorV2.surface.primary
							}
							hideRange={true}
							onChange={onPriceChange}
						/>
					</ProgressContainer>
					{props.options.length > 1 && (
						<Tabs
							design="secondary"
							style={{
								width: "100%",
								justifyContent: "space-evenly",
								margin: "24px 0px",
							}}
							cellStyle={{ maxWidth: 90, padding: "8px 24px" }}
							options={tabOptions}
							selectedOption={selectedTab}
							textColor={Color.text.full}
							cellColor={"white"}
							onChange={(option) => onTabChange(option)}
						/>
					)}
					{selectedTab?.id !== "one" && (
						<Text
							type="c1"
							style={{
								textAlign: "center",
								color: Color.text.high,
								minHeight: 44,
							}}
						>
							{selectedTab?.id === "one"
								? ""
								: "Tu donación se renovará automaticamente cada " +
								  (selectedTab?.id === "annually"
										? "año"
										: "mes") +
								  ". Puedes cancelarla en cualquier momento."}
						</Text>
					)}
				</DataContainer>
			</Container>
			{props.showInfo && isMobile && (
				<DonationInfo style={{ margin: "0px -24px -24px" }} />
			)}
		</SectionContainerStyled>
	);
});
export default PlanStep;
export interface Props {
	growthbook?: GrowthBook;
	title?: string;
	hideTitle?: boolean;
	hideSubtitle?: boolean;
	currency: string;
	language: string;
	options: Array<"monthly" | "annually" | "one">;
	minPrice?: number;
	maxPrice?: number;
	defaultPrice?: number;
	challenge?: ChallengeScheme;
	completed?: boolean;
	showInfo?: boolean;
	coorporate?: CoorporateScheme;
	onPriceChange?: (amount: number) => void;
}
export interface PriceRef {
	getData: () =>
		| undefined
		| {
				price: number;
				charge: string;
		  };
}
