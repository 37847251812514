import { PaymentMethod, PaymentMethodScheme, Track, Product, UserScheme, Payment, Subscription, User, ChallengeScheme, Challenge } from "client-v2";
import ReactPixel from 'react-facebook-pixel';
import { PaymentRequestPaymentMethodEvent } from "@stripe/stripe-js";
import Stripe from "stripe";
import moment from "moment";

const getPaycards = async (userId: string, charge: string): Promise<{
    paymentMethods: Array<PaymentMethodScheme>,
    defaultPaymentMethod: PaymentMethodScheme | undefined
}> =>{
    const result = await PaymentMethod.get({
        userId: userId,
        types: charge === 'one' ? ['paycard'] : ['sepa_debit'],
        doesNotExist: charge === 'one' ? undefined : ['stripeId'],
    });
    let paymentMethod = undefined;
    result.data.map((item) =>{
        if(item.default){
            paymentMethod = item;
        }
    });
    if(!paymentMethod && result.data.length > 0)
        paymentMethod = result.data[0];
    return {
        defaultPaymentMethod: paymentMethod,
        paymentMethods: result.data
    }
}

const getProduct = async (data: {
    user: UserScheme, 
    price: number, 
    charge: string,
    view: string
}): Promise<{
    paymentMethods: Array<PaymentMethodScheme>,
    defaultPaymentMethod: PaymentMethodScheme | undefined,
    product: {
        objectId?: string,
        price: number,
        charge: string
    }
}> =>{
    if(data.charge === 'one'){
        //Track on mixpanel
        Track.track('donation product selected',{
            'Product': 'one-off',
            'amount': data.price,
            'view': data.view
        });

        const result = await getPaycards(data.user.objectId, data.charge);
        return {
            ...result,
            product: {price: data.price, charge: data.charge}
        }
    }
    else{
        const productResult = await Product.get({
            price: data.price,
            charge: data.charge,
            active: true
        });

        //Track on mixpanel
        const product = productResult.data[0];
        Track.track('donation product selected',{
            'Product': product.objectId,
            'amount': data.price,
            'view': data.view
        });

        const result = await getPaycards(data.user.objectId, data.charge);
        return {
            ...result,
            product: {
                objectId: product.objectId,
                price: product.price, 
                charge: product.charge
            }
        }
    }
}

const initSubscription = async (data: {
    user: UserScheme, 
    product: any, 
    currency: string,
    paymentMethod: any, 
    paymentRequestEvent?: PaymentRequestPaymentMethodEvent,
    view: string
}): Promise<{
    result?: Stripe.PaymentIntent,
    error?: string
}> =>{
    if(data.product.charge === 'one'){
        //Track on mixpanel
        Track.track('donation payment intent',{
            'Product': 'one-off',
            'amount': data.product.price,
            'view': data.view,
            'Paycard': data.paymentMethod.brand
        });

        try{
            const result = await Payment.initPaymentIntent({
                userId: data.user.objectId,
                amount: data.product.price,
                currency: data.currency,
                redirectUrl: window.location.origin + "/card-success",
                paymentMethod: data.paymentMethod.id,
            });
            //Check if 3d secure needed
            if(result.data.status === 'processing' || result.data.status === 'succeeded'){
                return onPaymentIntentConfirmed({
                    user: data.user,
                    product: data.product,
                    paymentRequestEvent: data.paymentRequestEvent,
                    view: data.view
                });
            }
            else{
                return {
                    result: result.data
                }
            }
        }
        catch(e: any){
            return onPaymentIntentConfirmed({
                user: data.user,
                product: data.product,
                error: e.message,
                view: data.view
            });
        }
    }
    else{
        //Track on Facebook
        if(data.product.charge === 'annually'){
            ReactPixel.track('fb_mobile_level_achieved');
        }
        else{
            ReactPixel.track('fb_mobile_add_payment_info');
        }

        //Track on mixpanel
        Track.track('donation payment intent',{
            'Product': data.product.objectId,
            'amount': data.product.price,
            'view': data.view,
        });

        try{
            const initDate = moment().add(1, 'month').startOf('month');
            await Subscription.create({
                userId: data.user.objectId,
                productId: data.product.objectId,
                paymentMethod: data.paymentMethod.id,
                currency: data.currency,
                initDate: initDate.toDate()
            });
            return onPaymentIntentConfirmed({
                user: data.user,
                product: data.product,
                view: data.view
            });
        }
        catch(e: any){
            return onPaymentIntentConfirmed({
                user: data.user,
                product: data.product,
                error: e.message,
                view: data.view
            });
        }
    }
}

const onPaymentIntentConfirmed = (data: {
    user: UserScheme,
    product: any, 
    error?: string, 
    paymentRequestEvent?: PaymentRequestPaymentMethodEvent,
    view: string
}):{
    result?: any,
    error?: string
} =>{
    if(data.error){
        //Track on mixpanel
        Track.track('donation activation failed',{
            'Product': data.product.charge === 'one' ? 'one-off' : data.product.objectId,
            'amount': data.product.price,
            'view': data.view,
            'error': data.error
        });

        if(data.paymentRequestEvent)
            data.paymentRequestEvent.complete('fail');
        return {
            error: data.error
        }
    }
    else{
        //Track on Facebook
        if(data.product.charge === 'annually'){
            ReactPixel.track('fb_mobile_purchase');
        }
        else{
            ReactPixel.track('fb_mobile_add_to_cart');
        }

        //Track on mixpanel
        Track.track('donation activation done',{
            'Product': data.product.charge === 'one' ? 'one-off' : data.product.objectId,
            'amount': data.product.price,
            'view': data.view,
        });
        Track.charge(data.product.price);

        if(data.paymentRequestEvent)
            data.paymentRequestEvent.complete('success');

        //Update user
        User.set(data.user.objectId, {
            internalData: {
                tags: 'payment-donation'
            }
        });

        return {
            result: undefined
        }
    }
}

const registerToChallenge = async (data:{
    user: UserScheme, 
    challenge: ChallengeScheme, 
    amount: number, 
    paymentMethod: string, 
    paymentRequestEvent?: PaymentRequestPaymentMethodEvent,
    view: string
}): Promise<{
    result?: Stripe.PaymentIntent,
    error?: string
}> =>{
    Track.track('donation payment intent',{
        'Product': 'one-off',
        'amount': data.amount,
        'view': 'birthday'
    });

    try{
        const result = await Challenge.initPaymentIntent(data.challenge.objectId, {
            userId: data.user.objectId,
            amount: data.amount,
            paymentMethod: data.paymentMethod,
            redirectUrl: window.location.origin + "/card-success"
        })
        //Check if 3d secure needed
        if(result.data.status === 'processing' || result.data.status === 'succeeded'){
            return onPaymentIntentConfirmed({
                user: data.user,
                product: {
                    charge: 'one',
                    price: data.amount
                },
                paymentRequestEvent: data.paymentRequestEvent,
                view: data.view
            });
        }
        else{
            return {
                result: result.data
            }
        }
    }
    catch(e: any){
        return onPaymentIntentConfirmed({
            user: data.user,
            product: {
                charge: 'one',
                price: data.amount
            },
            error: e.message,
            view: data.view
        });
    }
}

export default {
    getPaycards,
    getProduct,
    initSubscription,
    registerToChallenge
}