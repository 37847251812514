import { Constant } from "client-v2";
import styled from "styled-components";
import { ColorV2, Text } from "@adoptaunabuelo/react-components";
import { useEffect, useState } from "react";
import media from "styled-media-query";
import HobbiesStamp from "../../../components/stamp/HobbiesStamp";
import { useSelector } from "react-redux";
import { State } from "../../../redux";

const screen01: any = "1350px";
const screen02: any = "1050px";
const screen03: any = "950px";
const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 286px;
	padding-top: 32px;
	margin-left: 38px;
	${media.lessThan(screen01)`
		width: 250px;
		margin-left: 32px;
    `}
	${media.lessThan(screen02)`
        width: 220px;
		margin-left: 16px;
    `}
	${media.lessThan(screen03)`
        display: none;
    `}
`;
const HobbiesContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const LetterRight = (props: LetterRightProps) => {
	const translation = useSelector(
		(state: State) => state.location.translation
	);
	const appLocation = useSelector((state: State) => state.location.location);
	const [hobbies, setHobbies] = useState<any[]>([]);

	useEffect(() => {
		if (props.hobbies && props.hobbies.length > 0) {
			const hobbiesConstants = Constant.hobbies();
			const temp = props.hobbies
				.map((item) => {
					const result = hobbiesConstants.filter(
						(i) => i.id === item
					);
					if (result.length > 0) {
						return {
							title:
								appLocation?.language === "en"
									? result[0].label_en
									: result[0].label,
							icon: result[0].icon,
							backgroundColor: result[0].backgroundColor,
						};
					}
				})
				.filter((i) => i);
			setHobbies(temp);
		}
	}, [props.hobbies]);
	return (
		<Container>
			<Text
				type="p2"
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					height: 48,
					color: ColorV2.text.neutralMedium,
				}}
			>
				{translation.letter_stamp_title}
			</Text>
			<HobbiesContainer>
				{hobbies.length >= 1 && (
					<HobbiesStamp
						style={{ margin: "32px 0px 0px 38px", zIndex: 2 }}
						icon={hobbies[0].icon}
						title={hobbies[0].title}
						backgroundColor={hobbies[0].backgroundColor}
					/>
				)}
				{hobbies.length >= 2 && (
					<HobbiesStamp
						style={{ margin: "-55px 0px 0px 45%" }}
						icon={hobbies[1].icon}
						title={hobbies[1].title}
						backgroundColor={hobbies[1].backgroundColor}
					/>
				)}
				{hobbies.length >= 3 && (
					<HobbiesStamp
						style={{ margin: "-20px 0px 0px 0px" }}
						icon={hobbies[2].icon}
						title={hobbies[2].title}
						backgroundColor={hobbies[2].backgroundColor}
					/>
				)}
				{hobbies.length >= 4 && (
					<HobbiesStamp
						style={{ margin: "-75px 0px 0px 50%", zIndex: 2 }}
						icon={hobbies[3].icon}
						title={hobbies[3].title}
						backgroundColor={hobbies[3].backgroundColor}
					/>
				)}
				{hobbies.length >= 5 && (
					<HobbiesStamp
						style={{ margin: "-20px 0px 0px 20%" }}
						icon={hobbies[4].icon}
						title={hobbies[4].title}
						backgroundColor={hobbies[4].backgroundColor}
					/>
				)}
			</HobbiesContainer>
		</Container>
	);
};
export default LetterRight;
export interface LetterRightProps {
	hobbies?: string[];
}
