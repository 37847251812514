import { useState, useCallback, useEffect, CSSProperties } from "react";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Button } from "@adoptaunabuelo/react-components";

const ReCaptchaButton = (props: Props) => {
	const { executeRecaptcha } = useGoogleReCaptcha();
	const { onError, ...restProps } = props;

	const [loading, setLoading] = useState(props.loading);

	useEffect(() => {
		setLoading(props.loading);
	}, [props.loading]);

	const onClick = (e: any) => {
		if (!executeRecaptcha) {
			props.onError &&
				props.onError("Parece que reCaptcha aun no está cargado.");
			return;
		}
		setLoading(true);
		executeRecaptcha(props.action)
			.then((token: string) => {
				props.onClick && props.onClick(token);
				setLoading(false);
				return;
			})
			.catch((error: Error) => {
				props.onError && props.onError(error.message);
				setLoading(false);
				return;
			});
	};

	return <Button {...restProps} loading={loading} onClick={onClick} />;
};
export default ReCaptchaButton;
export interface Props {
	style?: CSSProperties;
	loading?: boolean;
	children: React.ReactNode;
	action: string;
	onClick?: (token: string) => void;
	onError?: (error: string) => void;
}
