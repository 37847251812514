import styled from "styled-components";
import { ProductScheme } from "client-v2";

import { Color, Text } from "@adoptaunabuelo/react-components";
import SectionContainer from "../../../components/container/SectionContainer";

const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const CellSeparator = styled.div`
	display: flex;
	flex: 1;
	border-bottom: 1px solid ${Color.line.soft};
	margin: 0px 8px 4px;
`;

const Ticket = (props: Props) => {
	return (
		<SectionContainer>
			<Row>
				<Text type="p2" style={{ color: Color.text.high }}>
					x1 Entrada
				</Text>
				<CellSeparator />
				<Text type="p2" style={{ color: Color.text.high }}>
					{props.product.price.toFixed(2)} €
				</Text>
			</Row>
			<Row style={{ marginTop: 8 }}>
				<Text type="p2" style={{ color: Color.text.high }}>
					Impuestos
				</Text>
				<CellSeparator />
				<Text type="p2" style={{ color: Color.text.high }}>
					0.00 €
				</Text>
			</Row>
			<Row style={{ marginTop: 18 }}>
				<Text type="h6" weight="medium">
					Total
				</Text>
				<CellSeparator style={{ border: "none" }} />
				<Text type="h5" weight="medium">
					{props.product.price !== 0
						? props.product.price.toFixed(2) + " €"
						: "Gratis"}
				</Text>
			</Row>
		</SectionContainer>
	);
};
export default Ticket;
export interface Props {
	product: ProductScheme;
}
