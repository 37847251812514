import { useState, useEffect } from "react";
import styled from "styled-components";
import { GrowthBook } from "@growthbook/growthbook-react";
import { ErrorScheme, Product, ProductScheme, Track } from "client-v2";
import { useSelector } from "react-redux";
import { State } from "../../../redux";

import SectionContainer from "./SectionContainer";
import {
	Text,
	Color,
	Tabs,
	Input,
	ColorV2,
	Button,
} from "@adoptaunabuelo/react-components";
import { ChevronDown, Gift } from "lucide-react";
import AmountModal from "../../../components/modal/AmountModal";
import ReliefModal from "../../../components/modal/ReliefModal";
import PresentModal from "./PresentModal";
import media from "styled-media-query";

const isScreen01: any = "1350px";
const isScreen02: any = "1050px";
const SectionContainerStyled = styled(SectionContainer)`
	width: 489px;
	margin-right: 8%;
	${media.lessThan(isScreen01)`
        width: 425px;
        margin-right: 6%;
    `}
	${media.lessThan(isScreen02)`
        width: 375px;
        margin-right: 4%;
    `}
	${media.lessThan("medium")`
		width: unset;
		margin-right: 0px;
		min-height: calc(var(--doc-height) - 56px - 8px - 16px);
	`}
`;
const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;
const DataContainer = styled.div<{ $center: boolean }>`
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: ${(props) => (props.$center ? "center" : "flex-start")};
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const AmountView = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	${media.lessThan("medium")`
		flex: 1;
		justify-content: center;
	`}
`;
const PriceView = styled.div<{ $disabled: boolean }>`
	display: flex;
	flex-direction: row;
	cursor: ${(props) => !props.$disabled && "pointer"};
	align-items: center;
	transform: scale(1);
	transition: transform 0.1s ease-in, background-color 0.1s ease-in;
	&:hover {
		transform: ${(props) => !props.$disabled && "scale(1.1)"};
	}
`;
const IconView = styled.div`
	position: absolute;
	bottom: 0px;
	right: -30px;
`;
const ProgressContainer = styled.div`
	display: flex;
	height: 100%;
	justify-content: center;
	flex-direction: column;
	${media.lessThan("medium")`
		margin-bottom: 24px;
	`}
`;
const SkipButtonContainer = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: flex-start;
	height: 100%;
`;
const InputContainer = styled.div`
	background-color: white;
	border-radius: 12px;
	padding: 16px;
	margin-top: 52px;
	${media.lessThan("medium")`
		margin-top: 0px;
	`}
`;
const PriceToast = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	left: 0px;
	right: 0px;
	top: -72px;
`;
const PriceToastTriangle = styled.div`
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 14px solid ${ColorV2.surface.primary};
	margin-top: -4px;
`;
const RelativeView = styled.div`
	position: relative;
`;

const PlanStep = (props: Props) => {
	const isMobile = useSelector((state: State) => state.screen.isMobile);
	const appLocation = useSelector((state: State) => state.location.location);
	const translation = useSelector(
		(state: State) => state.location.translation
	);

	const [loading, setLoading] = useState(false);
	const [showReliefModal, setShowReliefModal] = useState(false);
	const [reliefAmount, setReliefAmount] = useState("0");
	const [selectedPrice, setSelectedPrice] = useState<number>(15);
	const [selectedTab, setSelectedTab] = useState<
		{ id: string; title: string } | undefined
	>(undefined);
	const [showAmountModal, setShowAmountModal] = useState(false);
	const [showPresentModal, setShowPresentModal] = useState(false);
	const [tabOptions, setTabsOptions] = useState<
		Array<{ id: string; title: string }>
	>([
		{
			id: "unique",
			title: "Puntual",
		},
	]);

	const defaultGrowthbook = {
		title: "Gracias por tu carta. Da un paso más y ayúdanos a seguir con nuestra misión.",
		title_en:
			"Thank you for your letter. Take it one step further and help us continue with our mission.",
		subtitle:
			"Estás regalando {{data}} horas de compañía a una persona mayor",
		subtitle_en:
			"You are giving away {{data}} hours of company to a grandpa",
		type_to_eur: 0.602,
	};
	const growthbook = props.growthbook
		? props.growthbook.getFeatureValue(
				"letter_donation_2024",
				defaultGrowthbook
		  )
		: defaultGrowthbook;

	useEffect(() => {
		//Scroll to top
		//window.scrollTo(0, 0);

		//Track to Mixpanel
		Track.track("donation progress init", {
			view: props.view,
		});

		//Set initial price
		setSelectedPrice(
			appLocation ? appLocation.currency.price.monthly.default : 20
		);
	}, []);

	const onNextClick = () => {
		if (selectedTab?.id === "unique") {
			//Track to Mixpanel
			Track.track("donation product selected", {
				Product: "one-off",
				amount: selectedPrice,
				view: props.view,
			});

			props.onFinish({
				data: {
					objectId: "unique",
					charge: "unique",
					price: selectedPrice,
				} as ProductScheme,
			});
		} else {
			setLoading(true);
			Product.get({
				price: selectedPrice,
				charge: selectedTab?.id,
				active: true,
				currency: appLocation ? appLocation.currency.name : "eur"
			})
				.then((result) => {
					if (result.data && result.data.length > 0) {
						//Track to Mixpanel
						const product = result.data[0];
						Track.track("donation product selected", {
							Product: product.objectId,
							amount: selectedPrice,
							view: props.view,
						});

						setLoading(false);
						props.onFinish({ data: product });
					} else {
						setLoading(false);
						props.onFinish({
							error: translation.payment_logic_product_error,
						});
					}
				})
				.catch((e: ErrorScheme) => {
					setLoading(false);
					props.onFinish({ error: e.message });
				});
		}
	};

	useEffect(() => {
		const temp: any = props.options.map((item) => {
			if (item === "monthly")
				return {
					id: "monthly",
					title:
						appLocation?.language === "en" ? "Monthly" : "Mensual",
				};
			else if (item === "annually")
				return {
					id: "annually",
					title:
						appLocation?.language === "en" ? "Annually" : "Anual",
				};
			else if (item === "unique")
				return {
					id: "unique",
					title:
						appLocation?.language === "en" ? "Unique" : "Puntual",
				};
		});
		setTabsOptions(temp);
		setSelectedTab(temp[0]);
		getReliefAmount(selectedPrice, temp[0].id);
	}, [props.options.length]);

	const onTabChange = (option: any) => {
		setSelectedTab(option);
		if (option.id === "annually") {
			const defaultPrice = appLocation
				? appLocation.currency.price.annually.default
				: 120;
			setSelectedPrice(defaultPrice);
			getReliefAmount(defaultPrice, "annually");
		} else if (option.id === "monthly") {
			const defaultPrice = appLocation
				? appLocation.currency.price.monthly.default
				: 15;
			setSelectedPrice(defaultPrice);
			getReliefAmount(defaultPrice, "monthly");
		} else {
			const defaultPrice = appLocation
				? appLocation.currency.price.one.default
				: 50;
			setSelectedPrice(defaultPrice);
			getReliefAmount(defaultPrice, "unique");
		}
	};

	const onPriceChange = (e: any) => {
		const value =
			typeof e === "string" ? parseInt(e) : parseInt(e.target.value);
		setSelectedPrice(value);
		getReliefAmount(value, selectedTab ? selectedTab.id : "monthly");
	};

	const getReliefAmount = (price: number, charge: string) => {
		const totalAmount = charge === "monthly" ? price * 12 : price;
		const reliefSection1 = totalAmount > 250 ? 250 : totalAmount;
		const reliefSection2 = totalAmount > 250 ? totalAmount - 250 : 0;
		const relief = reliefSection1 * 0.8 + reliefSection2 * 0.4;
		setReliefAmount(relief.toFixed(2));
	};

	return (
		<SectionContainerStyled
			animated={props.animated}
			childrenProps={{ style: { flex: 1 } }}
			buttonProps={{
				marginBottom: isMobile ? "0px" : "40px",
				sticky: true,
				LeftView:
					isMobile || !props.onSkipClick ? undefined : (
						<SkipButtonContainer>
							<Button
								design={"call-to-action"}
								loading={loading}
								style={{ color: ColorV2.text.neutralHard }}
								onClick={props.onSkipClick}
							>
								{
									translation.component_modal_skip_payment_button_skip
								}
							</Button>
						</SkipButtonContainer>
					),
				style: {
					backgroundColor: ColorV2.surface.primaryHard,
					width: isMobile ? "100%" : "fit-content",
				},
				loading: loading,
				children: translation.component_button_next,
				onClick: onNextClick,
			}}
		>
			<Container>
				<AmountModal
					isVisible={showAmountModal}
					currencySimbol={
						appLocation ? appLocation.currency.symbol : "€"
					}
					onClick={onPriceChange}
					onClose={() => setShowAmountModal(false)}
				/>
				<ReliefModal
					isVisible={showReliefModal}
					price={selectedPrice}
					charge={selectedTab ? selectedTab.id : "monthly"}
					onClose={() => setShowReliefModal(false)}
				/>
				<PresentModal
					isVisible={showPresentModal}
					onClick={() => {
						setShowPresentModal(false);
						if (selectedTab?.id === "monthly")
							setSelectedPrice(
								appLocation
									? appLocation.currency.price.monthly.gift
									: 20
							);
						else if (selectedTab?.id === "annually")
							setSelectedPrice(
								appLocation
									? appLocation.currency.price.annually.gift
									: 200
							);
					}}
					onClose={() => setShowPresentModal(false)}
				/>
				<DataContainer $center={false}>
					<Text
						type="h3"
						weight="semibold"
						style={{ textAlign: "center", marginBottom: 12 }}
					>
						{appLocation?.language === "en"
							? growthbook.title_en
							: growthbook.title}
					</Text>
					<ProgressContainer>
						<AmountView>
							<RelativeView>
								{selectedTab?.id === "unique" &&
									selectedPrice ===
										(appLocation
											? appLocation.currency.price.one.max
											: 500) && (
										<PriceToast>
											<Text
												type="p2"
												style={{
													color: "white",
													textAlign: "center",
													borderRadius: 12,
													padding: "8px 16px",
													backgroundColor:
														ColorV2.surface.primary,
													border:
														"4px solid " +
														ColorV2.surface
															.background,
												}}
											>
												{
													translation.payment_unique_max_amount
												}
											</Text>
											<PriceToastTriangle />
										</PriceToast>
									)}
								<Row
									style={{
										justifyContent: "center",
										alignItems: "flex-end",
									}}
								>
									<PriceView
										$disabled={
											selectedTab?.id === "unique"
												? false
												: true
										}
										onClick={() =>
											selectedTab?.id === "unique" &&
											setShowAmountModal(true)
										}
									>
										<Text
											type="d1"
											style={{
												position: "relative",
												fontFamily: "DM Mono",
												textAlign: "center",
												color: Color.text.primary,
											}}
											weight={"semibold"}
										>
											<Text
												type="h4"
												weight="medium"
												style={{
													position: "absolute",
													top: 6,
													right: -75,
													color: Color.text.primary,
													textAlign: "left",
													width: 66,
												}}
											>
												{appLocation
													? appLocation.currency
															.symbol
													: "€"}
											</Text>
											{selectedPrice}
										</Text>
										{selectedTab?.id === "unique" && (
											<IconView>
												<ChevronDown
													color={
														ColorV2.text
															.neutralMedium
													}
													height={24}
													width={24}
												/>
											</IconView>
										)}
									</PriceView>
								</Row>
								<Text
									type="p"
									style={{
										textAlign: "center",
										marginTop: 12,
									}}
								>
									{(appLocation?.language === "en"
										? growthbook.subtitle_en
										: growthbook.subtitle
									).replace(
										"{{data}}",
										(
											growthbook.type_to_eur *
											(appLocation
												? appLocation.currency
														.conversion
												: 1) *
											selectedPrice
										).toFixed(0)
									)}
								</Text>
							</RelativeView>
						</AmountView>
						<InputContainer>
							<Input
								type="range"
								min={
									selectedTab?.id === "annually"
										? appLocation
											? appLocation.currency.price
													.annually.min
											: 50
										: selectedTab?.id === "monthly"
										? appLocation
											? appLocation.currency.price.monthly
													.min
											: 5
										: appLocation
										? appLocation.currency.price.one.min
										: 5
								}
								max={
									selectedTab?.id === "annually"
										? appLocation
											? appLocation.currency.price
													.annually.max
											: 250
										: selectedTab?.id === "monthly"
										? appLocation
											? appLocation.currency.price.monthly
													.max
											: 40
										: appLocation
										? appLocation.currency.price.one.max
										: 500
								}
								value={selectedPrice}
								unit={
									appLocation
										? appLocation.currency.symbol
										: "€"
								}
								step={
									selectedTab?.id === "annually"
										? appLocation
											? appLocation.currency.price
													.annually.jump
											: 10
										: selectedTab?.id === "monthly"
										? appLocation
											? appLocation.currency.price.monthly
													.jump
											: 1
										: appLocation
										? appLocation.currency.price.one.jump
										: 5
								}
								presents={
									selectedTab?.id === "annually"
										? [
												{
													value: appLocation
														? appLocation.currency
																.price.annually
																.gift
														: 200,
													icon: (props: any) => (
														<Gift {...props} />
													),
													color: ColorV2.surface
														.redSoft,
													colorSuccess:
														ColorV2.surface
															.redMedium,
													onClick: () =>
														setShowPresentModal(
															true
														),
												},
										  ]
										: selectedTab?.id === "monthly"
										? [
												{
													value: appLocation
														? appLocation.currency
																.price.monthly
																.gift
														: 20,
													icon: (props: any) => (
														<Gift {...props} />
													),
													color: ColorV2.surface
														.redSoft,
													colorSuccess:
														ColorV2.surface
															.redMedium,
													onClick: () =>
														setShowPresentModal(
															true
														),
												},
										  ]
										: []
								}
								backgroundColor={ColorV2.border.neutralSoft}
								lineColor={ColorV2.surface.primary}
								thumbColor={ColorV2.surface.primary}
								hideRange={true}
								hideLabels={true}
								onChange={onPriceChange}
							/>
							{props.options.length > 1 && (
								<Tabs
									design="secondary"
									style={{
										width: "100%",
										justifyContent: "center",
										marginTop: 18,
									}}
									cellStyle={{
										maxWidth: 90,
									}}
									options={tabOptions}
									selectedOption={selectedTab}
									textColor={ColorV2.text.primary}
									cellColor={ColorV2.surface.neutralSoft}
									onChange={(option) => onTabChange(option)}
								/>
							)}
						</InputContainer>
						{appLocation &&
							appLocation.country_code === "ES" &&
							appLocation.currency.name === "EUR" && (
								<Text
									type="c2"
									style={{
										textAlign: "center",
										marginTop: 16,
									}}
								>
									{translation.payment_renta_01}
									<span
										style={{
											backgroundColor:
												ColorV2.surface.secondary,
											padding: "1px 6px",
											borderRadius: 3,
											fontWeight: 500,
										}}
									>
										{reliefAmount.split(".")[0]}
										<span style={{ fontSize: 10 }}>
											{"," + reliefAmount.split(".")[1]} €
										</span>
									</span>
									{translation.payment_renta_02}{" "}
									<span
										style={{
											color: ColorV2.text.neutralHard,
											textDecoration: "underline",
											fontSize: 12,
											fontWeight: 400,
											cursor: "pointer",
										}}
										onClick={() => setShowReliefModal(true)}
									>
										{translation.component_text_more_info}
									</span>
								</Text>
							)}
					</ProgressContainer>
				</DataContainer>
			</Container>
		</SectionContainerStyled>
	);
};
export default PlanStep;
export interface Props {
	growthbook?: GrowthBook;
	animated?: boolean;
	options: Array<"monthly" | "annually" | "unique">;
	view: string;
	onFinish: (result: { data?: ProductScheme; error?: string }) => void;
	onSkipClick?: () => void;
}
