import { useState, useRef, useEffect } from "react";
import {
	PaymentMethod,
	ProductScheme,
	UserScheme,
	PaymentMethodScheme,
} from "client-v2";
import { PaymentRequestPaymentMethodEvent } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";

import Wizard from "../../components/navigation/Wizard";
import Paycards, { PaycardsRef } from "../../components/section/Paycards";
import Price, { PriceRef } from "../../components/section/Price";
import Resume from "../donation/steps/Resume";
import Success from "../donation/steps/SuccessStep";
import Donation from "../../components/section/Donation";

const Checkout = (props: Props) => {
	const dispatch = useDispatch();
	const { setShowError } = bindActionCreators(actionsCreators, dispatch);
	const appLocation = useSelector((state: State) => state.location.location);
	const currentUser = useSelector((state: State) => state.user.currentUser);

	const priceStep = useRef<PriceRef>(null);
	const paycardsStep = useRef<PaycardsRef>(null);

	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [step, setStep] = useState(0);
	const [paymentMethods, setPaymentsMethods] = useState<
		Array<PaymentMethodScheme>
	>([]);
	const [data, setData] = useState<any>({
		product: {
			price: 15,
			charge: "monthly",
		},
	});

	useEffect(() => {
		if (props.product && currentUser) {
			setLoading(true);
			Donation.getPaycards(currentUser.objectId, props.product.charge)
				.then(({ paymentMethods, defaultPaymentMethod }) => {
					setPaymentsMethods(paymentMethods);
					setData({
						...data,
						product: props.product,
						paymentMethod: defaultPaymentMethod,
					});
					setLoading(false);
				})
				.catch(() => {
					setLoading(false);
				});
		}
	}, [props.product]);

	const initSubscription = async (
		user: UserScheme,
		paymentMethod: any,
		paymentRequestEvent?: PaymentRequestPaymentMethodEvent
	) => {
		setLoading(true);
		const result = await Donation.initSubscription({
			user: user,
			product: data.product,
			currency: (appLocation && appLocation.currency) ? appLocation.currency.name : "eur",
			paymentMethod: paymentMethod,
			paymentRequestEvent: paymentRequestEvent,
			view: "web-sb",
		});
		//Error
		if (result.error) {
			setShowError({ show: true, message: result.error });
			setLoading(false);
		}
		//Need 3d secure confirmation
		else if (result.result) {
			paycardsStep.current?.confirmPaymentIntent(result.result);
		}
		//Finish confirmation
		else {
			setSuccess(true);
			setLoading(false);
		}
	};

	const onPaymentMethodClick = (paymentMethod: any) => {
		if (currentUser) {
			setData({
				...data,
				paymentMethod: paymentMethod,
			});
			if (
				paymentMethod.id !== "apple_pay" &&
				paymentMethod.id !== "google_pay"
			) {
				setLoading(true);
				PaymentMethod.setDefault(paymentMethod.objectId, {
					userId: currentUser.objectId,
				})
					.then(async (result: any) => {
						if (currentUser) {
							const result = await Donation.getPaycards(
								currentUser.objectId,
								data.product.charge
							);
							setPaymentsMethods(result.paymentMethods);
							setData({
								...data,
								paymentMethod: result.defaultPaymentMethod,
							});
							setLoading(false);
						}
					})
					.catch((error: string) => {
						setLoading(false);
					});
			}
		}
	};

	const onNextClick = async () => {
		//Select price
		if (step === 0 && !props.product) {
			const newData = priceStep.current?.getData();
			if (newData && newData.price && newData.charge && currentUser) {
				setLoading(true);
				const result = await Donation.getProduct({
					user: currentUser,
					price: newData.price,
					charge: newData.charge,
					view: "web-sb",
				});
				setPaymentsMethods(result.paymentMethods);
				setData({
					...data,
					product: result.product,
					paymentMethod: result.defaultPaymentMethod,
				});
				setLoading(false);
				setStep(step + 1);
			}
		}
		//Select payment method
		else if ((step === 0 && data.product) || step === 1) {
			const newData = await paycardsStep.current?.getData();
			if (newData && newData.paymentMethod && newData.paymentMethod.id) {
				setData({ ...data, ...newData });
				if (currentUser) {
					initSubscription(currentUser, newData.paymentMethod);
				}
			} else if (newData && newData.paymentRequest) {
				setLoading(true);
				const newData = await paycardsStep.current?.getData();
				setLoading(false);
				//Paycard
				if (
					newData &&
					newData.paymentMethod &&
					newData.paymentMethod.id
				) {
					setData({ ...data, ...newData });
					if (currentUser) {
						initSubscription(currentUser, newData.paymentMethod);
					}
				}
				//Apple Pay
				else if (newData && newData.paymentRequest) {
					newData.paymentRequest.show();
					newData.paymentRequest.on("paymentmethod", async (ev) => {
						setData({
							...data,
							paymentMethod: {
								id: ev.paymentMethod.id,
								brand: "apple_pay",
							},
						});
						if (currentUser) {
							initSubscription(currentUser, ev.paymentMethod, ev);
						}
					});
				}
				//Sepa Debit
				else if (newData && newData.paymentMethod) {
					if (currentUser) {
						initSubscription(currentUser, {
							id: newData.paymentMethod.objectId,
							brand: "sepa_debit",
						});
					}
				} else {
					setShowError({
						show: true,
						message:
							"Debes añadir un método de pago para completar la compra de entradas",
					});
				}
			} else {
				setShowError({
					show: true,
					message:
						"Debes añadir un método de pago para completar la compra de entradas",
				});
			}
		}
	};

	return (
		<Wizard
			tag={"register-volunteer"}
			location={appLocation}
			loginIndex={0}
			step={step}
			titles={
				props.product
					? ["Método de pago", "Confirmación"]
					: ["", "Método de pago", "Confirmación"]
			}
			onError={(error: string) =>
				setShowError({ show: true, message: error })
			}
			onStepChange={(step: number) => {
				setStep(step);
			}}
			buttonProps={{
				loading: loading,
				success: success,
				onClick: onNextClick,
				onSuccess: () => setStep(step + 1),
			}}
			RightViews={[
				<Resume
					image={require("../../assets/images/background/register/back_video.webp")}
				/>,
				<Resume
					image={require("../../assets/images/background/register/back_vision.webp")}
				/>,
			]}
			LeftViews={
				props.product
					? [
							<Paycards
								ref={paycardsStep}
								paymentMethods={paymentMethods}
								buttonProps={{
									children:
										data.paymentMethod?.brand ===
											"apple_pay" ||
										data.paymentMethod?.brand ===
											"google_pay"
											? "Colaborar con"
											: "Colaborar con " +
											  data.product.price +
											  " €" +
											  (data.product.charge === "monthly"
													? "/mes"
													: data.product.charge ===
													  "annually"
													? "/año"
													: ""),
									width: "100%",
									request: data.paymentMethod?.brand,
								}}
								currency={"eur"}
								countryCode={"ES"}
								charge={
									data.product.charge
										? data.product.charge
										: "one"
								}
								currentUser={currentUser}
								amount={data.product.price}
								onPaymentMethodClick={onPaymentMethodClick}
								onSetupConfirmed={(
									paymentMethodId?: string,
									error?: string
								) => {
									if (error) {
										setShowError({
											show: true,
											message: error,
										});
										setLoading(false);
									} else {
										setSuccess(true);
										setLoading(false);
									}
								}}
							/>,
							<Success user={currentUser} />,
					  ]
					: [
							<Price
								ref={priceStep}
								currency={"eur"}
								language={"es"}
								options={
									props.adminId
										? ["monthly", "annually"]
										: ["monthly", "annually", "one"]
								}
							/>,
							<Paycards
								ref={paycardsStep}
								paymentMethods={paymentMethods}
								buttonProps={{
									children:
										data.paymentMethod?.brand ===
											"apple_pay" ||
										data.paymentMethod?.brand ===
											"google_pay"
											? "Colaborar con"
											: "Colaborar con " +
											  data.product.price +
											  " €" +
											  (data.product.charge === "monthly"
													? "/mes"
													: data.product.charge ===
													  "annually"
													? "/año"
													: ""),
									width: "100%",
									request: data.paymentMethod?.brand,
								}}
								currency={"eur"}
								countryCode={"ES"}
								currentUser={currentUser}
								amount={data.product.price}
								charge={
									data.product.charge
										? data.product.charge
										: "one"
								}
								onPaymentMethodClick={onPaymentMethodClick}
								onSetupConfirmed={(
									paymentMethodId?: string,
									error?: string
								) => {
									if (error) {
										setShowError({
											show: true,
											message: error,
										});
										setLoading(false);
									} else {
										setSuccess(true);
										setLoading(false);
									}
								}}
							/>,
							<Success user={currentUser} />,
					  ]
			}
		/>
	);
};
export default Checkout;
export interface Props {
	product?: ProductScheme;
	adminId?: string;
}
