import styled from "styled-components";

import { Color } from "@adoptaunabuelo/react-components";
import ResumeStyled from "../../components/section/Resume";

const EventImage = styled.img`
	height: 100%;
	width: 100%;
	background-color: ${Color.background.soft};
	border-radius: 12px;
	object-fit: cover;
`;

const Resume = (props: Props) => {
	return (
		<ResumeStyled style={{ padding: 0, border: "none" }}>
			<EventImage src={props.image} />
		</ResumeStyled>
	);
};
export default Resume;
export interface Props {
	image: string;
}
