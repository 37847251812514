import { forwardRef, Ref, useImperativeHandle, useState } from "react";

import SectionContainer from "../container/SectionContainer";
import { Text, Color, Input } from "@adoptaunabuelo/react-components";

const CodeStep = forwardRef((props: CodeProps, ref: Ref<CodeRef>) => {
	const [code, setCode] = useState<string | undefined>(undefined);

	useImperativeHandle(ref, () => ({
		getData() {
			if (code) {
				return {
					code,
				};
			} else {
				return {
					code: undefined,
				};
			}
		},
	}));

	const onChange = (value: string) => {
		setCode(value);
		props.onCodeChange(value);
	};

	return (
		<SectionContainer title={props.title}>
			<Input
				type="code"
				loading={props.loading}
				autoFocus={true}
				onChange={onChange}
			/>
			{props.countdown && props.countdown > 0 ? (
				<Text
					type="p2"
					style={{ color: Color.text.high, marginTop: 24 }}
				>
					Reenviar código en {props.countdown} segundos
				</Text>
			) : null}
		</SectionContainer>
	);
});
export default CodeStep;
export interface CodeProps {
	title?: string;
	countdown?: number;
	loading?: boolean;
	onCodeChange: (code: string) => void;
}
export interface CodeRef {
	getData: () => {
		code: string | undefined;
	};
}
