import styled from "styled-components";
import media from "styled-media-query";
import moment from "moment";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { EventScheme, ProductScheme } from "client-v2";

import { Color, Text, Button } from "@adoptaunabuelo/react-components";
import { Calendar, MapPin, Check } from "lucide-react";

const size: any = "1080px";

const Background = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;
const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 550px;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const RowReverse = styled.div`
	display: flex;
	flex-direction: row;
	width: -webkit-fill-available;
	width: -moz-available;
	border: 1px solid ${Color.line.soft};
	padding: 18px;
	border-radius: 12px;
	${media.lessThan("small")`
        flex-direction: column;
        gap: 24px;
    `}
`;
const Column = styled.div`
	display: flex;
	flex-direction: column;
`;
const AppRow = styled.div`
	display: flex;
	flex-direction: row;
	background-color: white;
	border-radius: 12px;
	margin-top: 48px;
	padding: 0px 32px;
	${media.lessThan(size)`
        padding: 0px 24px;
    `}
	${media.lessThan("small")`
        flex-direction: column;
        padding: 24px 24px 0px;
        align-items: center;
    `}
`;
const Mockup = styled.img`
	height: 288px;
	margin-top: 32px;
	margin-left: 12px;
	object-fit: cover;
	${media.lessThan(size)`
        height: 240px;
    `}
	${media.lessThan("small")`
        margin: 32px 0px 0px;
        height: 200px;
        width: 200px;
        object-position: top;
    `}
`;
const IconImage = styled.img`
	width: 135px;
	height: 40px;
	object-fit: cover;
	border-radius: 8px;
	cursor: pointer;
`;
const CheckIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 20px;
	width: 20px;
	background-color: ${Color.background.primaryLow};
	border-radius: 20px;
	margin-top: 2px;
`;

const Success = (props: Props) => {
	const onMapClick = () => {
		const location = props.event ? props.event.location : undefined;
		if (location)
			window.open(
				"https://maps.google.com/?q=" +
					location.latitude +
					"," +
					location.longitude,
				"_blank"
			);
	};

	return props.event && props.product ? (
		<Background>
			<Container>
				<Text
					type="h3"
					weight="semibold"
					style={{ textAlign: "center" }}
				>
					¡Nos vemos en el evento!
				</Text>
				<Text
					type="h5"
					weight="regular"
					style={{ textAlign: "center" }}
				>
					{props.product.title}
				</Text>
				<RowReverse
					style={{
						marginTop: 48,
						justifyContent: "space-between",
						gap: 24,
					}}
				>
					<Column style={{ flex: 1, alignItems: "center" }}>
						<Calendar
							style={{ marginBottom: 8 }}
							color={Color.text.full}
						/>
						<Text type="p" weight="medium">
							{props.product.startDate
								? moment(props.product.startDate.iso).format(
										"ddd D MMMM"
								  )
								: undefined}
						</Text>
						<Text
							type="p2"
							style={{ color: Color.text.high, marginBottom: 8 }}
						>
							De{" "}
							{props.product.startDate
								? moment(props.product.startDate.iso).format(
										"HH:mm"
								  )
								: undefined}{" "}
							a{" "}
							{props.product.endDate
								? moment(props.product.endDate.iso).format(
										"HH:mm"
								  )
								: undefined}
						</Text>
						{props.product.startDate && props.product.endDate && (
							<AddToCalendarButton
								buttonStyle="custom"
								customCss="https://data.adoptaunabuelo.org/css/calendar_button.css"
								name={props.product.title}
								description={props.event.description}
								organizer={
									"Adopta Un Abuelo | info@adoptaunabuelo.org"
								}
								startDate={moment(
									props.product.startDate.iso
								).format("YYYY-MM-DD")}
								startTime={
									props.product.startDate
										? moment(
												props.product.startDate.iso
										  ).format("HH:mm")
										: undefined
								}
								endDate={moment(
									props.product.endDate.iso
								).format("YYYY-MM-DD")}
								endTime={
									props.product.endDate
										? moment(
												props.product.endDate.iso
										  ).format("HH:mm")
										: undefined
								}
								timeZone="Europe/Madrid"
								location={props.event.address}
								hideIconButton
								hideBackground
								hideCheckmark
								label={"Añadir a tu calendario"}
								language="es"
								options={[
									"Apple",
									"Google",
									"Outlook.com",
									"Yahoo",
									"Microsoft365",
									"MicrosoftTeams",
								]}
							/>
						)}
					</Column>
					<Column style={{ flex: 1, alignItems: "center" }}>
						<MapPin
							style={{ marginBottom: 8 }}
							color={Color.text.full}
						/>
						<Text type="p" weight="medium">
							{props.event.shortAddress}
						</Text>
						<Text type="p2" style={{ color: Color.text.high }}>
							{props.event.address}
						</Text>
						<Button
							style={{ marginTop: 8 }}
							design="call-to-action"
							onClick={onMapClick}
						>
							Cómo llegar
						</Button>
					</Column>
				</RowReverse>
				<Column style={{ marginTop: 48 }}>
					<Text
						type="h4"
						weight="semibold"
						style={{ marginBottom: 16 }}
					>
						Requisitos para los asistentes
					</Text>
					<Row style={{ marginBottom: 12 }}>
						<CheckIcon>
							<Check
								height={16}
								width={16}
								strokeWidth={3}
								color={Color.text.primary}
							/>
						</CheckIcon>
						<Text type="p" style={{ marginLeft: 12 }}>
							Los menores de 16 años tendrán que ir acompañados
							por padres o tutores.
						</Text>
					</Row>
					<Row style={{ marginBottom: 12 }}>
						<CheckIcon>
							<Check
								height={16}
								width={16}
								strokeWidth={3}
								color={Color.text.primary}
							/>
						</CheckIcon>
						<Text type="p" style={{ marginLeft: 12 }}>
							Si el evento es en residencia se aplicarán las
							medidas de seguridad de la residencia.
						</Text>
					</Row>
					<Row style={{ marginBottom: 12 }}>
						<CheckIcon>
							<Check
								height={16}
								width={16}
								strokeWidth={3}
								color={Color.text.primary}
							/>
						</CheckIcon>
						<Text type="p" style={{ marginLeft: 12 }}>
							Informa a Adopta Un Abuelo sobre cualquier alergia o
							restricción alimentaria.
						</Text>
					</Row>
				</Column>
				{props.product.price > 0 && (
					<Column
						style={{
							backgroundColor: Color.background.secondaryLow,
							borderRadius: 12,
							marginTop: 32,
							padding: 16,
						}}
					>
						<Text type="h5" weight="semibold">
							Política de cancelación
						</Text>
						<Text type="p" style={{ marginTop: 8 }}>
							Cancela hasta 48 horas antes de que empiece la
							experiencia para obtener el reembolso completo.
						</Text>
					</Column>
				)}
				<AppRow>
					<Column style={{ justifyContent: "center" }}>
						<Text type="h4" weight="semibold">
							Descarga nuestra app y conoce a abuelos geniales
						</Text>
						<Text type="p" style={{ marginTop: 8 }}>
							Hazte voluntario, colabora o reserva tu plaza en los
							eventos.
						</Text>
						<Row style={{ marginTop: 24, gap: 12 }}>
							<IconImage
								src={require("../../../assets/logo/android.png")}
								onClick={() =>
									window.open(
										"https://play.google.com/store/apps/details?id=com.adoptaunabuelo.app&hl=es_419&gl=ES&pli=1",
										"_blank"
									)
								}
							/>
							<IconImage
								src={require("../../../assets/logo/ios.png")}
								onClick={() =>
									window.open(
										"https://apps.apple.com/es/app/adopta-un-abuelo/id1559513523",
										"_blank"
									)
								}
							/>
						</Row>
					</Column>
					<Mockup
						src={require("../../../assets/images/mockup/event.png")}
					/>
				</AppRow>
			</Container>
		</Background>
	) : null;
};
export default Success;
export interface Props {
	event?: EventScheme;
	product?: ProductScheme;
}
