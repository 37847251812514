import { Route, Routes } from "react-router-dom";
import { UserScheme } from "client-v2";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../redux";
import { bindActionCreators } from "redux";

import PaycardWizard from "../views/paycard/PaycardWizard";

import Loading from "../components/modal/Loading";
import SuccessModal from "../components/modal/SuccessModal";

const PaycardLayout = (props: PayoutLayoutProps) => {
	const dispatch = useDispatch();
	const { setShowSuccess } = bindActionCreators(actionsCreators, dispatch);
	const success = useSelector((state: State) => state.modals.success);
	const loading = useSelector((state: State) => state.modals.loading);

	const onSuccessClick = () => {
		setShowSuccess({ show: false });
	};

	const onClose = () => {
		window.location.href = "https://adoptaunabuelo.org";
	};

	return (
		<>
			<SuccessModal
				isVisible={success.show}
				title={success.title}
				message={success.message}
				buttonProps={{
					children: "Entendido",
					onClick: onSuccessClick,
				}}
				onClose={onClose}
			/>
			<Loading isVisible={loading ? true : false} />
			<Routes>
				<Route path="/" element={<PaycardWizard />} />
			</Routes>
		</>
	);
};

export default PaycardLayout;
export interface PayoutLayoutProps {}
