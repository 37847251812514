import { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { AUA, Constant, Track, UserScheme } from "client-v2";
import ReactPixel from "react-facebook-pixel";
import { useDispatch } from "react-redux";
import { actionsCreators } from "../redux";
import { bindActionCreators } from "redux";
import Translation from "../assets/translation/index";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import GLPN from "google-libphonenumber";
import { hotjar } from "react-hotjar";

import RegisterLayout from "./RegisterLayout";
import PaycardLayout from "./PaycardLayout";
import SuccessCardView from "../views/SuccessCardView";
import DonationLayout from "./DonationLayout";
import LetterLayout from "./LetterLayout";
import EventLayout from "./EventLayout";
import ChallengeLayout from "./ChallengeLayout";
import BirthdayLayout from "./BirthdayLayout";
import SBLayout from "./SBLayout";
import SignatureLayout from "./SignatureLayout";
import FeedLayout from "./FeedLayout";

// Create a GrowthBook instance
const growthbook = new GrowthBook({
	apiHost: "https://cdn.growthbook.io",
	clientKey: "sdk-QsRr9klLcVak4lj",
	enableDevMode: true,
	trackingCallback: (experiment, result) => {
		Track.track("Experiment Viewed", {
			"Experiment name": experiment.key,
			"Variant name": result.variationId,
			$source: "growthbook",
		});
	},
});

const Init = (props: Props) => {
	const phoneUtil = GLPN.PhoneNumberUtil.getInstance();
	const dispatch = useDispatch();
	const {
		setCurrentUser,
		setIsMobile,
		setIsScreen01,
		setIsScreen02,
		setAppTranslation,
		setAppLocation,
	} = bindActionCreators(actionsCreators, dispatch);

	const [loading, setLoading] = useState(true);
	const [stripePromise, setStripePromise] = useState<
		Promise<Stripe | null> | undefined
	>(undefined);

	useEffect(() => {
		initServer();

		//Add listeners
		window.addEventListener("resize", documentProperties);
		documentProperties();
		return () => {
			window.removeEventListener("resize", documentProperties);
		};
	}, []);

	const documentProperties = () => {
		const doc = document.documentElement;
		doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
		doc.style.setProperty("--doc-width", `${window.innerWidth}px`);
		doc.style.setProperty("--nav-bar-height", `72px`);
		doc.style.setProperty("--nav-bar-height-mobile", `62px`);
		if (window.screen.width <= 1350) setIsScreen01(true);
		else setIsScreen01(false);
		if (window.screen.width <= 1050) setIsScreen02(true);
		else setIsScreen02(false);
		if (window.screen.width <= 768) setIsMobile(true);
		else setIsMobile(false);
	};

	const initServer = async () => {
		//Init server
		const env: any = process.env.REACT_APP_ENV
			? process.env.REACT_APP_ENV
			: process.env.NODE_ENV;
		const result = await AUA.initServer(
			env,
			process.env.REACT_APP_LOCALHOST ? true : false
		);

		//Init Hotjar
		hotjar.initialize({
			id: 1902171,
			sv: 6,
			debug: env === "development" ? true : false,
		});

		if (result.data) {
			setCurrentUser(result.data);
			hotjar.identify(result.data.objectId, result.data);
		} else if (result.error) alert(result.error);

		//Init Stripe
		const stripeKey = AUA.getStripeKey();
		const stripePromise = loadStripe(stripeKey, {
			apiVersion: "2023-10-16",
		});
		setStripePromise(stripePromise);

		//Init facebook
		ReactPixel.init("2520818358062687", undefined, {
			autoConfig: true,
			debug: false,
		});
		ReactPixel.pageView();

		await initLanguage(result.data);
		setLoading(false);
	};

	const initLanguage = async (user?: UserScheme) => {
		//Get user language
		const tempLanguage = navigator.language ? navigator.language : "es-ES";
		const language = tempLanguage.split("-")[0];

		//Get country code from phone number or from navigator
		const phone = user ? user.phone : undefined;
		let country_code = "ES";
		let hasSEPA = false;
		if (phone) {
			const result = phoneUtil.parse(phone);
			const phoneCountryCode = result.getCountryCode();
			if (phoneCountryCode) {
				const contryContant = Constant.countries().find(
					(i) => i.prefix === "+" + phoneCountryCode.toString()
				);
				if (contryContant) {
					country_code = contryContant.countryCode;
					hasSEPA = contryContant.sepa;
				}
			}
		} else {
			const tempLanguage = navigator.language
				? navigator.language
				: "es-ES";
			country_code = tempLanguage.split("-")[1];
			const contryContant = Constant.countries().find(
				(i) => i.countryCode === country_code
			);
			hasSEPA = contryContant ? contryContant.sepa : false;
		}

		//Get currency
		const response = await fetch(
			`https://restcountries.com/v3.1/alpha/${country_code}`
		);
		if (response.status === 200) {
			const result = await response.json();
			const currency =
				result && result[0] && result[0].currencies
					? {
							name: Object.keys(result[0].currencies)[0],
							symbol: result[0].currencies[
								Object.keys(result[0].currencies)[0]
							].symbol,
					  }
					: {
							name: "EUR",
							symbol: "€",
					  };
			//Check if country has a valid currency
			const config: any = await AUA.getConfig();
			const validCurrencies = config
				? config.attributes.VALID_CURRENCIES
				: undefined;
			const isValidCurrency = validCurrencies
				? validCurrencies.find((i: any) => i.name === currency.name)
				: undefined;

			if (isValidCurrency) {
				//Check i
				setAppLocation({
					language: language,
					country_code: country_code,
					currency: {
						...isValidCurrency,
						symbol: new Intl.NumberFormat(language, {
							style: "currency",
							currency: currency.name,
						})
							.formatToParts(1)
							.find((part) => part.type === "currency")?.value,
						name: currency.name.toLowerCase(),
						hasSEPA: hasSEPA,
					},
				});
			} else {
				//Currency is not valid. Set it to EUR
				setAppLocation({
					language: language,
					country_code: country_code,
					currency: {
						name: "EUR",
						symbol: "€",
						conversion: 1,
						hasSEPA: hasSEPA,
						price: {
							annually: {
								min: 50,
								max: 250,
								default: 120,
								gift: 200,
								jump: 10,
							},
							monthly: {
								min: 5,
								max: 40,
								default: 15,
								gift: 20,
								jump: 1,
							},
							one: {
								min: 5,
								max: 500,
								default: 50,
								jump: 5,
							},
						},
					},
				});
			}
		} else {
			//No result. Set currency as EUR
			setAppLocation({
				language: language,
				country_code: country_code,
				currency: {
					name: "EUR",
					symbol: "€",
					conversion: 1,
					hasSEPA: hasSEPA,
					price: {
						annually: {
							min: 50,
							max: 250,
							default: 120,
							gift: 200,
							jump: 10,
						},
						monthly: {
							min: 5,
							max: 40,
							default: 15,
							gift: 20,
							jump: 1,
						},
						one: {
							min: 5,
							max: 500,
							default: 50,
							jump: 5,
						},
					},
				},
			});
		}

		//Init test a/b
		await initTestAB(country_code, user);

		//Init translation
		const translation = (Translation as any)[language]
			? (Translation as any)[language]
			: Translation["es"];
		setAppTranslation(translation);
	};

	const initTestAB = async (country_code: string, user?: UserScheme) => {
		//Get public ip
		//const response = await fetch("https://api.ipify.org?format=json");
		//const ip = await response.json();

		// Set user attributes for targeting (from cookie, auth system, etc.)
		const mixpanelId = AUA.getMixpanelDistinctId();
		growthbook.init({ streaming: true });
		growthbook.setAttributes({
			id: mixpanelId,
			//deviceId: ip.ip,
			company: "aua",
			loggedIn: user ? true : false,
			employee:
				(process.env.REACT_APP_ENV
					? process.env.REACT_APP_ENV
					: process.env.NODE_ENV) === "development",
			country: country_code,
			url: window.location.href,
		});
	};

	return loading || !stripePromise ? null : (
		<Elements stripe={stripePromise}>
			<GoogleReCaptchaProvider
				reCaptchaKey={"6Lf4j6YlAAAAAO5Svil2env4maE3OzXHPPPBPdSo"}
				language={navigator.language ? navigator.language : "es-ES"}
				useEnterprise={true}
			>
				<GrowthBookProvider growthbook={growthbook}>
					<Routes>
						<Route
							path="/registro/*"
							element={<RegisterLayout />}
						/>
						<Route path="/sb/*" element={<SBLayout />} />
						<Route
							path="/donacion/*"
							element={<DonationLayout />}
						/>
						<Route path="/tarjeta/*" element={<PaycardLayout />} />
						<Route
							path="/cartas/*"
							element={<LetterLayout growthbook={growthbook} />}
						/>
						<Route path="/evento/*" element={<EventLayout />} />
						<Route path="/reto/*" element={<ChallengeLayout />} />
						<Route
							path="/cumpleanios/*"
							element={<BirthdayLayout />}
						/>
						<Route
							path="/card-success/*"
							element={<SuccessCardView />}
						/>
						<Route path="/firma/*" element={<SignatureLayout />} />
						<Route path="/feed/*" element={<FeedLayout />} />
						<Route
							path="/"
							element={
								<Navigate
									to={`/${
										process.env.APP_PATH
											? process.env.APP_PATH
											: "cartas"
									}`}
									replace
								/>
							}
						/>
					</Routes>
				</GrowthBookProvider>
			</GoogleReCaptchaProvider>
		</Elements>
	);
};
export default Init;
export interface Props {}
