import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

const size: any = '1080px';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 32px 0px;
    ${media.lessThan(size)`
        max-width: calc(100vw - 96px);
    `}
    ${media.lessThan("small")`
        max-width: calc(100vw - 48px);
    `}
`

const SectionContainer = (props: Props) =>{

    return(
        <Container
            {...props}
        >
            {props.children}
        </Container>
    )
}
export default SectionContainer;
export interface Props extends ComponentPropsWithoutRef<"div">{

}