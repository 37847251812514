import { useState } from 'react';
import styled from 'styled-components';

import ActivitiesCell from '../cell/ActivitiesCell';
import { Color } from '@adoptaunabuelo/react-components';
import { ChevronRight, ChevronLeft } from 'lucide-react';

const Container = styled.div`

`
const DirectionView = styled.div`
    display: flex;
    align-items:center;
`
const DirectionButton = styled.div<{$disabled: boolean}>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 100px;
    border: 1px solid ${Color.line.soft};
    cursor: ${props => props.$disabled ? 'unset' : 'pointer'};
    background-color: white;
    opacity: ${props => props.$disabled ? 0.3 : 1};
    &:hover{
        background-color: ${Color.line.soft};
    }
`

const TestimonyList = (props: Props) =>{

    const [ index, setIndex ] = useState(0);

    const onPreviousClick = () =>{
        if(index !== 0)
            setIndex(index-1);
    }

    const onNextClick = () =>{
        if(index !== props.testimonies.length-1)
            setIndex(index+1);
    }

    return(
        <Container>
            <ActivitiesCell
                {...props.testimonies[index]}
            >
                <DirectionView>
                    <DirectionButton
                        style={{marginRight: 8}}
                        $disabled={index === 0}
                        onClick={onPreviousClick}
                    >
                        <ChevronLeft/>
                    </DirectionButton>
                    <DirectionButton
                        $disabled={index === props.testimonies.length-1}
                        onClick={onNextClick}
                    >
                        <ChevronRight/>
                    </DirectionButton>
                </DirectionView>
            </ActivitiesCell>
        </Container>
    )
}
export default TestimonyList;
export interface Props{
    testimonies: Array<{
        img: string,
        testimony: string,
        info: string,
        name: any,
        logo: any
    }>
}