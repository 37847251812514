import styled from "styled-components";
import HobbiesStamp from "../../../components/stamp/HobbiesStamp";
import { useEffect, useState } from "react";
import { Constant } from "client-v2";
import media from "styled-media-query";
import { useSelector } from "react-redux";
import { State } from "../../../redux";

const screen02: any = "1050px";
const Container = styled.div<{ $position: "left" | "right" }>`
	position: relative;
	display: flex;
	flex: 1;
	width: 300px;
	align-items: center;
	justify-content: ${(props) =>
		props.$position === "right" ? "flex-start" : "flex-end"};
	padding-left: ${(props) => (props.$position === "right" ? "18px" : "0px")};
	padding-right: ${(props) => (props.$position === "left" ? "18px" : "0px")};
	${media.lessThan(screen02)`
        width: 100%;
    `}
	${media.lessThan("medium")`
        display: none;
    `}
`;

const StampSection = (props: StampProps) => {
	const appLocation = useSelector(
		(state: State) => state.location.location
	);
	const [hobbies, setHobbies] = useState<any[]>([]);
	const [show, setShow] = useState(false);

	useEffect(() => {
		if (props.hobbies && props.hobbies.length > 0) {
			const hobbiesConstants = Constant.hobbies();
			const temp = props.hobbies
				.map((item) => {
					const result = hobbiesConstants.filter(
						(i) => i.id === item
					);
					if (result.length > 0) {
						return {
							title: appLocation?.language === "en" ? result[0].label_en : result[0].label,
							icon: result[0].icon,
							backgroundColor: result[0].backgroundColor,
						};
					}
				})
				.filter((i) => i)
				.slice(0, 5);
			setHobbies(temp);
			setShow(true);
		}
		else{
			setHobbies([]);
			setShow(false);
		}
	}, [props.hobbies]);

	return (
		<Container $position={props.position}>
			{props.position === "left" && (
				<>
					{hobbies.length >= 1 && (
						<HobbiesStamp
							style={{
								position: "absolute",
								top: "50%",
								left: "15%",
							}}
							icon={hobbies[0].icon}
							title={hobbies[0].title}
							backgroundColor={hobbies[0].backgroundColor}
						/>
					)}
					{hobbies.length >= 3 && (
						<HobbiesStamp
							style={{
								position: "absolute",
								top: "28%",
								right: "5%",
							}}
							icon={hobbies[2].icon}
							title={hobbies[2].title}
							backgroundColor={hobbies[2].backgroundColor}
						/>
					)}
				</>
			)}
			{props.position === "right" && (
				<>
					{hobbies.length >= 2 && (
						<HobbiesStamp
							icon={hobbies[1].icon}
							title={hobbies[1].title}
							backgroundColor={hobbies[1].backgroundColor}
						/>
					)}
					{hobbies.length >= 5 && (
						<HobbiesStamp
							style={{
								position: "absolute",
								top: "28%",
								left: "45%",
							}}
							icon={hobbies[4].icon}
							title={hobbies[4].title}
							backgroundColor={hobbies[4].backgroundColor}
						/>
					)}
					{hobbies.length >= 4 && (
						<HobbiesStamp
							style={{
								position: "absolute",
								top: "55%",
								right: 0,
							}}
							icon={hobbies[3].icon}
							title={hobbies[3].title}
							backgroundColor={hobbies[3].backgroundColor}
						/>
					)}
				</>
			)}
		</Container>
	);
};
export default StampSection;
export interface StampProps {
	position: "right" | "left";
	hobbies?: string[];
}
