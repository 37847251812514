import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { CoorporateScheme, UserScheme } from "client-v2";
import { useSelector } from "react-redux";
import { State } from "../../../redux";

import { Modal, Text, Button, ColorV2 } from "@adoptaunabuelo/react-components";
import { Download, X } from "lucide-react";
import LetterCanvas, {
	CanvasRef,
} from "../../../components/canvas/LetterCanvas";

const Row = styled.div`
	display: flex;
	flex-direction: row;
	${media.lessThan("medium")`
        flex-direction: column;
        align-items: center;
    `}
`;
const Column = styled.div`
	display: flex;
	flex-direction: column;
`;
const EditContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: sticky;
	bottom: 0px;
	padding: 24px 0px 0px;
	border-top: 1px solid ${ColorV2.border.neutralSoft};
	margin-top: 24px;
	gap: 24px;
	background-color: white;
	${media.lessThan("medium")`
        padding: 24px 0px;
    `}
`;
const Cell = styled.div`
	display: flex;
	flex-direction: row;
`;
const ButtonCell = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;
const ButtonContainer = styled.div`
	display: flex;
	gap: 4px;
`;
const Number = styled(Text)`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 24px;
	width: 24px;
	background-color: ${ColorV2.surface.background};
	border-radius: 30px;
	font-weight: 600 !important;
`;
const ImageContainer = styled.div`
	width: 250px;
	aspect-ratio: 46/81;
	background-color: white;
	border-radius: 12px;
	overflow: hidden;
	box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.18);
	${media.lessThan("small")`
        width: 180px;
    `}
`;
const ImageColumn = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${ColorV2.surface.background};
	padding: 32px;
	justify-content: center;
	${media.lessThan("medium")`
        width: 100%;
		align-items: center;
    `}
`;
const CustomizationButton = styled.button<{ $isSelected: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 32px;
	width: 32px;
	border-radius: 32px;
	padding: 0px;
	cursor: pointer;
	background-color: transparent;
	border: ${(props) =>
		props.$isSelected ? "2px solid " + ColorV2.text.neutralHard : "none"};
	&:hover {
		background-color: ${ColorV2.surface.neutralSoft};
	}
`;

const InstagramModal = (props: Props) => {
	const canvasRef = useRef<CanvasRef>(null);
	const isMobile = useSelector((state: State) => state.screen.isMobile);
	const [instagramImage, setInstagramImage] = useState<string | undefined>(
		undefined
	);
	const [customization, setCustomization] = useState({
		design: "A",
		color: "008FF5",
	});

	const customOptions = [
		{
			id: "a",
			type: "design",
			title: "A",
		},
		{
			id: "b",
			type: "design",
			title: "B",
		},
		{
			id: "c",
			type: "design",
			title: "C",
		},
		{
			id: "primary",
			type: "color",
			title: "008FF5",
		},
		{
			id: "primaryHard",
			type: "color",
			title: "00315C",
		},
		{
			id: "background",
			type: "color",
			title: "F9F6F3",
		},
	];

	useEffect(() => {
		loadImage(customization.design, customization.color);
	}, []);

	const loadImage = async (design: string, color: string) => {
		setInstagramImage(
			require(`../../../assets/images/storie/letter_${design}_${color}.png`)
		);
	};

	const downloadImage = () => {
		const base64 = canvasRef.current?.toDataURL();
		if (base64) {
			const downloadLink = document.createElement("a");
			const fileName = "mi_carta.png";
			downloadLink.href = base64;
			downloadLink.download = fileName;
			downloadLink.click();
		}
	};

	const onCustomizationChange = (item: {
		id: string;
		type: string;
		title: string;
	}) => {
		const temp = {
			design: item.type === "design" ? item.title : customization.design,
			color: item.type === "color" ? item.title : customization.color,
		};
		setCustomization(temp);
		loadImage(temp.design, temp.color);
	};

	return (
		<Modal
			isVisible={props.isVisible}
			onClose={props.onClose}
			type={isMobile ? "full-screen" : "default"}
			style={{
				backgroundColor: "white",
				width: 800,
				padding: 0,
				maxHeight: "95%",
			}}
			contentStyle={{ padding: 0 }}
			hideHeader={true}
		>
			<Button
				style={{ position: "absolute", top: 8, right: 8 }}
				design={"image"}
				icon={<X color={ColorV2.text.neutralHard} />}
				onClick={props.onClose}
			/>
			<Row>
				<ImageColumn>
					<ImageContainer>
						{instagramImage && (
							<LetterCanvas
								ref={canvasRef}
								style={{ width: "100%" }}
								image={instagramImage}
								name={props.grandpa.name}
								birthday={props.grandpa.birthday?.iso}
								design={customization.design}
								textColor={
									customization.color === "F9F6F3"
										? ColorV2.text.neutralHard
										: "white"
								}
							/>
						)}
					</ImageContainer>
				</ImageColumn>
				<Column
					style={{
						justifyContent: "center",
						padding: isMobile ? "24px 24px 0px" : "48px 24px 24px",
					}}
				>
					<Text
						type="h3"
						weight="semibold"
						style={{ marginBottom: 32 }}
					>
						Cómo compartir la imagen en Instagram
					</Text>
					<Column style={{ gap: 18 }}>
						<Cell style={{ gap: 12 }}>
							<Number type="p2">1</Number>
							<Text type="p" style={{ flex: 1 }}>
								Personaliza la imagen a tu gusto.
							</Text>
						</Cell>
						<Cell style={{ gap: 12 }}>
							<Number type="p2">2</Number>
							<Text type="p" style={{ flex: 1 }}>
								Guarda la imagen en tu galería.
							</Text>
						</Cell>
						<Cell style={{ gap: 12 }}>
							<Number type="p2">3</Number>
							<Text type="p" style={{ flex: 1 }}>
								Crea un story en Instagram y usa la imagen que
								acabas de descargar.
							</Text>
						</Cell>
						<Cell style={{ gap: 12 }}>
							<Number type="p2">4</Number>
							<Text type="p" style={{ flex: 1 }}>
								¡Compártela! Te agradecemos mucho que animes
								a otros a que escriban su carta. ¡Eres
								increíble!
							</Text>
						</Cell>
					</Column>
					<EditContainer>
						<ButtonCell>
							<Text type="p">Elige tu diseño</Text>
							<ButtonContainer>
								{customOptions
									.filter((i) => i.type === "design")
									.map((item) => {
										const isSelected =
											item.title === customization.design;
										return (
											<CustomizationButton
												key={item.id}
												$isSelected={isSelected}
												onClick={() =>
													onCustomizationChange(item)
												}
											>
												<Text type="p" weight="medium">
													{item.title}
												</Text>
											</CustomizationButton>
										);
									})}
							</ButtonContainer>
						</ButtonCell>
						<ButtonCell>
							<Text type="p">Selecciona un color</Text>
							<ButtonContainer>
								{customOptions
									.filter((i) => i.type === "color")
									.map((item) => {
										const isSelected =
											item.title === customization.color;
										return (
											<CustomizationButton
												key={item.id}
												$isSelected={isSelected}
												onClick={() =>
													onCustomizationChange(item)
												}
											>
												<div
													style={{
														height: 20,
														width: 20,
														borderRadius: 20,
														backgroundColor:
															"#" + item.title,
													}}
												/>
											</CustomizationButton>
										);
									})}
							</ButtonContainer>
						</ButtonCell>
						<Button icon={<Download />} onClick={downloadImage}>
							Guardar imagen
						</Button>
					</EditContainer>
				</Column>
			</Row>
		</Modal>
	);
};
export default InstagramModal;
export interface Props {
	isVisible: boolean;
	grandpa: UserScheme;
	coorporate?: CoorporateScheme;
	onClose: () => void;
}
