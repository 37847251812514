import React, { useEffect } from "react";
import styled from "styled-components";

const SuccessCardViewContainer = styled.div`
	display: flex;
	height: var(--doc-height);
	flex-direction: column;
`;

const SuccessCardView = () => {
	useEffect(() => {
		window.top && window.top.postMessage("3DS-authentication-complete");
	});
	return <SuccessCardViewContainer>Success</SuccessCardViewContainer>;
};
export default SuccessCardView;
