import styled from "styled-components";

import { Color, Text } from '@adoptaunabuelo/react-components';
import { Star } from 'lucide-react';

const Container = styled.li`
    display: flex;
    flex: 1;
    flex-direction: column;
    border: 1px solid ${Color.line.soft};
    border-radius: 12px;
    padding: 16px;
`
const Row = styled.div`
    display: flex;
    flex-direction: row;
`
const Col = styled.div`
    display: flex;
    flex-direction: column;
`
const Image = styled.img`
    height: 56px;
    width: 56px;
    border-radius: 100px;
    background-color: ${Color.background.soft};
`

const TestimonyCell = (props: Props) =>{

    return(
        <Container>
            <Row
                style={{alignItems: 'center', marginBottom: 12}}
            >
                <Col
                    style={{flex: 1}}
                >
                    <Text type="h6" weight="medium">
                        {props.testimony.name}
                    </Text>
                    <Row>
                        <Star height={20} width={20} color={Color.text.primary} fill={Color.text.primary}/>
                        <Star height={20} width={20} color={Color.text.primary} fill={Color.text.primary}/>
                        <Star height={20} width={20} color={Color.text.primary} fill={Color.text.primary}/>
                        <Star height={20} width={20} color={Color.text.primary} fill={Color.text.primary}/>
                        <Star height={20} width={20} color={Color.text.primary} fill={Color.text.primary}/>
                    </Row>
                </Col>
                <Image
                    src={props.testimony.image}
                />
            </Row>
            <Text type="p">
                "{props.testimony.testimony}"
            </Text>
        </Container>
    )
}
export default TestimonyCell;
export interface Props{
    testimony: {
        name: string,
        image: any,
        testimony: string
    }
}