import {
	forwardRef,
	useState,
	useImperativeHandle,
	Ref,
} from "react";

import SectionContainer from "../container/SectionContainer";
import { Input } from "@adoptaunabuelo/react-components";
import { Constant } from "client-v2";

const Login = forwardRef((props: LoginProps, ref: Ref<LoginRef>) => {
	const [phone, setPhone] = useState<string | undefined>(undefined);
	const [phoneIsValid, setPhoneIsValid] = useState(false);
	const [phoneError, setPhoneError] = useState<string | undefined>(undefined);

	useImperativeHandle(ref, () => ({
		getData() {
			return getData();
		},
	}));

	const getData = () => {
		if (phone) {
			if (phoneIsValid) {
				setPhoneError(undefined);
				return {
					phone: phone,
				};
			} else {
				if (!phoneIsValid)
					setPhoneError("El número introducido no es válido");
				return undefined;
			}
		} else {
			if (!phone) setPhoneError("Debes añadir tu número de teléfono");
			return undefined;
		}
	};

	const onInputChange = (value: string) => {
		setPhone(value);
		setPhoneError(undefined);
	};

	const onKeyDown = (e: any) => {
		if (e.key === "Enter") {
			const result = getData();
			props.onEnter && props.onEnter(result);
		}
	};

	return (
		<SectionContainer>
			<Input
				id={"input-phone"}
				type="tel"
				autoComplete={"tel"}
				autoFocus={true}
				name={"telephone"}
				containerStyle={{ marginBottom: 12 }}
				placeholder={"Número de teléfono"}
				error={phoneError}
				country={props.country}
				countryOptions={Constant.countries()}
				design={"secondary"}
				//onKeyDown={onKeyDown}
				onPhoneChange={(data: any) => {
					onInputChange(data.country + data.value);
					setPhoneIsValid(data.isValid);
				}}
			/>
		</SectionContainer>
	);
});
export default Login;
export interface LoginProps {
	country: string;
	onEnter?: (
		result:
			| undefined
			| {
					phone: string;
			  }
	) => void;
}
export interface LoginRef {
	getData: () =>
		| undefined
		| {
				phone: string;
		  };
}
