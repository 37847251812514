import styled from 'styled-components';
import media from 'styled-media-query';

import ReCaptchaButton from '../button/ReCaptchaButton';
import { Button, Color, ColorV2 } from '@adoptaunabuelo/react-components';

const Container = styled.div`
    position: fixed;
    bottom: 0;
    display: none;
    width: 100%;
    z-index: 1000;
    flex-direction: column;
    justify-content: center;
    padding: 8px 0px;
    background-color: ${ColorV2.surface.background};
    ${media.lessThan("medium")`
        display: flex;
    `}
`
const Left = styled.div`
    display: flex;
    align-items: center;
    padding-left: 15%;
    ${media.lessThan("medium")`
        padding-left: 5%;
    `}
`
const Right = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15%;
    ${media.lessThan("medium")`
        padding-right: 5%;
    `}
`
const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

const Footer = (props: FooterProps) =>{

    const { recaptcha, ...restButtonProps } = props.buttonProps;

    return(
        <Container
            style={props.style}
        >
            {props.childrenPosition === 'top' && props.children}
            <Row>
                <Left>
                    {props.childrenPosition === 'left' && props.children}
                </Left>
                <Right>
                    {props.skipButtonProps &&
                        <Button
                            design={'call-to-action'}
                            style={{flex: 1, color: Color.text.high, marginRight: 12, fontSize: 14}}
                            {...props.skipButtonProps}
                        />
                    }
                    {recaptcha ?
                        <ReCaptchaButton
                            action='login'
                            {...restButtonProps}
                        />
                    :
                        <Button
                            {...restButtonProps}
                        />
                    }
                </Right>
            </Row>
        </Container>
    )
}
export default Footer;
export interface FooterProps{
    style?: any,
    children?: any
    childrenPosition?: 'top' | 'left'
    buttonProps: {
        children: string
        loading?: boolean
        success?: boolean,
        style?: any,
        disabled?: boolean,
        animationDelay?: number,
        animationTime?: number,
        recaptcha?: boolean,
        iconPosition?: 'right' | 'left',
        onSuccess?: () => void,
        onClick?: (e: any) => void,
    }
    skipButtonProps?: {
        children: string,
        onClick?: (e: any) => void,
    }
}