import { useState, useEffect } from "react";
import { Subscription, Track } from "client-v2";
import styled from "styled-components";
import media from "styled-media-query";
import moment from "moment";
import "moment/locale/es";
import { useSelector } from "react-redux";
import { State } from "../../../redux";
import { Play } from "lucide-react";
import { Text, Color, Button } from "@adoptaunabuelo/react-components";
import VideoModal from "../../../components/modal/VideoModal";

const screenSize1: any = "1300px";
const screenSize2: any = "1024px";

const HeaderContainer = styled.div<{ $backgroundImage: any }>`
	display: flex;
	flex: 1;
	flex-direction: column;
	width: -webkit-fill-available;
	width: -moz-available;
	background-image: url(${(props) => props.$backgroundImage});
	background-repeat: no-repeat;
	background-size: cover;
	background-position-x: right;
	background-position-y: bottom;
	${media.lessThan(screenSize1)`
        background-position-x: center;
    `}
	${media.lessThan(screenSize2)`
        background-size: contain;
    `}
    ${media.lessThan("medium")`
        background-image: none;
    `}
`;
const Section = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	padding: 0px 10%;
	height: calc(var(--doc-height) - var(--nav-bar-height));
	${media.lessThan(screenSize1)`
        padding: 0px 5%;
    `}
	${media.lessThan(screenSize2)`
        padding: 0px 48px;
    `}
    ${media.lessThan("medium")`
        height: unset;
        padding: 0px 32px;
    `}
    ${media.lessThan("small")`
        padding: 0px 24px;
    `}
`;
const MobileHeader = styled.div<{ $backgroundImage: any }>`
	display: none;
	align-items: flex-end;
	padding: 24px;
	height: 220px;
	background-image: url(${(props) => props.$backgroundImage});
	background-repeat: no-repeat;
	background-size: cover;
	background-position-x: center;
	background-position-y: center;
	${media.lessThan("medium")`
        display: flex;
    `}
`;
const DesktopSection = styled.div`
	${media.lessThan("medium")`
        display: none;
    `}
`;
const DataView = styled.div`
	display: flex;
	flex-direction: column;
	width: 560px;
	justify-content: center;
	margin-top: -72px;
	${media.lessThan("medium")`
        padding: 32px 0px;
        margin-top: 0px;
    `}
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const Title = styled(Text)`
	font-weight: 600 !important;
	${media.lessThan("small")`
        font-size: 28px !important;
	`}
`;
const UserImage = styled.img`
	height: 40px;
	width: 40px;
	border-radius: 40px;
	background-color: ${Color.background.soft};
	margin-right: 16px;
	object-fit: cover;
`;
const Header = (props: Props) => {
	moment.locale("es");
	const [play, setPlay] = useState(false);
	const [loading, setLoading] = useState(false);
	const [users, setUsers] = useState<Array<any>>([]);

	useEffect(() => {
		setLoading(true);
		Subscription.getData()
			.then((result) => {
				setUsers(result.data.users);
				setLoading(false);
			})
			.catch((e: string) => {
				setLoading(false);
			});
	}, []);

	const onVideoEnd = () => {
		Track.track("donation video finish", {
			view: "web-donation",
		});
		setPlay(false);
	};

	const renderCell = (payment: any, index: number) => (
		<Row
			key={"donation-" + index}
			style={{ padding: "6px 0px", alignItems: "center" }}
		>
			<UserImage
				src={
					payment.image
						? payment.image.url
						: require("../../../assets/images/icon/user_icon.png")
				}
			/>
			<Text type="p2">
				{payment.name ? payment.name : "Anónimo"} colaboró{" "}
				{moment(payment.date).fromNow()}
			</Text>
		</Row>
	);

	return (
		<>
			<VideoModal
				isVisible={play}
				url={
					"https://data.adoptaunabuelo.org/video/donation/welcome_01.mp4"
				}
				onFinish={onVideoEnd}
				onClose={() => setPlay(false)}
			/>
			<HeaderContainer
				$backgroundImage={require("../../../assets/images/background/header/donation.webp")}
			>
				<MobileHeader
					$backgroundImage={require("../../../assets/images/background/header/donation_mobile.webp")}
					onClick={() => setPlay(true)}
				>
					<Button
						style={{ backgroundColor: Color.background.full }}
						size={"small"}
						icon={
							<Play
								height={16}
								width={16}
								fill={"white"}
								color={"white"}
							/>
						}
					>
						Ver video
					</Button>
				</MobileHeader>
				<Section>
					<DataView>
						<Title
							type="h1"
							style={{
								marginBottom:
									props.type === "register" ? 0 : 40,
							}}
						>
							{props.type === "register"
								? "Da un paso más y hazte socio/a."
								: "Ningún mayor debería sentirse solo."}
							{props.type !== "register" && (
								<span style={{ color: Color.text.primary }}>
									{" "}
									¿Nos ayudas?
								</span>
							)}
						</Title>
						{props.type === "register" && (
							<Text
								type="p"
								style={{ marginBottom: 40, marginTop: 8 }}
							>
								Gracias a ti creamos una mejor sociedad que pone
								en valor la experiencia y el saber de nuestros
								mayores.
							</Text>
						)}
						<DesktopSection>
							<Row style={{ marginBottom: 64 }}>
								<Button
									style={{ marginRight: 8 }}
									loading={loading}
									onClick={props.onNextClick}
								>
									Quiero ser socio/a
								</Button>
								<Button
									style={{
										backgroundColor:
											Color.background.deepBlueLow,
										color: Color.text.primary,
									}}
									loading={loading}
									icon={
										<Play
											height={18}
											width={18}
											fill={Color.text.primary}
											color={Color.text.primary}
										/>
									}
									onClick={() => setPlay(true)}
								>
									Ver video
								</Button>
							</Row>
						</DesktopSection>
						<Text
							type="p"
							weight="medium"
							style={{ marginBottom: 24 }}
						>
							Últimas personas que han colaborado:
						</Text>
						{users.map((payment, index) =>
							renderCell(payment, index)
						)}
					</DataView>
				</Section>
			</HeaderContainer>
		</>
	);
};
export default Header;
export interface Props {
	type?: "register";
	onNextClick: () => void;
}
