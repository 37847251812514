import { useState, useRef } from "react";
import {
	PaymentMethod,
	UserScheme,
	PaymentMethodScheme,
	ErrorScheme,
} from "client-v2";
import { PaymentRequestPaymentMethodEvent } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";

import Price, { PriceRef } from "../../components/section/Price";
import Paycards, { PaycardsRef } from "../../components/section/Paycards";
import Success from "./steps/SuccessStep";
import Resume from "./steps/Resume";
import Donation from "../../components/section/Donation";

import Wizard from "../../components/navigation/Wizard";

const Checkout = (props: Props) => {
	const dispatch = useDispatch();
	const { setShowError } = bindActionCreators(actionsCreators, dispatch);
	const currentUser = useSelector(
		(state: State) => state.user.currentUser
	);
	const location = useSelector((state: State) => state.location.location);

	const priceStep = useRef<PriceRef>(null);
	const paycardsStep = useRef<PaycardsRef>(null);

	const [step, setStep] = useState(0);
	const [paymentMethods, setPaymentsMethods] = useState<
		Array<PaymentMethodScheme>
	>([]);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [data, setData] = useState<any>({});

	const onNextClick = async () => {
		//Amount
		if (step === 0) {
			const newData = priceStep.current?.getData();
			if (newData && newData.price && newData.charge && currentUser) {
				setLoading(true);
				const result = await Donation.getProduct({
					user: currentUser,
					price: newData.price,
					charge: newData.charge,
					view: "web-donation",
				});
				setPaymentsMethods(result.paymentMethods);
				setData({
					...data,
					product: result.product,
					paymentMethod: result.defaultPaymentMethod,
				});
				setLoading(false);
				setStep(step + 1);
			}
		}
		//Checkout
		else if (step === 1) {
			setLoading(true);
			const newData = await paycardsStep.current?.getData();
			setLoading(false);
			//Paycard
			if (newData && newData.paymentMethod && newData.paymentMethod.id) {
				setData({ ...data, ...newData });
				if (currentUser) {
					initSubscription(currentUser, newData.paymentMethod);
				}
			}
			//Apple Pay
			else if (newData && newData.paymentRequest) {
				newData.paymentRequest.show();
				newData.paymentRequest.on("paymentmethod", async (ev) => {
					setData({
						...data,
						paymentMethod: {
							id: ev.paymentMethod.id,
							brand: "apple_pay",
						},
					});
					if (currentUser) {
						initSubscription(currentUser, ev.paymentMethod, ev);
					}
				});
			}
			//Sepa Debit
			else if (newData && newData.paymentMethod) {
				if (currentUser) {
					initSubscription(currentUser, {
						id: newData.paymentMethod.objectId,
						brand: "sepa_debit",
					});
				}
			} else {
				setShowError({
					show: true,
					message:
						"Debes añadir un método de pago para completar la compra de entradas",
				});
			}
		}
	};

	const onPaymentMethodClick = (paymentMethod: any) => {
		if (currentUser) {
			setData({
				...data,
				paymentMethod: paymentMethod,
			});
			if (
				paymentMethod.id !== "apple_pay" &&
				paymentMethod.id !== "google_pay"
			) {
				setLoading(true);
				PaymentMethod.setDefault(paymentMethod.objectId, {
					userId: currentUser.objectId,
				})
					.then(async () => {
						if (currentUser) {
							const result = await Donation.getPaycards(
								currentUser.objectId,
								data.product.charge
							);
							setPaymentsMethods(result.paymentMethods);
							setData({
								...data,
								paymentMethod: result.defaultPaymentMethod,
							});
							setLoading(false);
						}
					})
					.catch((error: ErrorScheme) => {
						setShowError({ show: true, message: error.message });
						setLoading(false);
					});
			}
		}
	};

	const initSubscription = async (
		user: UserScheme,
		paymentMethod: any,
		paymentRequestEvent?: PaymentRequestPaymentMethodEvent
	) => {
		setLoading(true);
		const result = await Donation.initSubscription({
			user: user,
			product: data.product,
			currency: (location && location.currency) ? location.currency.name : "eur",
			paymentMethod: paymentMethod,
			paymentRequestEvent: paymentRequestEvent,
			view: "web-donation",
		});
		//Error
		if (result.error) {
			setShowError({ show: true, message: result.error });
			setLoading(false);
		}
		//Need 3d secure confirmation
		else if (result.result) {
			paycardsStep.current?.confirmPaymentIntent(result.result);
		}
		//Finish confirmation
		else {
			setSuccess(true);
			setLoading(false);
		}
	};

	return (
		<Wizard
			tag={"register-donation"}
			location={location}
			loginIndex={0}
			step={step}
			titles={[
				"",
				data.product?.charge === "one"
					? "Método de pago"
					: "Cuenta bancaria",
			]}
			subtitles={[
				"",
				data.product?.charge === "one"
					? "Introduce el método de pago con el que realizarás la donación."
					: "Introduce el IBAN donde quieres que domiciliemos tu donación.",
			]}
			onError={(error: string) =>
				setShowError({ show: true, message: error })
			}
			onStepChange={(step: number) => {
				setStep(step);
			}}
			buttonProps={{
				loading: loading,
				success: success,
				onClick: onNextClick,
				onSuccess: () => setStep(step + 1),
			}}
			RightViews={[
				<Resume
					image={require("../../assets/images/background/register/back_video.webp")}
				/>,
				<Resume
					image={require("../../assets/images/background/register/back_vision.webp")}
				/>,
				undefined,
			]}
			LeftViews={[
				<Price
					ref={priceStep}
					currency={"eur"}
					language={"es"}
					options={["monthly", "annually", "one"]}
				/>,
				<Paycards
					ref={paycardsStep}
					paymentMethods={paymentMethods}
					buttonProps={{
						children:
							data.paymentMethod?.brand === "apple_pay" ||
							data.paymentMethod?.brand === "google_pay"
								? "Donar con"
								: data.product
								? "Donar " +
								  data.product.price +
								  " €" +
								  (data.product.charge === "monthly"
										? "/mes"
										: data.product.charge === "annually"
										? "/año"
										: "")
								: "",
						width:
							data.paymentMethod?.brand === "apple_pay" ||
							data.paymentMethod?.brand === "google_pay"
								? 200
								: data.product
								? data.product.charge === "monthly"
									? 200
									: data.product.charge === "annually"
									? 200
									: 160
								: 220,
						request: data.paymentMethod?.brand,
					}}
					currency={"eur"}
					countryCode={"ES"}
					currentUser={currentUser}
					amount={data.product ? data.product.price : 0}
					charge={data.product ? data.product.charge : "one"}
					onPaymentMethodClick={onPaymentMethodClick}
					onSetupConfirmed={(
						paymentMethodId?: string,
						error?: string
					) => {
						if (error) {
							setShowError({ show: true, message: error });
							setLoading(false);
						} else {
							setSuccess(true);
							setLoading(false);
						}
					}}
				/>,
				<Success user={currentUser} />,
			]}
		/>
	);
};
export default Checkout;
export interface Props {}
