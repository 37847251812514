import styled from "styled-components";
import { ChallengeScheme } from "client-v2";
import moment from "moment";

import Cell from "./Cell";
import {
	Text,
	ColorV2,
	Button,
	Counter,
} from "@adoptaunabuelo/react-components";
import { Cake } from "lucide-react";

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	color: white;
`;

const BirthdayCell = (props: Props) => {
	return (
		<Cell challenge={props.challenge}>
			<Text
				type="h3"
				weight="semibold"
				style={{ color: "white", marginBottom: 24 }}
			>
				{props.challenge.title}
			</Text>
			<Counter
				height={52}
				width={36}
				amount={props.letters}
				color={ColorV2.text.neutralHard}
			/>
			<Text type="h6" style={{ color: "white", marginTop: 4 }}>
				felicitaciones
			</Text>
			<Row style={{ marginTop: 24, marginBottom: 40 }}>
				<Cake height={20} width={20} />
				<Text type="p" style={{ color: "white" }}>
					¡Quedan{" "}
					{moment(props.challenge.endDate.iso).diff(moment(), "day")}{" "}
					días!
				</Text>
			</Row>
			<Button
				style={{
					width: "fit-content",
					backgroundColor: "white",
					color: ColorV2.text.neutralHard,
				}}
				onClick={() =>
					window.open(
						"https://adoptaunabuelo.org/cumpleanios/?id=" +
							props.challenge.objectId,
						"_blank"
					)
				}
			>
				Quiero mandar mi felicitación
			</Button>
		</Cell>
	);
};
export default BirthdayCell;
export interface Props {
	challenge: ChallengeScheme;
	letters: number;
}
