import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Player } from "@lottiefiles/react-lottie-player";

import checkAnimation from "../../assets/animations/check-complete.json";
import { ProgressBar, Color, Text } from "@adoptaunabuelo/react-components";
import media from "styled-media-query";

const Container = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
	overflow-x: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
	::-webkit-scrollbar {
		display: none;
	}
	${media.lessThan("medium")`
        margin: 0px -32px;
        padding: 0px 32px;
    `}
    ${media.lessThan("small")`
        margin: 0px -24px;
        padding: 0px 24px;
    `}
`;
const Cell = styled.div`
	cursor: pointer;
`;
const ImageView = styled.div`
	display: flex;
	position: relative;
	border-radius: 6px;
	overflow: hidden;
`;
const Image = styled.img`
	width: 130px;
	height: 96px;
	object-fit: cover;
`;
const BackgroundView = styled.div<{ $show: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background-color: rgba(0, 0, 0, 0.48);
	opacity: ${(props) => (props.$show ? 1 : 0)};
	transition: opacity 0.3s ease-out;
`;
const HoverView = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background-color: ${Color.background.deepBlueLow};
	opacity: 0;
	transition: opacity 0.3s ease-out;
	cursor: pointer;
	padding: 12px;
	&:hover {
		opacity: 1;
	}
`;

const Background = (props: BackgroundProps) => {
	const [hidden, setHidden] = useState(true);

	useEffect(() => {
		setTimeout(function () {
			//Auto scroll
			if (props.increase && props.progress) {
				const element = document.getElementById(
					"progress-cell-" + props.index
				);
				if (element) element.scrollIntoView({ behavior: "smooth" });
			} else if (!props.increase) {
				const element = document.getElementById(
					"progress-cell-" + (props.index - 1)
				);
				if (element) element.scrollIntoView({ behavior: "smooth" });
			}

			//Show / hide background
			if (props.completed) {
				setHidden(false);
			} else {
				setHidden(true);
			}
		}, props.delay);
	}, [props.completed]);

	return (
		<BackgroundView $show={!hidden}>
			{!hidden && (
				<Player
					style={{ height: 42, width: 42 }}
					src={checkAnimation}
					loop={false}
					keepLastFrame={true}
					autoplay={true}
				/>
			)}
		</BackgroundView>
	);
};

const SectionProgressBar = (props: Props) => {
	const scrollView = useRef<HTMLDivElement>(null);
	const [values, setValues] = useState<
		Array<{
			minValue: number;
			maxValue: number;
			progress: number;
			defaultProgress: number;
		}>
	>([]);
	const [prevProgress, setPrevProgress] = useState(props.progress);
	const [increase, setIncrease] = useState(true);

	useEffect(() => {
		let maxValue = 0;
		const result = props.options.map((item, index) => {
			const amount = item.amount.eur;
			const minValue = maxValue;
			maxValue = maxValue + amount;
			const defaultProgress =
				props.defaultProgress < minValue
					? 0
					: props.defaultProgress - minValue;
			const progress =
				props.progress >= maxValue
					? maxValue - defaultProgress - minValue
					: props.progress - minValue - defaultProgress;
			return {
				minValue: minValue,
				maxValue: maxValue,
				defaultProgress: defaultProgress,
				progress: progress > 0 ? progress : 0,
			};
		});
		setIncrease(prevProgress > props.progress ? false : true);
		setPrevProgress(props.progress);
		setValues(result);
	}, [props.options, props.progress, props.defaultProgress]);

	return (
		<Container ref={scrollView} style={props.style}>
			{values.length === props.options.length &&
				props.options.map((item, index) => {
					const value = values[index];
					const completed =
						value.progress + value.defaultProgress >=
						value.maxValue - value.minValue;
					const animationDelay = props.animationTime
						? increase
							? props.animationTime * index
							: props.animationTime *
							  (props.options.length - index)
						: 0;
					return (
						<Cell
							id={"progress-cell-" + index}
							key={"progress-cell-" + index}
						>
							<ImageView>
								<Image src={item.image} />
								<Background
									index={index}
									delay={animationDelay * 1000}
									completed={completed}
									increase={increase}
									progress={
										value.defaultProgress || value.progress
											? true
											: false
									}
								/>
								<HoverView>
									<Text
										type="p"
										style={{
											textAlign: "center",
											fontSize: 13,
										}}
									>
										{item.title}
									</Text>
								</HoverView>
							</ImageView>
							<ProgressBar
								style={{ marginTop: 8, height: 8 }}
								minValue={value.minValue}
								maxValue={value.maxValue}
								animationTime={props.animationTime}
								animationDelay={animationDelay}
								progress={[
									{
										value: value.defaultProgress,
										color: Color.background.deepBlue,
									},
									{
										value: value.progress,
										color: Color.background.primary,
									},
								]}
							/>
						</Cell>
					);
				})}
		</Container>
	);
};
export default SectionProgressBar;
export interface Props {
	style?: any;
	progress: number;
	defaultProgress: number;
	options: Array<{
		title: string;
		image?: string;
		amount: {
			eur: number;
			usd: number;
		};
	}>;
	animationTime?: number;
}
export interface BackgroundProps {
	delay: number;
	completed: boolean;
	index: number;
	increase: boolean;
	progress: boolean;
}
