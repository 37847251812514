import { Button, ColorV2, Modal, Text } from "@adoptaunabuelo/react-components";
import { useSelector } from "react-redux";
import { State } from "../../../redux";
import styled from "styled-components";
import media from "styled-media-query";

const Row = styled.div`
	display: flex;
	flex-direction: row;
	${media.lessThan("medium")`
        flex-direction: column;
	`}
`;
const Col = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;
const ImageView = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 24px;
`;
const Image = styled.img`
	height: 242px;
	width: 288px;
	border-radius: 12px;
`;
const LetterImage = styled.img`
	width: 208px;
	height: 189px;
	${media.lessThan("medium")`
        display: none;
	`}
`;
const SignatureImage = styled.img`
	width: 110px;
	height: 36px;
	margin-top: 8px;
	${media.lessThan("medium")`
        display: none;
	`}
`;

const PresentModal = (props: PresentModalProps) => {
	const isMobile = useSelector((state: State) => state.screen.isMobile);
	const appLocation = useSelector((state: State) => state.location.location);
	const translation = useSelector(
		(state: State) => state.location.translation
	);
	return (
		<Modal
			{...props}
			style={{ width: 768 }}
			type={isMobile ? "full-screen" : "default"}
		>
			<Row style={{ gap: 32 }}>
				<ImageView>
					<Image
						src={require("../../../assets/images/letter/miki-noelle-mug.webp")}
					/>
					<LetterImage
						src={require("../../../assets/images/letter/miki-noelle-letter.webp")}
					/>
					<SignatureImage
						src={require("../../../assets/images/letter/miki-noelle-signature.webp")}
					/>
				</ImageView>
				<Col>
					<Text
						type="h3"
						weight="semibold"
						style={{ marginBottom: 16 }}
					>
						{translation.payment_modal_mug_title}
					</Text>
					<Text type="p" style={{ marginBottom: 16 }}>
						Si donas{" "}
						{appLocation
							? new Intl.NumberFormat(appLocation.language, {
									style: "currency",
									currency: appLocation.currency.name,
									maximumFractionDigits: 0,
							  }).format(
									appLocation.currency.price.annually.gift
							  )
							: "200€"}{" "}
						al año o{" "}
						{appLocation
							? new Intl.NumberFormat(appLocation.language, {
									style: "currency",
									currency: appLocation.currency.name,
									maximumFractionDigits: 0,
							  }).format(appLocation.currency.price.monthly.gift)
							: "20€"}{" "}
						al mes, nos aseguramos de que una persona mayor esté
						acompañada durante todo el año.
					</Text>
					<Text
						type="p"
						style={{ marginBottom: 16 }}
						weight={"semibold"}
					>
						{translation.payment_modal_mug_text_02}
					</Text>
					<Text type="p" style={{ marginBottom: 32 }}>
						{translation.payment_modal_mug_text_03}
					</Text>
					<Button
						style={{
							marginBottom: 16,
							backgroundColor: ColorV2.surface.primaryHard,
						}}
						onClick={props.onClick}
					>
						{translation.payment_modal_mug_button}
					</Button>
				</Col>
			</Row>
		</Modal>
	);
};
export default PresentModal;
export interface PresentModalProps {
	isVisible: boolean;
	onClick: () => void;
	onClose: () => void;
}
