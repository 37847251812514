import { Player } from '@lottiefiles/react-lottie-player';
import { Modal } from '@adoptaunabuelo/react-components';

import animation from '../../assets/animations/main-loading.json';

const Loading = (props: Props) =>{
    return(
        <Modal
            isVisible={props.isVisible}
            onClose={() => null}
            style={{minWidth: 100, width: 100, height: 100, justifyContent: 'center', alignItems: 'center', display: 'flex'}}
            contentStyle={{padding: 0}}
            hideHeader={true}
            hideClose={true}
        >
            <Player
                style={{height: 150, width: 150}}
                loop={true}
                autoplay={true}
                src={animation}
            />
        </Modal>
    )
}
export default Loading;
export interface Props{
    isVisible: boolean
}