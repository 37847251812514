import { forwardRef, useState, useImperativeHandle, Ref } from "react";
import styled from "styled-components";
import { Regex } from "client-v2";
import media from "styled-media-query";

import SectionContainer from "../container/SectionContainer";
import { Input, Text, Checkbox, Color } from "@adoptaunabuelo/react-components";

const Row = styled.div`
	display: flex;
	flex-direction: row;
	gap: 12px;
	${media.lessThan("small")`
        flex-direction: column;
    `}
`;
const TermsText = styled(Text)`
	${media.lessThan("small")`
        font-size: 14px !important;
    `}
`;
const Link = styled.a`
	color: ${Color.text.primary};
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
`;

const Personal = forwardRef((props: Props, ref: Ref<PersonalRef>) => {
	const [name, setName] = useState<string | undefined>(undefined);
	const [surname, setSurname] = useState<string | undefined>(undefined);
	const [email, setEmail] = useState<string | undefined>(undefined);
	const [terms, setTerms] = useState(false);
	const [nameError, setNameError] = useState<string | undefined>(undefined);
	const [surnameError, setSurnameError] = useState<string | undefined>(
		undefined
	);
	const [emailError, setEmailError] = useState<string | undefined>(undefined);
	const [termsError, setTermsError] = useState<boolean>(false);

	useImperativeHandle(ref, () => ({
		getData() {
			if (name && surname && email && terms) {
				const emailIsValid = Regex.email(email);
				if (emailIsValid) {
					setEmailError(undefined);
					setNameError(undefined);
					setSurname(undefined);
					setTermsError(false);
					return {
						name: name,
						surname: surname,
						email: email,
					};
				} else {
					if (!emailIsValid)
						setEmailError("Debes añadir un email válido");
					return undefined;
				}
			} else {
				if (!name) setNameError("Debes añadir tu nombre");
				if (!surname) setSurnameError("Debes añadir tu apellido");
				if (!email) setEmailError("Debes añadir tu email");
				if (!terms) setTermsError(true);
				return undefined;
			}
		},
	}));

	const onInputChange = (input: string, value: string) => {
		if (input === "name") {
			setName(value);
			setNameError(undefined);
		} else if (input === "surname") {
			setSurname(value);
			setSurnameError(undefined);
		} else if (input === "email") {
			setEmail(value);
			setEmailError(undefined);
		}
	};

	const onTermsChange = (selection: Array<any>) => {
		setTermsError(false);
		if (selection.length > 0) setTerms(true);
		else setTerms(false);
	};

	return (
		<SectionContainer title={props.title}>
			<Row>
				<Input
					type="text"
					containerStyle={{ flex: 1 }}
					placeholder={"Nombre"}
					error={nameError}
					design={"secondary"}
					onChange={(e: any) => onInputChange("name", e.target.value)}
				/>
				<Input
					type="text"
					containerStyle={{ marginBottom: 12, flex: 1 }}
					placeholder={"Apellidos"}
					error={surnameError}
					design={"secondary"}
					onChange={(e: any) =>
						onInputChange("surname", e.target.value)
					}
				/>
			</Row>
			<Input
				containerStyle={{ marginBottom: 12 }}
				placeholder={"Email"}
				error={emailError}
				type="email"
				icon={undefined}
				design={"secondary"}
				onChange={(e: any) => onInputChange("email", e.target.value)}
			/>
			<Checkbox
				style={{ marginTop: 32 }}
				options={[
					{
						id: "terms",
						Element: (
							<TermsText type="p">
								Acepto los{" "}
								<Link
									href="https://www.adoptaunabuelo.org/terminos-condiciones"
									target="_blank"
								>
									Términos y condiciones de uso
								</Link>{" "}
								y la{" "}
								<Link
									href="https://www.adoptaunabuelo.org/politica-privacidad"
									target="_blank"
								>
									Política de Privacidad
								</Link>
							</TermsText>
						),
						error: termsError,
					},
				]}
				type={"multiple"}
				onChange={onTermsChange}
			/>
		</SectionContainer>
	);
});
export default Personal;
export interface Props {
	title?: string;
}
export interface PersonalRef {
	getData: () =>
		| undefined
		| {
				name: string;
				surname: string;
				email: string;
		  };
}
