import { useEffect, useState } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { ProductScheme, EventScheme } from "client-v2";
import moment from "moment";

import { Text, Color, ColorV2 } from "@adoptaunabuelo/react-components";
import * as icons from "lucide-react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import Timing from "../../../components/cell/Timing";

const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
const MobileSection = styled.div`
	display: none;
	flex: 1;
	flex-direction: column;
	gap: 24px;
	padding: 24px 0px;
	margin-top: 24px;
	border-top: 1px solid ${Color.line.soft};
	border-bottom: 1px solid ${Color.line.soft};
	${media.lessThan("medium")`
        display: flex;
    `}
`;
const Section = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-top: 48px;
`;
const BulletContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	gap: 16px;
	flex-wrap: wrap;
	${media.lessThan("medium")`
        gap: 8px;
    `}
`;
const BulletCell = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 180px;
	flex: 3;
	padding: 16px;
	border-radius: 12px;
	background-color: ${ColorV2.surface.background};
	${media.lessThan("medium")`
        
    `}
	${media.lessThan("small")`
        flex: 2;
        min-width: 30%;
    `}
`;
const MapView = styled.div`
	position: relative;
	height: 356px;
	border-radius: 12px;
	overflow: hidden;
	margin-top: 32px;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const Column = styled.div`
	display: flex;
	flex-direction: column;
`;
const CheckIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 24px;
	width: 24px;
	background-color: ${Color.background.primaryLow};
	border-radius: 30px;
	margin-right: 12px;
`;

const Info = (props: Props) => {
	const iconsT: any = icons;
	const Check = iconsT["Check"];
	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: "AIzaSyA_H7WVmlnxy8OWrNuIJmGclYWwXFB49Wk",
	});

	const [pastDue, setPastDue] = useState(false);

	useEffect(() => {
		const pastDue = props.product.startDate
			? moment() > moment(props.product.startDate.iso)
			: false;
		setPastDue(pastDue);
	}, []);

	return (
		<Container>
			<MobileSection>
				<Row style={{ gap: 12 }}>
					<icons.Calendar height={24} width={24} />
					<Column>
						<Text type="p" weight="medium">
							{moment(props.product.startDate?.iso).format(
								"ddd DD MMMM YYYY"
							)}
						</Text>
						<Text type="p2" style={{ color: Color.text.high }}>
							de{" "}
							{moment(props.product.startDate?.iso).format(
								"HH:mm"
							)}{" "}
							a{" "}
							{moment(props.product.endDate?.iso).format("HH:mm")}
						</Text>
					</Column>
				</Row>
				<Row style={{ gap: 12 }}>
					<icons.MapPin height={24} width={24} />
					<Column>
						<Text type="p" weight="medium">
							{props.event.shortAddress}
						</Text>
						<Text type="p2" style={{ color: Color.text.high }}>
							{props.event.address}
						</Text>
					</Column>
				</Row>
			</MobileSection>
			<Section>
				<Text type="h4" weight="semibold" style={{ marginBottom: 24 }}>
					Acerca de este evento
				</Text>
				<Text type="p" style={{ whiteSpace: "pre-wrap" }}>
					{props.event.description}
				</Text>
			</Section>
			{props.event.timeline && (
				<Section>
					<Text
						type="h4"
						weight="semibold"
						style={{ marginBottom: 24 }}
					>
						Horario
					</Text>
					<Timing data={props.event.timeline} />
				</Section>
			)}
			{props.event.bullet && (
				<Section>
					<Text
						type="h4"
						weight="semibold"
						style={{ marginBottom: 24 }}
					>
						Qué incluye
					</Text>
					<BulletContainer>
						{props.event.bullet.map((item, index: number) => {
							const Icon = iconsT[item.icon];
							return (
								<BulletCell key={"bullet-" + index}>
									<Icon style={{ marginBottom: 12 }} />
									<Text type="p" weight="medium">
										{item.title}
									</Text>
									<Text
										type="c1"
										style={{ color: Color.text.high }}
									>
										{item.subtitle}
									</Text>
								</BulletCell>
							);
						})}
					</BulletContainer>
				</Section>
			)}
			<Section>
				<Text type="h4" weight="semibold" style={{ marginBottom: 4 }}>
					¿Dónde es?
				</Text>
				<Text type="p">{props.event.address}</Text>
				<MapView>
					{isLoaded ? (
						<GoogleMap
							mapContainerStyle={{
								position: "absolute",
								top: 0,
								right: 0,
								left: 0,
								bottom: 0,
							}}
							center={{
								lat: props.event.location.latitude,
								lng: props.event.location.longitude,
							}}
							zoom={14}
							clickableIcons={false}
							options={{
								disableDefaultUI: true,
								disableDoubleClickZoom: true,
								draggable: false,
							}}
						>
							<Marker
								position={{
									lat: props.event.location.latitude,
									lng: props.event.location.longitude,
								}}
								icon={{
									url: require("../../../assets/images/icon/map_pin.png"),
								}}
							/>
						</GoogleMap>
					) : null}
				</MapView>
			</Section>
			{(!props.product.data || !props.product.data.corporate) && (
				<Section>
					<Text
						type="h4"
						weight="semibold"
						style={{ marginBottom: 24 }}
					>
						Ten en cuenta
					</Text>
					<Column>
						<Text
							type="h5"
							weight="medium"
							style={{ marginBottom: 16 }}
						>
							Requisitos para los asistentes
						</Text>
						<Row style={{ marginBottom: 16 }}>
							<CheckIcon>
								<Check
									color={Color.text.primary}
									height={18}
									width={18}
									strokeWidth={3}
								/>
							</CheckIcon>
							<Text type="p" style={{ flex: 1 }}>
								Los menores de 16 años tendrán que ir
								acompañados por padres o tutores.
							</Text>
						</Row>
						<Row style={{ marginBottom: 16 }}>
							<CheckIcon>
								<Check
									color={Color.text.primary}
									height={18}
									width={18}
									strokeWidth={3}
								/>
							</CheckIcon>
							<Text type="p" style={{ flex: 1 }}>
								Si el evento es en residencia se aplicarán las
								medidas de seguridad de la residencia.
							</Text>
						</Row>
						<Row>
							<CheckIcon>
								<Check
									color={Color.text.primary}
									height={18}
									width={18}
									strokeWidth={3}
								/>
							</CheckIcon>
							<Text type="p" style={{ flex: 1 }}>
								Informa a Adopta Un Abuelo sobre cualquier
								alergia o restricción alimentaria.
							</Text>
						</Row>
						{props.product.price > 0 && (
							<>
								<Text
									type="h5"
									weight="medium"
									style={{ marginBottom: 16, marginTop: 24 }}
								>
									Política de cancelación
								</Text>
								<Text type="p">
									Cancela hasta 48 horas antes de que empiece
									la experiencia para obtener el reembolso
									completo.
								</Text>
							</>
						)}
					</Column>
				</Section>
			)}
		</Container>
	);
};
export default Info;
export interface Props {
	product: ProductScheme;
	event: EventScheme;
}
