import {
	forwardRef,
	Ref,
	useEffect,
	useImperativeHandle,
	useState,
	useRef,
} from "react";
import styled from "styled-components";
import {
	AUA,
	Payment,
	PaymentMethod,
	CoorporateScheme,
	PaymentMethodScheme,
	UserScheme,
} from "client-v2";
import { loadStripe, PaymentRequest } from "@stripe/stripe-js";
import { useStripe } from "@stripe/react-stripe-js";
import media from "styled-media-query";
import Stripe from "stripe";

import SectionContainer from "../container/SectionContainer";
import PaycardCell from "../cell/PaycardCell";
import {
	Text,
	Color,
	Payout,
	Modal,
	Input,
} from "@adoptaunabuelo/react-components";
import { ShieldCheck, Lock, User, Landmark } from "lucide-react";
import PaycardLogo from "../../assets/logo/Paycard";

const screenSize1: any = "1300px";

const Cell = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 16px;
	cursor: pointer;
	border-radius: 12px;
	margin-top: 4px;
	&:hover {
		background-color: ${Color.background.soft};
	}
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const Col = styled.div`
	display: flex;
	flex-direction: column;
`;
const DataRow = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	margin: 24px 0px 0px;
	padding-top: 18px;
	border-top: 1px solid ${Color.line.soft};
	justify-content: space-between;
	${media.lessThan(screenSize1)`
        flex-direction: column-reverse;
    `}
`;
const LockRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	${media.lessThan(screenSize1)`
        margin-top: 12px;
    `}
`;
const PaycardContainer = styled.div`
	display: flex;
	flex-direction: column;
	background-color: white;
	border-radius: 12px;
	padding: 4px;
	.cell:first-child {
		border-radius: 12px 12px 0px 0px;
	}
	.cell:last-child {
		border-bottom: none;
		border-radius: 0px 0px 12px 12px;
	}
`;

const PaycardsStep = forwardRef((props: Props, ref: Ref<PaycardsRef>) => {
	const payout = useRef<any>(null);
	const modal = useRef<any>(null);
	const stripeKey = AUA.getStripeKey();
	const stripe = useStripe();

	const [selectedPaycard, setSelectedPaycard] = useState<
		PaymentMethodScheme | undefined
	>(undefined);
	const [showAdd, setShowAdd] = useState(false);
	const [inputError, setInputError] = useState<string | undefined>(undefined);
	const [confirmUrl, setConfirmUrl] = useState<string | undefined>(undefined);
	const [paymentIntent, setPaymentIntent] = useState<
		Stripe.PaymentIntent | undefined
	>(undefined);
	const [setupIntent, setSetupIntent] = useState<
		Stripe.SetupIntent | undefined
	>(undefined);
	const [showModal, setShowModal] = useState(false);
	const [paymentRequest, setPaymentRequest] = useState<
		PaymentRequest | undefined
	>(undefined);
	const [canUseApplePay, setCanUseApplePay] = useState<
		"apple_pay" | "google_pay" | undefined
	>(undefined);
	const [applePaySelection, setApplePaySelection] = useState<
		"apple_pay" | "google_pay" | undefined
	>(undefined);
	const [showRemove, setShowRemove] = useState<
		PaymentMethodScheme | undefined
	>(undefined);
	const [loading, setLoading] = useState(false);
	const [modalError, setModalError] = useState<string | undefined>(undefined);
	const [IBAN, setIBAN] = useState<{
		account: string | undefined;
		name: string | undefined;
	}>({
		account: undefined,
		name: undefined,
	});

	useImperativeHandle(ref, () => ({
		async getData() {
			setInputError(undefined);
			if (IBAN.account && IBAN.name) {
				//check IBAN
				const ibanRegex =
					/^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/;
				const ibanAccount = IBAN.account
					.replace(/\s/g, "")
					.replace("-", "")
					.toUpperCase();
				if (ibanRegex.test(ibanAccount)) {
					//Save the payment method
					if (props.currentUser) {
						const result = await PaymentMethod.initSetupIntent({
							userId: props.currentUser.objectId,
							IBAN: ibanAccount,
							name: IBAN.name,
						});
						return {
							paymentMethod: result.data,
						};
					} else {
						setInputError("Hubo un error inesperado.");
						return undefined;
					}
				} else {
					setInputError(
						"Parece que el número de cuenta que has introducido no es correcto."
					);
					return undefined;
				}
			} else {
				const generatedPaymentMethod =
					await payout.current?.getPaymentMethod();

				//Use Apple Pay or Google Pay
				if (applePaySelection && paymentRequest) {
					return {
						paymentRequest: paymentRequest,
					};
				}
				//Use the new added card
				else if (generatedPaymentMethod) {
					return {
						paymentMethod: {
							id: generatedPaymentMethod.id,
							brand: generatedPaymentMethod.card.brand,
						},
					};
				}
				//Use an old selected paycard
				else if (selectedPaycard) {
					return {
						paymentMethod: {
							id: selectedPaycard.objectId,
							brand: selectedPaycard.brand,
						},
					};
				} else {
					return undefined;
				}
			}
		},
		async confirmPaymentIntent(paymentIntent: Stripe.PaymentIntent) {
			setPaymentIntent(paymentIntent);
			if (
				paymentIntent.next_action &&
				paymentIntent.next_action.redirect_to_url &&
				paymentIntent.next_action.redirect_to_url.url
			) {
				setConfirmUrl(paymentIntent.next_action.redirect_to_url.url);
				setShowModal(true);
			} else if (paymentIntent.client_secret) {
				const result = await stripe?.confirmCardPayment(
					paymentIntent.client_secret
				);
				if (result?.paymentIntent) {
					props.onSetupConfirmed();
				} else {
					onConfirmModalClose(paymentIntent);
				}
			}
		},
		async confirmSetupIntent(setupIntent: Stripe.SetupIntent) {
			setSetupIntent(setupIntent);
			if (
				setupIntent.next_action &&
				setupIntent.next_action.redirect_to_url &&
				setupIntent.next_action.redirect_to_url.url
			) {
				setConfirmUrl(setupIntent.next_action.redirect_to_url.url);
				setShowModal(true);
			} else if (setupIntent.client_secret) {
				const result = await stripe?.confirmCardSetup(
					setupIntent.client_secret
				);
				if (result?.setupIntent) {
					props.onSetupConfirmed();
				} else {
					setInputError(result?.error.message);
				}
			}
		},
	}));

	window.addEventListener(
		"message",
		function (ev) {
			if (ev.data === "3DS-authentication-complete" && showModal) {
				modal.current.close();
			}
		},
		false
	);

	useEffect(() => {
		if (!props.canEdit && props.amount && props.charge) {
			if (props.charge === "one") {
				loadStripe(stripeKey).then((stripe) => {
					if (stripe) {
						const paymentRequest = stripe.paymentRequest({
							currency: props.currency,
							country: props.countryCode,
							total: {
								label: "Donación Adopta Un Abuelo",
								amount: props.amount ? props.amount * 100 : 0,
							},
							requestPayerName: false,
							requestPayerEmail: false,
						});
						setPaymentRequest(paymentRequest);
						paymentRequest.canMakePayment().then((result) => {
							if (
								result &&
								(result.applePay || result.googlePay)
							) {
								setCanUseApplePay(
									result.applePay
										? "apple_pay"
										: result.googlePay
										? "google_pay"
										: undefined
								);
								setShowAdd(false);
							}
						});
					}
				});
			} else {
				setCanUseApplePay(undefined);
			}
		}
	}, [props.amount, props.canEdit, props.charge]);

	useEffect(() => {
		if (props.paymentMethods && props.paymentMethods.length) {
			setShowAdd(false);
			props.paymentMethods.map((item) => {
				if (item.default) {
					setSelectedPaycard(item);
				}
			});
		} else {
			setShowAdd(true);
		}
	}, [props.paymentMethods]);

	const onConfirmModalClose = async (
		paymentIntent?: Stripe.PaymentIntent,
		setupIntent?: Stripe.SetupIntent
	) => {
		if (paymentIntent && paymentIntent.id) {
			setShowModal(false);
			Payment.finishPaymentIntent(paymentIntent.id)
				.then((result) => {
					if (
						result.data.status === "succeeded" ||
						result.data.status === "processing"
					) {
						props.onSetupConfirmed && props.onSetupConfirmed();
					} else {
						setInputError(
							"Parece que hay un error con tus datos. Comprueba que has añadido todo de manera correcta."
						);
						props.onSetupConfirmed &&
							props.onSetupConfirmed(
								undefined,
								"Parece que la verificación del pago ha fallado. Inténtalo de nuevo."
							);
					}
				})
				.catch((error: string) => {
					setInputError(error);
					props.onSetupConfirmed &&
						props.onSetupConfirmed(undefined, error);
				});
		} else if (setupIntent && setupIntent.payment_method) {
			setShowModal(false);
			props.onSetupConfirmed(
				typeof setupIntent.payment_method === "string"
					? setupIntent.payment_method
					: setupIntent.payment_method.id
			);
		}
	};

	const removeCard = async (paycard: PaymentMethodScheme) => {
		if (props.currentUser) {
			setLoading(true);
			PaymentMethod.remove(paycard.objectId, {
				userId: props.currentUser.objectId,
			})
				.then(() => {
					setLoading(false);
					setShowRemove(undefined);
					props.onRemoveClick && props.onRemoveClick(paycard);
				})
				.catch((error: string) => {
					setLoading(false);
					setModalError(error);
				});
		}
	};

	return (
		<SectionContainer title={props.title}>
			<Modal
				isVisible={showRemove ? true : false}
				title="¿Seguro que quieres continuar?"
				subtitle="Este paso no se podrá deshacer."
				error={modalError}
				buttonProps={{
					loading: loading,
					children: "Eliminar método de pago",
					onClick: () =>
						showRemove ? removeCard(showRemove) : undefined,
				}}
				onClose={() => setShowRemove(undefined)}
			/>
			<Modal
				type="web"
				contentStyle={{ padding: 0, height: "100%" }}
				ref={modal}
				isVisible={showModal}
				hideClose={true}
				hideHeader={true}
				url={confirmUrl}
				style={{ width: 600, height: "100%", padding: 0 }}
				onClose={() => onConfirmModalClose(paymentIntent, setupIntent)}
			/>
			{showAdd ? (
				<>
					{props.charge === "one" ? (
						<Payout
							ref={payout}
							stripeKey={stripeKey}
							paymentOption={
								props.charge === "one" ? "card" : "sepa_debit"
							}
							design={"secondary"}
						/>
					) : (
						<Col style={{ gap: 8 }}>
							<Input
								type="text"
								design="secondary"
								placeholder="Nombre del titular"
								icon={
									<User
										height={18}
										width={18}
										color={Color.text.high}
									/>
								}
								onChange={(e) =>
									setIBAN({
										...IBAN,
										name: e.target.value,
									})
								}
							/>
							<Input
								type="text"
								design="secondary"
								placeholder="Número de cuenta bancaria"
								icon={
									<Landmark
										height={18}
										width={18}
										color={Color.text.high}
									/>
								}
								onChange={(e) =>
									setIBAN({
										...IBAN,
										account: e.target.value,
									})
								}
							/>
						</Col>
					)}
					{inputError && (
						<Text
							type="p"
							style={{
								marginTop: 12,
								color: Color.status.color.error,
								fontSize: 14,
							}}
						>
							{inputError}
						</Text>
					)}
				</>
			) : (
				<>
					<PaycardContainer>
						{props.paymentMethods.map(
							(item: PaymentMethodScheme) => (
								<PaycardCell
									key={"paycard-" + item.objectId}
									brand={item.brand}
									last4={item.last4}
									name={item.name}
									expDate={
										item.expDate
											? item.expDate.iso
											: undefined
									}
									default={
										applePaySelection ? false : item.default
									}
									canEdit={props.canEdit}
									color={
										props.coorporate &&
										props.coorporate.color &&
										props.coorporate.color.main
									}
									onClick={() => {
										setApplePaySelection(undefined);
										props.onPaymentMethodClick(item);
									}}
									onRemoveClick={() => {
										setModalError(undefined);
										setShowRemove(item);
									}}
								/>
							)
						)}
						{canUseApplePay && !props.canEdit && (
							<PaycardCell
								key={canUseApplePay}
								style={{ marginBottom: 8 }}
								brand={canUseApplePay}
								last4={"Pay"}
								default={applePaySelection ? true : false}
								color={
									props.coorporate &&
									props.coorporate.color &&
									props.coorporate.color.main
								}
								onClick={() => {
									setApplePaySelection(canUseApplePay);
									props.onPaymentMethodClick({
										id: canUseApplePay,
										brand: canUseApplePay,
									});
								}}
							/>
						)}
					</PaycardContainer>
					<Cell onClick={() => setShowAdd(!showAdd)}>
						<Text
							type="p"
							style={{
								color: props.coorporate?.color
									? props.coorporate.color.main
									: Color.text.primary,
								fontSize: 24,
								height: 36,
								borderRadius: 6,
								width: 36,
								backgroundColor: "white",
								alignItems: "center",
								justifyContent: "center",
								display: "flex",
							}}
						>
							+
						</Text>
						<Text
							type="p"
							weight="medium"
							style={{
								color: props.coorporate?.color
									? props.coorporate.color.main
									: Color.text.primary,
								marginLeft: 16,
							}}
						>
							Añadir nuevo método de pago
						</Text>
					</Cell>
				</>
			)}
			<DataRow>
				<Col>
					<LockRow>
						<ShieldCheck
							style={{ marginRight: 8, marginTop: -2 }}
							height={20}
							width={20}
							color={Color.text.high}
						/>
						<Text
							type="p2"
							style={{ color: Color.text.high }}
							weight="medium"
						>
							Pago seguro garantizado
						</Text>
					</LockRow>
					<LockRow style={{ marginTop: 12 }}>
						<Lock
							style={{ marginRight: 8, marginTop: -2 }}
							height={20}
							width={20}
							color={Color.text.high}
						/>
						<Text
							type="p2"
							style={{ color: Color.text.high }}
							weight="medium"
						>
							Encriptación SSL protegida
						</Text>
					</LockRow>
				</Col>
				{props.charge === "one" && (
					<Row style={{ gap: 4 }}>
						<PaycardLogo
							brand={"visa"}
							style={{ height: 24, width: 36, borderRadius: 6 }}
						/>
						<PaycardLogo
							brand={"mastercard"}
							style={{ height: 24, width: 36, borderRadius: 6 }}
						/>
						<PaycardLogo
							brand={"amex"}
							style={{ height: 24, width: 36, borderRadius: 6 }}
						/>
						<PaycardLogo
							brand={"apple_pay"}
							style={{ height: 24, width: 36, borderRadius: 6 }}
						/>
						<PaycardLogo
							brand={"google_pay"}
							style={{ height: 24, width: 36, borderRadius: 6 }}
						/>
					</Row>
				)}
			</DataRow>
		</SectionContainer>
	);
});
export default PaycardsStep;
export interface Props {
	title?: string;
	paymentMethods: Array<PaymentMethodScheme>;
	canEdit?: boolean;
	buttonProps?: {
		children: string;
		width: number | string;
		request?: string;
	};
	amount?: number;
	charge: "one" | "monthly" | "annually";
	currency: string;
	countryCode: string;
	currentUser?: UserScheme;
	coorporate?: CoorporateScheme;
	onPaymentMethodClick: (paymentMethod: any) => void;
	onSetupConfirmed: (paymentMethodId?: string, error?: string) => void;
	onRemoveClick?: (paymentMethod: PaymentMethodScheme) => void;
}
export interface PaycardsRef {
	getData: () => Promise<
		| undefined
		| {
				paymentMethod?: any;
				paymentRequest?: PaymentRequest;
		  }
	>;
	confirmSetupIntent: (setupIntent: Stripe.SetupIntent) => void;
	confirmPaymentIntent: (paymentIntent: Stripe.PaymentIntent) => void;
}
