import { useState, forwardRef, useImperativeHandle, useEffect, Ref } from 'react';
import Geocode from "react-geocode"; 
import axios from 'axios';

import SectionContainer from '../container/SectionContainer';
import { Color, Input } from '@adoptaunabuelo/react-components';
import { Search } from 'lucide-react';

const LocationStep = forwardRef((props: LocationProps, ref: Ref<LocationRef>) => {

    const googleAPIKey = "AIzaSyA_H7WVmlnxy8OWrNuIJmGclYWwXFB49Wk";

    const [ searchText, setSearchText ] = useState<string | undefined>(undefined);
    const [ sortAddress, setSortAddress ] = useState<string | undefined>(undefined);
    const [ city, setCity ] = useState<string | undefined>(undefined);
    const [ country, setCountry ] = useState<string | undefined>(undefined);
    const [ postalCode, setPostalCode ] = useState<string | undefined>(undefined);
    const [ timeZone, setTimeZone ] = useState<string | undefined>(undefined);
    const [ inputError, setInputError ] = useState(false);
    const [ coordinates, setCoordinates ] = useState<undefined | google.maps.LatLngLiteral>(undefined);

    useImperativeHandle(ref, () => ({
        getData() {
            return getData({
                address: searchText,
                coordinates: coordinates,
                shortAddress: sortAddress,
                country: country,
                city: city,
                timeZone: timeZone,
                postalCode: postalCode
            });
        }
    }));

    useEffect(() =>{
        Geocode.setApiKey(googleAPIKey);
    },[]);

    useEffect(() =>{
        if(searchText && coordinates &&  sortAddress && country && timeZone){
            const result = getData({
                address: searchText,
                coordinates: coordinates,
                shortAddress: sortAddress,
                country: country,
                city: city,
                timeZone: timeZone,
                postalCode: postalCode
            });
            props.onEnter && props.onEnter(result);
        }
    }, [searchText, coordinates, sortAddress, city, country, timeZone, postalCode]);

    const getData = (data: {
        address?: string, 
        coordinates?: google.maps.LatLngLiteral,
        shortAddress?: string,
        country?: string,
        city?: string,
        timeZone?: string,
        postalCode?: string
    }): {
        address: string, 
        coordinates: google.maps.LatLngLiteral,
        shortAddress: string,
        country: string,
        city?: string,
        timeZone: string,
        postalCode?: string
    } | undefined =>{
        if(data.address && data.coordinates && data.country && data.shortAddress && data.timeZone){
            return {
                address: data.address, 
                coordinates: data.coordinates,
                shortAddress: data.shortAddress,
                country: data.country,
                city: data.city,
                timeZone: data.timeZone,
                postalCode: data.postalCode
            }
        }
        else{
            setInputError(true);
            return undefined;
        }
    }

    const onLocationChange = async (item: {
        address: string,
        geocoder: google.maps.GeocoderResult
        location: google.maps.LatLngLiteral
    }) => {
        setInputError(false);
        setCoordinates(item.location);
        setSearchText(item.address);

        const locality = item.geocoder.address_components.filter(it => it.types.includes("locality"));
        const city = item.geocoder.address_components.filter(it => it.types.includes("administrative_area_level_2"));
        const country = item.geocoder.address_components.filter(it => it.types.includes("country"));
        const postal_code = item.geocoder.address_components.filter(it => it.types.includes("postal_code"));
        const sortAddress = (locality.length > 0 ? locality[0].long_name+', ' : '')+(city.length > 0 ? city[0].long_name+', ' : '')+country[0].long_name;
        setCountry(country[0].short_name);
        setCity(city.length > 0 ? city[0].long_name : undefined);
        setSortAddress(sortAddress);
        setPostalCode(postal_code.length > 0 ? postal_code[0].long_name : undefined);

        //Get the timezone
        const result2 = await axios({
            method: 'GET',
            url: 'https://maps.googleapis.com/maps/api/timezone/json?location='+item.location.lat+'%2C'+item.location.lng+'&timestamp=1331161200&key='+googleAPIKey
        });
        if(result2.data.status === 'OK'){
            setTimeZone(result2.data.timeZoneId);
        }
    }

    return(
        <SectionContainer
            title={props.title}
        >
            <Input
                placeholder='Dirección aproximada'
                containerStyle={{marginBottom: 0}}
                type='location'
                design='secondary'
                value={searchText}
                error={inputError ? "Añade una dirección válida" : undefined}
                icon={<Search height={20} width={20} color={Color.text.high} style={{marginRight: 8}}/>}
                onLocationChange={onLocationChange}
            />
        </SectionContainer>
    )
});

export default LocationStep;
export interface LocationProps{
    title?: string,
    onEnter?: (result: undefined | {
        address: string, 
        coordinates: google.maps.LatLngLiteral,
        shortAddress: string,
        country: string,
        timeZone: string,
        city?: string,
        postalCode?: string
    }) => void
}
export interface LocationRef{
    getData: () => undefined | {
        address: string, 
        coordinates: google.maps.LatLngLiteral,
        shortAddress: string,
        country: string,
        timeZone: string,
        city?: string,
        postalCode?: string
    }
}