import React from "react";

const SvgBack = (props: Props) => {
    return (
        <svg style={props.style}  width="129" height="80" viewBox="0 0 129 80" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_14664_39535)">
<path d="M13.03 12.9995C13.0588 13.2529 13.0464 13.5133 13.0314 13.7695C12.9121 15.9304 12.93 17.4048 12.8108 19.5656C12.7833 20.063 12.7929 21.2716 12.2161 21.832C11.4789 22.5473 9.69346 22.0417 8.65482 21.917C6.48436 21.6552 4.55643 21.1428 2.42708 20.766C2.07767 20.7043 1.06233 20.3193 1.25827 19.3354C1.45422 18.3516 2.35309 18.5626 3.03547 18.6284C5.04972 18.8243 6.87762 19.3669 8.8261 19.5697C7.92996 18.4407 6.92147 16.9211 6.08973 15.6837C5.18263 14.3341 4.3509 12.9241 3.74251 11.4127C2.73128 8.89422 1.88447 6.29213 1.90776 1.80186C1.91325 1.12497 1.8968 0 2.94915 0C3.53698 0 3.84802 0.472732 3.83432 1.06056C3.68633 7.22527 5.34569 10.5961 7.06671 13.331C7.89845 14.652 10.2991 18.0515 10.7965 18.3393C10.8116 17.5089 10.8527 16.6772 10.8801 15.8687C10.9075 15.0274 10.8513 14.1888 10.9856 13.3571C11.0377 13.0296 11.1021 12.6953 11.272 12.4102C11.4419 12.1252 11.7406 11.895 12.0722 11.895C12.4682 11.895 12.8025 12.2239 12.9382 12.5952C12.9861 12.7268 13.0149 12.8624 13.0314 12.9995H13.03Z" fill="#008FF5"/>
<path d="M2.28181 29.0929C1.06367 30.048 1.10615 33.216 0.875946 35.0946C0.529275 38.0488 0.49776 40.4933 0.451172 43.8244C0.510092 44.8753 2.76962 45.7194 3.97268 45.8222C6.1541 45.7907 8.44103 43.6613 10.073 42.2541C11.6474 40.7263 12.3983 38.1598 12.4175 36.2524C12.4682 31.2853 6.07737 26.1168 2.28181 29.0929ZM3.15191 43.6709C2.93678 43.6709 3.75345 32.9365 4.02475 30.6029C6.435 29.6314 11.1048 35.629 8.96857 39.3903C7.86964 41.5005 4.6578 43.6695 3.15191 43.6709Z" fill="#008FF5"/>
<path d="M49.0946 21.6018C48.8137 20.633 47.8956 18.1351 47.0105 18.1365C45.6443 18.1392 45.0236 19.1984 44.7386 20.9181C43.9233 23.9723 42.8038 30.1795 42.2379 34.0641C42.2475 34.8849 42.3311 35.3741 43.1628 35.3864C44.7359 35.4083 44.5495 32.35 44.9291 30.6961C45.1826 30.5632 47.4407 29.741 48.7164 29.3286C48.9082 29.9411 49.6961 32.4281 50.062 33.2269C50.2607 33.6585 50.6539 33.912 51.0403 33.8175C51.9803 33.59 52.3229 33.083 52.1461 32.2856C51.5939 29.8041 50.0716 24.9575 49.096 21.6018H49.0946ZM45.3114 28.6626C45.6238 26.3278 46.1239 23.5654 46.6912 21.2524C47.3078 23.1611 47.7846 25.5755 48.2793 27.4966C46.7953 28.1735 45.9759 28.3708 45.31 28.6613L45.3114 28.6626Z" fill="#008FF5"/>
<path d="M66.6597 28.9286C63.9699 28.6572 60.8937 25.8428 59.6359 24.1711C60.4498 23.7997 61.9995 23.5819 63.7301 20.9551C64.6619 19.315 64.2919 16.4224 62.127 16.0716C59.9962 15.7016 56.9269 16.4772 55.5306 17.5446C55.0236 17.9365 55.0099 18.7847 54.9976 19.5342C54.9332 23.3558 54.7756 27.3583 55.0141 31.1141C55.0716 32.0253 55.7608 32.1239 56.0527 32.1239C56.6994 32.1239 57.4174 31.6841 57.4476 30.9729C57.5558 28.3626 57.3668 27.1842 57.3078 24.8562C59.64 27.5775 61.7241 30.8263 66.076 30.8784C66.8584 30.9867 67.9039 30.5386 67.8614 29.7507C67.8559 29.278 67.4174 28.9793 66.6597 28.9272V28.9286ZM60.3141 17.5309C61.7077 17.5309 61.9475 18.2242 61.9858 18.523C62.3969 20.703 60.2045 22.2802 57.1311 22.8488C57.1311 20.7537 57.1763 18.7189 57.1763 18.7189C57.6202 18.2023 58.4794 17.6693 60.3141 17.5295V17.5309Z" fill="#008FF5"/>
<path d="M38.648 21.1702C37.9135 21.0167 37.3887 21.3004 37.3476 21.9499C37.2832 22.9734 37.1681 24.0134 37.0777 25.1274C36.8831 27.4404 36.716 29.689 36.4159 31.9334C35.3786 30.3919 34.2742 28.7517 33.2246 27.2116L31.6173 24.8465C31.2021 24.2395 31.0363 23.701 30.0415 23.7079C29.3811 23.7079 28.5863 23.9764 28.3397 24.7315C27.3408 27.7706 27.375 31.0195 27.0325 34.2176C26.9242 35.3248 26.8311 36.2826 26.8311 37.7761C26.8311 38.4174 27.2134 38.786 27.8793 38.786C28.5452 38.786 29.4633 38.3379 29.5058 37.3431C29.64 34.1272 29.9949 30.0809 30.1648 26.7005C32.3134 29.7698 33.87 33.1022 36.2254 36.0524C36.9941 36.8649 38.4945 37.0197 38.8179 35.137C38.8823 33.8888 39.6949 25.5892 39.7264 22.3952C39.7346 21.4936 39.1577 21.2784 38.648 21.1716V21.1702Z" fill="#008FF5"/>
<path d="M21.9338 25.1906C14.7222 21.6869 11.0459 40.9812 19.2906 40.8154C21.1418 40.8168 22.9012 39.6767 23.6082 37.5213C24.7538 34.0259 25.4032 27.8406 21.9338 25.1892V25.1906ZM17.9999 29.9001C17.9957 29.8398 18.9261 26.9828 20.395 27.1541C23.437 27.6488 22.1599 41.6115 17.9724 38.1393C16.1185 35.677 17.1229 32.4145 17.9999 29.9014V29.9001Z" fill="#008FF5"/>
<path d="M78.2684 12.6858C76.8707 13.1873 76.1418 14.2054 75.8211 15.4262C75.247 17.6063 76.2596 19.23 78.5274 21.2443C80.5115 23.0064 81.4487 26.432 79.0344 27.0281C76.62 27.6241 76.5419 25.9935 75.6252 24.589C75.2141 23.9587 73.8864 24.0505 73.8932 25.3523C73.9042 27.4843 76.4432 29.3739 78.6 29.2424C83.0382 28.9711 84.0316 24.6973 81.6488 21.3799C80.4416 19.6986 76.9941 18.2243 77.9382 15.6948C78.3602 14.5657 79.0851 14.3766 79.4317 14.3766C80.1566 14.3766 80.8294 15.0823 81.1856 16.2182C81.3857 16.8513 82.4586 16.6828 82.7641 16.2032C82.9642 15.9305 82.9985 15.5756 82.8642 15.1495C82.2613 12.9119 80.2443 11.9732 78.267 12.683L78.2684 12.6858Z" fill="#008FF5"/>
<path d="M91.0088 11.6785C84.0083 11.6058 83.6041 28.5735 90.1949 27.3019C93.7329 26.6195 94.8154 22.3499 94.8606 19.3121C94.8154 16.9087 94.6879 11.7168 91.0102 11.6785H91.0088ZM89.8428 25.1465C86.6501 25.1082 87.6038 13.4241 90.5128 13.6201C93.1848 13.7996 93.0313 25.1849 89.8428 25.1465Z" fill="#008FF5"/>
<path d="M99.814 22.9694C99.9168 19.6849 100.159 15.9305 100.535 11.6348C100.633 10.803 99.8401 9.84525 98.9837 10.1878C97.549 11.0291 97.871 15.7524 97.5655 17.546C97.4695 18.8957 97.3599 20.4249 97.1845 22.1719C97.1407 23.6148 96.5761 25.7249 98.2533 25.84C98.9974 25.8318 99.4934 25.5084 100.384 25.2837C101.505 24.9576 103.2 24.4643 104.028 23.7217C104.41 23.3791 104.513 22.9831 104.359 22.4377C103.895 21.3744 100.836 22.8886 99.814 22.9694Z" fill="#008FF5"/>
<path d="M111.154 8.56393C107.03 8.71877 105.651 14.1326 105.87 18.0213C106.012 21.6566 107.507 24.4875 110.185 24.1011C116.283 23.2214 117.552 8.32277 111.154 8.56393ZM108.595 13.6434C108.914 12.6267 109.847 10.6604 111.072 10.8139C114.336 11.2222 112.898 18.2227 111.712 20.5001C111.21 21.3304 110.198 21.7689 109.487 21.5716C107.713 21.214 108.064 15.5316 108.595 13.6434Z" fill="#008FF5"/>
<path d="M20.9074 45.744C18.5465 46.4017 16.2473 47.1745 13.9329 47.9076C12.5778 48.1104 12.0776 49.767 13.7671 49.9136C14.6071 49.8287 15.5786 49.3971 16.4035 49.2381C16.2788 51.9772 15.9992 54.5478 15.9184 57.3883C15.8732 58.4982 15.8307 59.5464 15.7745 60.2603C15.7485 60.9016 16.2733 61.6484 17.0023 61.6744C18.5177 61.7977 18.2272 59.5875 18.3574 58.3242C18.5438 55.1712 18.7726 50.9865 19.0987 48.4763C20.0414 48.0378 22.7984 48.2282 22.4736 46.488C22.3859 45.9221 21.5802 45.4905 20.9074 45.7454V45.744Z" fill="#008FF5"/>
<path d="M32.1379 56.4702C31.7775 55.2918 30.2744 56.14 29.4002 56.2935C28.1135 56.5812 27.0927 56.8101 26.8912 56.7635C26.7186 56.7224 26.794 56.5812 26.9447 52.3828C28.0738 52.1129 29.3632 52.0773 30.4402 51.6182C31.4815 51.1551 31.3144 49.567 30.0949 49.6149C29.0453 49.7054 28.0422 49.9507 26.9817 50.0466C27.0255 47.6898 27.1119 46.5812 27.2407 46.4113C27.3462 46.2716 28.8493 46.1825 31.2719 45.1521C31.6542 45 31.7542 44.5519 31.6843 44.1984C31.3363 42.9803 28.9767 43.8736 26.6926 44.3039C25.1565 44.593 25.0524 44.5588 24.9345 46.051C24.8112 47.7089 24.6989 49.4039 24.5906 51.0441C24.4371 53.3694 24.2782 55.7742 24.0836 58.1282C23.9809 58.7531 24.1631 59.5917 24.9825 59.5423C27.1913 59.16 29.4015 58.7092 31.5048 57.8898C32.0707 57.65 32.2516 56.9937 32.1393 56.4702H32.1379Z" fill="#008FF5"/>
<path d="M80.7896 33.3079C79.0823 33.4764 76.9283 34.2876 75.1661 34.8001C74.2878 35.2837 72.0763 35.1522 72.3229 36.4923C72.512 38.0064 74.2111 37.1021 75.2456 36.8787C74.9401 39.4685 74.9908 42.3967 74.7797 45.1262C74.8291 46.9006 73.8822 50.6784 75.717 50.7058C76.2555 50.7058 76.894 50.3495 77.0708 49.6671C77.4791 48.0352 77.6024 39.6603 77.7847 36.5649C78.1697 36.4498 79.1618 36.1196 80.3991 35.825C82.1133 35.5441 82.2886 33.3558 80.7896 33.3079Z" fill="#008FF5"/>
<path d="M66.683 36.9676C68.5671 36.9608 67.9066 40.4069 69.8812 39.0792C70.6033 38.3543 70.1223 36.8635 69.5811 36.177C67.0407 32.9405 61.6186 36.0866 62.971 39.8753C63.9247 42.1855 65.8348 43.9052 67.079 46.0386C68.0135 47.4924 68.2985 49.9164 65.8773 49.9822C64.6551 50.0027 64.3495 48.6366 63.4054 48.0981C62.4394 47.5678 61.8283 48.7353 62.2941 49.5355C62.9217 51.1126 64.0837 52.3266 65.8102 52.3431C67.2722 52.3458 68.7247 51.4744 69.6647 50.0397C70.9924 47.106 68.3629 44.2559 66.82 42.0046C65.4923 40.4686 63.763 37.6473 66.6817 36.9649L66.683 36.9676Z" fill="#008FF5"/>
<path d="M55.3717 37.7884C49.8428 38.0762 47.9258 54.2518 54.7524 54.182C60.7102 53.727 60.469 44.1477 59.007 39.9123C58.3712 38.2845 57.0256 37.6021 55.3717 37.7884ZM54.7551 52.1636C53.0409 53.1474 51.546 40.4056 55.8705 39.8383C56.8982 39.8383 57.164 41.1853 57.4517 43.242C57.8957 47.6857 56.9639 50.8961 54.7537 52.1636H54.7551Z" fill="#008FF5"/>
<path d="M46.2542 52.2116C45.6485 52.9036 45.0511 53.2544 44.4797 53.2544C40.2717 52.3528 42.5312 43.3243 43.9823 41.9856C45.2772 40.7893 45.8404 41.9568 46.3035 43.0708C46.7228 44.0806 48.1259 43.6463 47.9108 42.3404C47.708 41.1072 46.8872 39.2218 45.0621 39.4712C38.6137 40.3522 38.0766 55.5934 44.3783 55.3659C45.9541 55.3659 47.5491 54.5095 48.004 52.9379C48.2588 52.0582 46.8968 51.2607 46.2556 52.2116H46.2542Z" fill="#008FF5"/>
<path d="M123.521 39.3107C122.718 35.8207 121.95 31.6771 120.91 28.6447C120.132 26.3729 118.17 27.6801 117.808 28.9708C116.605 33.2679 115.501 37.5924 114.071 42.0114C113.659 43.0637 113.884 44.138 114.861 44.149C116.383 44.1038 116.262 41.4907 116.921 40.2164C117.417 40.0835 119.488 39.6149 120.829 39.5025C121.124 40.5507 121.408 42.0183 121.602 42.9774C121.972 44.4326 124.054 43.8749 124.052 42.6582C124.051 41.1879 123.618 40.1739 123.519 39.312L123.521 39.3107ZM117.55 38.1528C118.13 35.8549 118.718 33.1843 119.286 30.8645C119.767 33.2583 120.113 35.5809 120.536 37.5855C119.293 37.9363 118.23 38.0569 117.552 38.1528H117.55Z" fill="#008FF5"/>
<path d="M109.851 31.3895C107.73 31.4375 108.442 35.5016 108.301 37.1116C108.133 39.0245 108.204 41.2073 108.014 43.4134C107.987 44.5849 108.571 45.3454 109.459 45.3358C110.98 45.3194 110.724 43.7669 110.759 42.1651C110.801 40.3577 110.854 35.2234 110.954 33.3668C110.998 32.1363 110.872 31.3703 109.851 31.3895Z" fill="#008FF5"/>
<path d="M109.98 27.3021C109.374 27.9927 107.807 29.4424 109.313 29.8028C110.557 30.1015 113.875 27.6214 113.279 26.4649C112.524 25.0015 110.784 26.3854 109.98 27.3021Z" fill="#008FF5"/>
<path d="M88.9932 33.0747C88.7247 32.3444 88.0998 31.4414 87.3051 31.4414C86.4377 31.4414 85.7869 32.7705 85.5416 33.4118C84.5495 36.0139 84.2152 38.5159 83.5411 41.5017C82.3859 46.6264 81.472 49.1627 83.2369 49.1627C84.5715 49.1408 84.9962 45.9618 85.2401 45.0328C86.0637 44.6656 89.0426 44.1572 89.0426 44.1572C89.4564 45.8001 89.6386 48.9188 91.2514 48.8489C92.6846 48.7859 92.4065 47.8212 92.1598 45.5987C91.1596 40.2233 90.3662 37.5417 88.9932 33.0747ZM85.558 42.9213C85.6129 42.4773 86.5213 37.4088 87.0571 35.2123C87.5682 37.4431 88.3163 39.8615 88.6959 42.1443L85.5594 42.9213H85.558Z" fill="#008FF5"/>
<path d="M105.261 43.7614C102.623 43.0996 100.573 40.8839 99.0987 38.6052C103.459 37.261 105.699 30.7058 100.594 30.7606C98.9617 30.7606 97.0776 31.7513 95.7197 32.2857C95.1579 32.5063 95.2127 32.768 94.8249 38.2942C94.6893 40.2166 94.4605 42.1966 94.4344 44.5794C94.4029 45.2029 94.8523 46.1004 95.4635 46.099C97.1283 46.1566 96.8488 40.7976 97.1228 39.5219C100.272 44.5315 102.846 45.825 105.229 45.6373C106.172 45.5633 106.624 44.1026 105.262 43.7614H105.261ZM97.2448 33.6573C97.8449 33.1599 99.4646 32.6241 100.328 32.6241C101.32 32.6282 101.649 33.6915 101.31 34.5068C100.765 35.9374 98.6328 37.1363 97.131 37.3884C97.1776 36.0716 97.2448 33.6573 97.2448 33.6573Z" fill="#008FF5"/>
<path d="M80.4662 75.518C79.7057 75.3974 79.5221 74.5533 79.6797 73.8298C81.535 67.7747 83.6671 61.0428 85.6402 54.7753C85.8978 54.0888 86.3527 51.6827 87.3996 51.8937C88.1888 52.0841 88.5766 52.7528 88.1957 53.7819C87.2941 56.795 86.1979 60.2001 85.1181 63.5037C84.0521 66.7772 82.9518 70.1535 82.0666 73.1474C81.8309 74.8137 81.0773 75.5865 80.4648 75.518H80.4662Z" fill="#008FF5"/>
<path d="M73.3917 69.6889C70.6772 69.7396 68.9357 67.1745 68.7192 64.8191C67.7038 64.8259 66.0924 64.8437 66.1897 63.9764C66.0307 63.32 67.042 63.1515 68.7096 63.0378C68.7411 62.4705 68.7904 61.8196 68.8644 61.2482C68.034 61.2249 66.276 61.5538 66.5199 60.4234C66.5843 59.5245 68.2725 59.508 69.2768 59.3888C69.6728 57.1636 71.8871 53.8284 74.3687 54.123C76.0445 54.123 77.2503 55.1575 77.5942 56.8909C77.6367 57.0978 77.5887 57.2855 77.4517 57.4527C77.1105 57.9007 76.0938 58.0926 75.9403 57.3814C75.521 56.2962 74.7715 55.9536 74.3317 55.9797C72.9285 55.9852 72.0283 57.9967 71.6487 59.3765C72.6257 59.3902 74.7331 58.9791 74.5577 60.2946C74.4468 61.0605 72.0694 60.9838 71.1609 61.1551C71.0732 61.6059 70.9869 62.2567 70.9595 62.8158C71.5747 62.8651 72.2256 62.798 72.8449 62.7843C73.6753 62.7843 73.9151 63.0049 74.0096 63.3639C74.2234 64.2258 73.0779 64.4546 70.9458 64.6519C70.8732 65.9632 71.9008 67.872 73.5177 67.5787C74.5756 67.5075 75.669 66.2496 76.6748 66.1962C77.7353 66.1331 77.8628 67.3198 77.1475 68.0172C76.3103 68.9558 74.6646 69.6889 73.3876 69.6889H73.3917Z" fill="#008FF5"/>
<path d="M93.1162 68.1076C92.5613 68.1049 91.9351 67.4472 91.942 66.8566C91.942 64.0038 92.168 61.91 92.401 59.1326C92.6832 55.7097 92.6216 52.5212 94.7331 52.8322C96.9173 52.9322 98.5931 56.7292 100.1 58.5173C101.173 56.7127 102.073 54.5628 103.124 52.7171C103.656 51.784 104.078 51.4579 104.676 51.451C105.949 51.4387 105.879 52.7788 105.879 57.6075C105.879 59.7314 105.933 61.9279 105.794 64.1422C105.725 65.2342 105.081 65.4137 104.61 65.4137C103.111 65.5453 103.52 62.0457 103.449 60.0972C103.47 58.9709 103.52 57.9117 103.457 56.8278C99.3796 65.6494 99.7167 61.6127 95.1127 56.2537C94.703 60.7289 94.6564 64.0257 94.2974 67.0114C94.244 67.7829 93.6232 68.1076 93.1135 68.1076H93.1162Z" fill="#008FF5"/>
<path d="M124.125 62.9103C122.896 62.909 120.696 61.6305 120.207 60.3631C119.961 59.7026 120.207 58.9695 121.081 58.9079C121.562 58.912 122.004 59.3121 122.493 59.7423C123.094 60.2808 123.814 60.8961 124.71 60.8207C125.943 60.6535 126.307 59.8122 126.362 59.3518C126.535 58.194 125.513 56.1729 124.07 55.4425C116.086 50.5193 125.207 43.2433 128.104 49.4751C128.224 49.7533 128.209 50.0068 128.059 50.2356C127.783 50.67 127.038 50.9029 126.677 50.4576C125.632 48.0624 123.585 48.305 122.886 50.0178C122.067 52.5554 126.002 53.9353 127.249 55.7042C129.887 58.5119 128.122 63.1337 124.126 62.9117L124.125 62.9103Z" fill="#008FF5"/>
<path d="M110.037 64.3216C109.767 64.3326 109.545 64.2449 109.359 64.0037C108.986 63.5228 109.08 62.7705 109.138 62.2087C109.207 61.5565 109.288 60.9276 109.288 60.2726C109.548 56.8305 109.634 54.6971 110.026 50.9824C110.152 49.7916 110.54 49.8108 116.183 49.1106C117.147 48.9914 117.383 50.7988 116.668 50.9673C114.107 51.5743 112.471 51.762 112.291 51.9224C112.104 52.0909 112.104 52.1786 112.072 52.4102C112.028 52.7418 111.934 55.2479 111.912 55.2493C112.845 55.1726 113.777 55.0958 114.71 55.0191C115.143 54.9835 115.591 54.9492 115.998 55.0999C116.405 55.2493 116.484 55.6384 116.446 56.0714C116.42 56.3715 116.216 56.8744 115.391 56.9689C114.207 57.106 113.057 57.3252 111.872 57.4526C111.82 57.8295 111.745 58.4707 111.724 58.8489C111.679 59.6532 111.512 61.6606 111.656 61.7196C112.027 61.8717 116.339 61.24 116.771 61.366C117.202 61.4921 117.597 62.0005 117.598 62.4513C117.598 62.8322 117.319 63.1693 116.982 63.3474C116.645 63.5255 116.257 63.5749 115.877 63.6214C114.518 63.79 113.164 63.9298 111.805 64.0887C111.249 64.1531 110.688 64.2463 110.136 64.3134C110.102 64.3175 110.067 64.3203 110.035 64.3216H110.037Z" fill="#008FF5"/>
<path d="M59.8277 66.7252C63.1492 64.3067 63.426 59.6917 59.1947 57.6323C55.5457 55.8564 50.5581 57.9776 50.0182 62.2322C49.8373 63.9696 52.0667 64.4424 52.7162 62.5528C53.082 61.4895 53.3068 60.6879 54.4591 59.9521C57.0023 58.327 61.0911 60.6345 59.2632 63.8299C58.4068 65.0973 57.1845 65.4056 55.1251 66.2634C53.9261 66.7622 53.9261 68.4914 55.5348 68.5914C57.5107 68.7148 59.9099 69.0806 61.1021 70.6687C62.6573 72.8378 61.3829 75.1617 59.033 76.5525C56.9543 77.4624 54.4865 77.4788 52.127 74.8466C51.5022 73.8395 49.8305 73.9532 49.4989 74.9603C49.344 75.381 49.4098 75.914 49.666 76.3168C51.2117 78.7997 53.3451 79.6602 56.0596 79.9589C59.9401 80.3865 64.4961 77.5076 64.5372 73.6668C64.577 70.0658 63.5027 67.9803 59.8264 66.7265L59.8277 66.7252Z" fill="#008FF5"/>
</g>
<defs>
<clipPath id="clip0_14664_39535">
<rect width="128.049" height="80" fill="white" transform="translate(0.451172)"/>
</clipPath>
</defs>
</svg>
    )
}
export default SvgBack;
export interface Props{
    style?: React.CSSProperties
}