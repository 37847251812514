import { useState } from "react";
import styled from "styled-components";
import { Track } from "client-v2";

import { Color, Text } from "@adoptaunabuelo/react-components";
import { Play } from "lucide-react";
import ResumeStyled from "../../../components/section/Resume";
import VideoModal from "../../../components/modal/VideoModal";

const EventImageView = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 225px;
	width: 100%;
	background-color: ${Color.background.soft};
	border-radius: 12px;
	cursor: pointer;
	overflow: hidden;
`;
const EventImage = styled.img`
	height: 100%;
	width: 100%;
	object-fit: cover;
`;
const PlayButton = styled.div`
	position: absolute;
	bottom: 16px;
	right: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50px;
	padding: 6px 12px;
	gap: 6px;
	background-color: #001d3d3d;
	backdrop-filter: blur(20px);
`;

const Resume = (props: Props) => {
	const [play, setPlay] = useState(false);

	const onVideoEnd = () => {
		Track.track("donation video finish", {
			view: "web-donation",
		});
		setPlay(false);
	};

	return (
		<>
			<VideoModal
				isVisible={play}
				url={
					"https://data.adoptaunabuelo.org/video/donation/welcome_01.mp4"
				}
				onFinish={onVideoEnd}
				onClose={() => setPlay(false)}
			/>
			<ResumeStyled
				style={{
					padding: 0,
					border: "none",
					flexDirection: "column",
					backgroundColor: "transparent",
					boxShadow: "none",
				}}
			>
				<EventImageView onClick={() => setPlay(true)}>
					<PlayButton>
						<Play
							height={24}
							width={24}
							fill={"white"}
							strokeWidth={0}
						/>
						<Text type="b2" style={{ color: "white" }}>
							Ver video
						</Text>
					</PlayButton>
					<EventImage src={props.image} />
				</EventImageView>
				<Text
					type="h3"
					weight="semibold"
					style={{ marginTop: 24, marginBottom: 8 }}
				>
					Ningún mayor debería sentirse solo. ¿Nos ayudas?
				</Text>
				<Text type="h6">
					Devolvamos a los mayores todo lo que nos han dado y lo que
					nos siguen dando.
				</Text>
			</ResumeStyled>
		</>
	);
};
export default Resume;
export interface Props {
	image: string;
}
