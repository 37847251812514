import styled from "styled-components";
import media from "styled-media-query";

import { Text, Color, Menu, Button } from "@adoptaunabuelo/react-components";
import {
	Heart,
	Facebook,
	Twitter,
	Share,
	Link,
	MessageCircle,
} from "lucide-react";

const Background = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	flex-direction: column;
`;
const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 550px;
`;
const MobileSection = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	margin: 48px 0px;
	padding: 24px;
	border-radius: 12px;
	background-color: white;
	align-items: center;
	gap: 16px;
`;
const Section = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-top: 48px;
`;
const HowWork = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;
const Row = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
`;
const Col = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
const Numbers = styled(Text)<{ color?: string }>`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	margin-right: 16px !important;
	height: 40px;
	width: 40px;
	border-radius: 30px;
	color: ${(props) =>
		props.color ? props.color : Color.text.primary} !important;
`;
const Image = styled.img`
	height: 160px;
	width: 160px;
	${media.lessThan("small")`
        display: none;
    `}
`;

const SuccessStep = (props: Props) => {
	return (
		<Background>
			<Container>
				<Heart
					fill={Color.background.primary}
					color={"transparent"}
					style={{ marginBottom: 24, marginTop: 24 }}
					height={48}
					width={48}
					strokeWidth={1}
				/>
				<Text
					type="h3"
					weight="semibold"
					style={{ textAlign: "center" }}
				>
					¡Eres increíble!
				</Text>
				<Text
					type="p"
					style={{
						marginTop: 12,
						marginBottom: 12,
						textAlign: "center",
					}}
				>
					Hemos recibido tu firma. Gracias por ayudarnos a proteger
					los derechos de las personas mayores.
				</Text>
				<Section>
					<Text
						type="h4"
						weight="semibold"
						style={{ marginBottom: 24, textAlign: "center" }}
					>
						¿Y ahora qué?
					</Text>
					<HowWork>
						<Row>
							<Numbers type="p" weight="semibold">
								1
							</Numbers>
							<Col>
								<Text type="h6" weight="medium">
									Seguiremos haciendo ruido
								</Text>
								<Text
									type="p"
									style={{
										color: Color.text.high,
										marginTop: 4,
									}}
								>
									Para que la campaña llega a todo el mundo.
								</Text>
							</Col>
						</Row>
						<Row>
							<Numbers type="p" weight="semibold">
								2
							</Numbers>
							<Col>
								<Text type="h6" weight="medium">
									Llegaremos hasta el final
								</Text>
								<Text
									type="p"
									style={{
										color: Color.text.high,
										marginTop: 4,
									}}
								>
									En caso de conseguir todas las firmas lo
									llevaremos al organismo competente para
									conseguir el objetivo.
								</Text>
							</Col>
						</Row>
					</HowWork>
				</Section>
				<MobileSection>
					<Col>
						<Text type="h6" weight="semibold">
							¡Ayúdanos a difundirlo!
						</Text>
						<Text
							type="p2"
							style={{ marginTop: 8, marginBottom: 24 }}
						>
							Llegar a más gente significa más felicitaciones y
							más cumpleaños celebrados. Comparte para ayudarnos a
							cumplir nuestra misión.
						</Text>
						<Menu
							id={"share"}
							position={"top-right"}
							menuStyle={{ bottom: 62 }}
							options={[
								{
									id: "twitter",
									label: "Twitter",
									icon: <Twitter height={20} width={20} />,
								},
								{
									id: "facebook",
									label: "Facebook",
									icon: <Facebook height={20} width={20} />,
								},
								{
									id: "whatsapp",
									label: "Whatsapp",
									icon: (
										<MessageCircle height={20} width={20} />
									),
								},
								{
									id: "url",
									label: "Copiar enlace",
									icon: <Link height={20} width={20} />,
								},
							]}
							Icon={
								<Button
									design={"secondary"}
									icon={<Share color={Color.text.primary} />}
								>
									Compartir
								</Button>
							}
							onClick={props.onShareClick}
						/>
					</Col>
					<Image
						src={
							"https://adoptaunabuelo.org/wp-content/uploads/2023/03/ilustration-online.webp"
						}
					/>
				</MobileSection>
			</Container>
		</Background>
	);
};
export default SuccessStep;
export interface Props {
	onShareClick: (op: any) => void;
}
