import styled from "styled-components";
import SectionContainer from "./SectionContainer";
import { Button, ColorV2, Input } from "@adoptaunabuelo/react-components";
import media from "styled-media-query";
import { useSelector } from "react-redux";
import { State } from "../../../redux";
import { useState } from "react";
import { Trash } from "lucide-react";
import { CoorporateScheme } from "client-v2";

const ImageContainer = styled.div<{ $transform: boolean }>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 388px;
	height: 300px;
	background-color: white;
	margin-top: 32px;
	border-radius: 24px;
	overflow: hidden;
	${media.lessThan("medium")`
        width: unset;
    `}
`;
const LetterBackground = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: center;
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	${media.lessThan("medium")`
        display: none;
    `}
`;
const LetterImg = styled.img`
	width: 994px;
	object-position: center;
`;
const SelectedImage = styled.img`
	height: 100%;
	width: 100%;
	object-fit: cover;
`;

const LetterImage = (props: Props) => {
	const colors =
		props.corporate && props.corporate.color
			? {
					buttonBackground: props.corporate.color.main,
					buttonColor: props.corporate.color.third,
					animation: [
						props.corporate.color.main,
						props.corporate.color.secondary,
					],
			  }
			: {
					buttonBackground: ColorV2.surface.primaryHard,
					buttonColor: "white",
					animation: ["#00315C", "#005AA8"],
			  };
	const isMobile: boolean = useSelector(
		(state: State) => state.screen.isMobile
	);
	const [imageBase64, setImageBase64] = useState<string | undefined>(
		undefined
	);

	const onNextClick = () => {
		props.onFinish({ data: imageBase64 });
	};

	return (
		<>
			<LetterBackground>
				<LetterImg
					src={require("../../../assets/images/background/letter_back.png")}
				/>
			</LetterBackground>
			<SectionContainer
				title="¿Quieres añadir una imagen?"
				subtitle="Puedes adjuntar una foto tuya para que te ponga cara o bien enviarle un dibujo que hayas hecho."
				animated={props.animated}
				style={{
					height: "100%",
					marginTop: isMobile ? 0 : 40,
				}}
				childrenProps={{
					style: { flex: 1 },
				}}
				buttonProps={{
					style: isMobile
						? {
								zIndex: 1000,
								backgroundColor: colors.buttonBackground,
								color: colors.buttonColor,
						  }
						: {
								marginBottom: 40,
								zIndex: 1000,
								backgroundColor: colors.buttonBackground,
								color: colors.buttonColor,
						  },
					children: imageBase64 ? "Siguiente" : "Continuar sin foto",
					onClick: onNextClick,
				}}
			>
				<ImageContainer $transform={imageBase64 ? true : false}>
					{imageBase64 ? (
						<>
							<SelectedImage src={imageBase64} />
							<Button
								style={{
									position: "absolute",
									top: 16,
									right: 16,
									backgroundColor: "white",
									borderRadius: 100,
									zIndex: 1000,
								}}
								design={"image"}
								icon={<Trash />}
								onClick={() => setImageBase64(undefined)}
							/>
						</>
					) : (
						<Input
							type="image"
							style={{ zIndex: 1000 }}
							options={["camera", "library"]}
							buttonText="Añadir imagen"
							hideCrop={true}
							onChange={(image: string) => setImageBase64(image)}
						/>
					)}
				</ImageContainer>
				<LetterBackground>
					<LetterImg
						src={require("../../../assets/images/background/letter_front.png")}
					/>
				</LetterBackground>
			</SectionContainer>
		</>
	);
};
export default LetterImage;
export interface Props {
	corporate?: CoorporateScheme;
	animated?: boolean;
	onFinish: (result: { data?: string; error?: string }) => void;
}
