import styled from 'styled-components';
import media from 'styled-media-query';

import TestimonyCell from '../cell/TestimonyCell';

const Container = styled.div`
    display: flex;
    gap: 16px;
    ${media.lessThan('medium')`
        flex-direction: column;
    `}
`

const TestimonyList = (props: Props) =>{
    
    return(
        <Container>
            {props.options.map((item, index) =>(
                <TestimonyCell
                    key={'testimony-'+index}
                    testimony={item}
                />
            ))}
        </Container>
    )
}
export default TestimonyList;
export interface Props {
    options: Array<any>
}