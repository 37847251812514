import styled from "styled-components";
import media from "styled-media-query";
import { UserScheme } from "client-v2";

import { Text, Color } from "@adoptaunabuelo/react-components";
import { Heart } from "lucide-react";
import Relief from "../../../components/section/Relief";

const size: any = "1080px";

const Background = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;
const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 550px;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const Column = styled.div`
	display: flex;
	flex-direction: column;
`;
const AppRow = styled.div`
	display: flex;
	flex-direction: row;
	background-color: white;
	border-radius: 12px;
	margin-top: 48px;
	padding: 0px 32px;
	${media.lessThan(size)`
        padding: 0px 24px;
    `}
	${media.lessThan("small")`
        flex-direction: column;
        padding: 24px 24px 0px;
        align-items: center;
    `}
`;
const Mockup = styled.img`
	height: 288px;
	margin-top: 32px;
	margin-left: 12px;
	object-fit: cover;
	${media.lessThan(size)`
        height: 240px;
    `}
	${media.lessThan("small")`
        margin: 32px 0px 0px;
        height: 200px;
        width: 200px;
        object-position: top;
    `}
`;
const IconImage = styled.img`
	width: 135px;
	height: 40px;
	object-fit: cover;
	border-radius: 8px;
	cursor: pointer;
`;

const SuccessStep = (props: Props) => {
	return (
		<Background>
			<Container>
				<Heart
					fill={Color.background.primary}
					color={"transparent"}
					style={{ marginBottom: 24, marginTop: 24 }}
					height={48}
					width={48}
					strokeWidth={1}
				/>
				<Text
					type="h3"
					weight="semibold"
					style={{ textAlign: "center" }}
				>
					¡Gracias por tu donación, {props.user && props.user.name}!
				</Text>
				<Text
					type="p"
					style={{
						marginTop: 12,
						marginBottom: 12,
						textAlign: "center",
					}}
				>
					Personas como tú hacen que estemos más cerca de lograr
					nuestro objetivo: Acompañar a todos los mayores del mundo.
				</Text>
				{props.user && !props.user.idCard && (
					<Relief style={{ marginTop: 32 }} user={props.user} />
				)}
				<AppRow>
					<Column style={{ justifyContent: "center" }}>
						<Text type="h5" weight="semibold">
							¿Quieres ayudar a más personas mayores? Bájate
							gratis nuestra app.
						</Text>
						<Text type="p" style={{ marginTop: 8 }}>
							Podrás realizar voluntariado y estar al tanto de
							todas nuestras novedades.
						</Text>
						<Row style={{ marginTop: 24, gap: 12 }}>
							<IconImage
								src={require("../../../assets/logo/android.png")}
								onClick={() =>
									window.open(
										"https://play.google.com/store/apps/details?id=com.adoptaunabuelo.app&hl=es_419&gl=ES&pli=1",
										"_blank"
									)
								}
							/>
							<IconImage
								src={require("../../../assets/logo/ios.png")}
								onClick={() =>
									window.open(
										"https://apps.apple.com/es/app/adopta-un-abuelo/id1559513523",
										"_blank"
									)
								}
							/>
						</Row>
					</Column>
					<Mockup
						src={require("../../../assets/images/mockup/event.png")}
					/>
				</AppRow>
			</Container>
		</Background>
	);
};
export default SuccessStep;
export interface Props {
	user?: UserScheme;
}
