import React from "react";

const SvgBack = (props: Props) => {
	switch (props.position) {
		case "top":
			return (
				<svg
					style={{
						position: "absolute",
						top: 0,
						backgroundColor: props.backgroundColor,
						...props.style,
					}}
					width="100%"
					height="16px"
				>
					<defs>
						<pattern
							id="bgTop"
							patternUnits="userSpaceOnUse"
							width="220px"
							height="16px"
						>
							<path
								d="M21.3076 16L42.8056 9.67642e-07L97.4615 -1.42144e-06L76.6922 16L21.3076 16Z"
								fill={props.color[0]}
							/>
							<path
								d="M77.4614 16L98.9594 9.67642e-07L153.615 -1.42144e-06L132.846 16L77.4614 16Z"
								fill="transparent"
							/>
							<path
								d="M133.615 16L154.384 -1.42593e-05L209.769 -1.66802e-05L188.999 16L133.615 16Z"
								fill={props.color[1]}
							/>
						</pattern>
					</defs>
					<rect width="100%" height="100%" fill="url(#bgTop)" />
				</svg>
			);
		case "bottom":
			return (
				<svg
					style={{
						position: "absolute",
						bottom: 0,
						backgroundColor: props.backgroundColor,
						...props.style,
					}}
					width="100%"
					height="16px"
				>
					<defs>
						<pattern
							id="bgBottom"
							patternUnits="userSpaceOnUse"
							width="220px"
							height="16px"
						>
							<path
								d="M21.3076 16L42.8056 9.67642e-07L97.4615 -1.42144e-06L76.6922 16L21.3076 16Z"
								fill={props.color[0]}
							/>
							<path
								d="M77.4614 16L98.9594 9.67642e-07L153.615 -1.42144e-06L132.846 16L77.4614 16Z"
								fill="transparent"
							/>
							<path
								d="M133.615 16L154.384 -1.42593e-05L209.769 -1.66802e-05L188.999 16L133.615 16Z"
								fill={props.color[1]}
							/>
						</pattern>
					</defs>
					<rect width="100%" height="100%" fill="url(#bgBottom)" />
				</svg>
			);
		case "right":
			return (
				<svg
					style={{
						position: "absolute",
						right: 0,
						backgroundColor: props.backgroundColor,
						...props.style,
					}}
					width="16px"
					height="100%"
				>
					<defs>
						<pattern
							id="bgRight"
							patternUnits="userSpaceOnUse"
							width="100%"
							height="220px"
						>
							<path
								d="M16 188.462L3.4403e-06 166.964L7.33763e-06 112.308L16 133.077L16 188.462Z"
								fill={props.color[0]}
							/>
							<path
								d="M16 132.308L3.4403e-06 110.81L7.33763e-06 56.1539L16 76.9231L16 132.308Z"
								fill="white"
							/>
							<path
								d="M16 76.1539L3.38833e-06 55.3846L7.3376e-06 0.000494196L16 20.7697L16 76.1539Z"
								fill={props.color[1]}
							/>
						</pattern>
					</defs>
					<rect width="100%" height="100%" fill="url(#bgRight)" />
				</svg>
			);
		case "left":
			return (
				<svg
					style={{
						position: "absolute",
						backgroundColor: props.backgroundColor,
						left: 0,
						...props.style,
					}}
					width="16px"
					height="100%"
				>
					<defs>
						<pattern
							id="bgLeft"
							patternUnits="userSpaceOnUse"
							width="100%"
							height="220px"
						>
							<path
								d="M16 188.462L3.4403e-06 166.964L7.33763e-06 112.308L16 133.077L16 188.462Z"
								fill={props.color[0]}
							/>
							<path
								d="M16 132.308L3.4403e-06 110.81L7.33763e-06 56.1539L16 76.9231L16 132.308Z"
								fill="white"
							/>
							<path
								d="M16 76.1539L3.38833e-06 55.3846L7.3376e-06 0.000494196L16 20.7697L16 76.1539Z"
								fill={props.color[1]}
							/>
						</pattern>
					</defs>
					<rect width="100%" height="100%" fill="url(#bgLeft)" />
				</svg>
			);
		default:
			return (
				<svg
					style={{
						...props.style,
						backgroundColor: props.backgroundColor,
					}}
					width="100%"
					height="6"
				>
					<defs>
						<pattern
							id="bg"
							patternUnits="userSpaceOnUse"
							width="90"
							height="6"
						>
							<path
								d="M0.5 6.00003L11.9902 2.71543e-05L34.9706 2.13814e-05L23.4804 6.00002L0.5 6.00003Z"
								fill={props.color[0]}
							/>
							<path
								d="M22.9707 6.00002L34.7633 1.42665e-05L57.4413 1.32752e-05L45.6487 6.00001L22.9707 6.00002Z"
								fill={"transparent"}
							/>
							<path
								d="M45.4414 6.00002L57.234 1.5697e-05L79.912 1.3752e-05L68.4218 6.00009L45.4414 6.00002Z"
								fill={props.color[1]}
							/>
						</pattern>
					</defs>
					<rect width="100%" height="100%" fill="url(#bg)" />
				</svg>
			);
	}
};
export default SvgBack;
export interface Props {
	position?: "top" | "bottom" | "left" | "right";
	style?: React.CSSProperties;
	color: string[];
	backgroundColor?: string;
}
