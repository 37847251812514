import { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import media from 'styled-media-query';

import { X } from 'lucide-react';
import { Color, Text, Modal, Button } from '@adoptaunabuelo/react-components';

const VideoContainer = styled.div`
    position: relative;
    height: calc(var(--doc-height) - 120px);
    width: 100%;
    background-color: black;
    ${media.lessThan('medium')`
        height: calc(var(--doc-height) - 100px);
    `}
`
const CloseButton = styled.button`
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 17px;
    left: 32px;
    height: 40px;
    z-index: 1200;
    padding: 0px 12px;
    background-color: ${Color.text.high};
    border-radius: 22px;
    border: none;
    cursor: pointer;
`

const VideoModal = (props: VideoModalProps) =>{

    const isMobile = window.innerWidth <= 768;
    const [ counter, setCounter ] = useState(props.skipIn ? props.skipIn : 0);

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(parseInt(("0" + (counter - 1)).slice(-2))), 1000);
    }, [counter]);

    const onClose = () =>{
        props.onClose && props.onClose();
    }

    return(
        <Modal
            style={{width: isMobile ? '95%' : '85%', backgroundColor: 'transparent', boxShadow: 'unset', overflow: 'hidden'}}
            contentStyle={{padding: 0, alignItems: 'flex-end', display: 'flex', flexDirection: 'column'}}
            hideHeader={true}
            isVisible={props.isVisible}
            onClose={props.onClose}
        >
            <Button
                design='image'
                style={{backgroundColor: 'white', borderRadius: 40, marginBottom: 24, marginRight: 4}}
                icon={<X/>}
                onClick={onClose}
            />
            <VideoContainer>
                {props.skipIn &&
                    <CloseButton
                        disabled={counter > 0}
                        onClick={onClose}
                    >
                        <X color={counter > 0 ? Color.text.whiteMedium : 'white'} height={24} width={24}/>
                        <Text type="p" style={{color: Color.text.white, marginLeft: 8}}>
                            {counter > 0 ? 'Saltar en 00:'+counter : 'Saltar video'}
                        </Text>
                    </CloseButton>
                }
                <ReactPlayer
                    width={'100%'}
                    height={'100%'}
                    url={props.url}
                    playing={true}
                    onEnded={() => props.onFinish && props.onFinish()}
                />
            </VideoContainer>
        </Modal>
    )
}
export default VideoModal;
export interface VideoModalProps{
    isVisible: boolean,
    url: string,
    skipIn?: number,
    onFinish?: () => void
    onClose: () => void
}