import { useState } from "react";
import { useSelector } from "react-redux";
import { State } from "../../redux";
import { Modal, Input } from "@adoptaunabuelo/react-components";

const AmountModal = (props: Props) => {
	const translation = useSelector(
		(state: State) => state.location.translation
	);
	const [amount, setAmount] = useState<number | undefined>(undefined);
	const [error, setError] = useState<string | undefined>(undefined);

	const onClick = () => {
		if (amount) {
			if (amount >= 5) {
				if (amount <= 999999) {
					props.onClick(amount);
					props.onClose();
				} else
					setError(
						translation.payment_modal_amount_error_01 +
							props.currencySimbol
					);
			} else
				setError(
					translation.payment_modal_amount_error_02 +
						props.currencySimbol
				);
		} else {
			setError(translation.payment_modal_amount_error_03);
		}
	};

	const onInputChange = (e: any) => {
		setError(undefined);
		const value = e.target.value;
		if (value.length === 0) setAmount(undefined);
		else {
			if (/^[0-9]+$/.test(value)) setAmount(value);
		}
	};

	return (
		<Modal
			isVisible={props.isVisible}
			title={translation.payment_modal_amount_title}
			subtitle={translation.payment_modal_amount_subtitle}
			style={{ width: 400 }}
			buttonProps={{
				children: translation.payment_modal_amount_button,
				onClick: onClick,
			}}
			onClose={props.onClose}
		>
			<Input
				type="text"
				placeholder={translation.payment_modal_amount_input_placeholder}
				design="secondary"
				value={amount}
				containerStyle={{ marginBottom: 24, marginTop: 2 }}
				error={error}
				onChange={onInputChange}
			/>
		</Modal>
	);
};
export default AmountModal;
export interface Props {
	isVisible: boolean;
	currencySimbol?: string;
	onClick: (amount: number) => void;
	onClose: () => void;
}
